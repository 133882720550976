import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExitAppComponent } from './exit-app.component';

const routes: Routes = [
  {
    path: '',
    component: ExitAppComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExitAppRoutingModule { }
