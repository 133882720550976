import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { MetadataModule } from '../metadata/metadata.module';
import { QrCodeModuleCustom } from '../qr-code/qr-code-custom.module';
import { ReturnButtonModule } from '../return-button/return-button.module';
import { PpvModalComponent } from './ppv-modal.component';

const ANGULAR_MODULES = [ CommonModule ];
const EXTERNAL_MODULES = [ TranslateModule];

@NgModule({
    declarations: [PpvModalComponent],
    exports: [PpvModalComponent],
    imports: [
        ...ANGULAR_MODULES,
        ...EXTERNAL_MODULES,
        QrCodeModuleCustom,
        ReturnButtonModule,
        MetadataModule,
        NavigationModule
    ]
})
export class PpvModalModule { }
