import { Injectable } from '@angular/core';
import { MeApiService } from '@api/services';
import { UserFrontApi } from '@app/galgo-api/models';
import { IAddPlayingParams, IPlayingParams, IPlayingVideosParams } from '@core/models/playing.interface';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PlayingService {
  constructor(private meService: MeApiService) {}
  setVideo(params: IAddPlayingParams): Observable<void> {
    return this.meService.meControllerAddPlaying(params);
  }
  removeVideo(params: IPlayingParams): Observable<void> {
    return this.meService.meControllerRemovePlaying(params);
  }
  getPlayingVideos(params: IPlayingVideosParams) {
    return this.meService.meControllerGetPlayingVideos({
      language: params.language,
      page: params.page,
      size: params.size,
      body: params.videoIds,
    });
  }

  meControllerGetMe(params?: {
    language?: string;
  }): Observable<UserFrontApi> {
    return this.meService.meControllerGetMe(params);
  }
}
