/* tslint:disable */
/* eslint-disable */

/**
 * Status of the user (pending, confirmed, incomplete)
 */
export enum UserStatusApi {
  Pending = 'pending',
  Confirmed = 'confirmed',
  Incomplete = 'incomplete',
  Disabled = 'disabled'
}
