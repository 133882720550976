import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { urlMocks } from './mock-urls.conf';

@Injectable()
export class MockRequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private logger: NGXLogger) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.logger.debug('MockRequestInterceptor -> Mock interceptor');
    for (const element of urlMocks) {
      this.logger.debug(`MockRequestInterceptor -> try ${request.url} vs ${element.url}`);
      if (request.url === element.url) {
        this.logger.warn('MockRequestInterceptor -> Loaded from json: ' + request.url);
        this.logger.debug('MockRequestInterceptor -> content: ' +  element.json);
        return of(
          new HttpResponse({
            status: 200,
            body: element.json,
          })
        );
      }
    }

    return next.handle(request);
  }
}
