import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PegiModule } from '@shared/components/pegi/pegi.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TagModule } from '../tag/tag.module';
import { MetadataComponent } from './metadata.component';

const ANGULAR_MODULES = [ CommonModule ];
const EXTERNAL_MODULES = [ TranslateModule ];
const SHARED_MODULES = [ PipesModule, PegiModule ];
@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...EXTERNAL_MODULES,
    ...SHARED_MODULES,
    TagModule
  ],
  declarations: [ MetadataComponent ],
  exports: [ MetadataComponent ],
})
export class MetadataModule { }
