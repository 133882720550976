import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { MediaItemTypes } from '@core/models/media-item.model';
import { MySpaceService } from '@core/services/my-space.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';

import { MySpaceConfiguration } from './my-space.config';

@UntilDestroy()
@Component({
  templateUrl: './my-space.component.html',
  styleUrls: ['./my-space.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: MySpaceComponent }]
})
export class MySpaceComponent extends NavigationComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('mySpace') mySpace!: ElementRef;

  favouriteItems: MediaItemTypes[];
  galgoEvent: GalgoEvent.videoFromFavorites;
  isDataReceived: boolean;
  title: string;
  setInitialFocus: boolean;
  scrollTop: number;
  currentUrl: string;


  get navigableGrid(): boolean {
    return MySpaceConfiguration.navigableGrid;
  }

  constructor(
    public logger: NGXLogger,
    private mySpaceService: MySpaceService,
    private settingsService: SettingsService,
    private browsingHistoryService: BrowsingHistoryService,
    private router: Router,
    public el: ElementRef
  ) {
    super(el);
    this.galgoEvent = GalgoEvent.videoFromFavorites;
    this.isDataReceived = false;
    this.title = MySpaceConfiguration.title;
    this.scrollTop = 0;
    this.currentUrl = '';

  }


  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.setInitialFocus = MySpaceConfiguration.setInitialFocus;

    this.settingsService.languageSet$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
        if (focusData?.favourites) {
          this.favouriteItems = focusData.favourites.items;
          this.isDataReceived = true;
        } else {
          this.getFavorites();
        }
      }
    });
  }

  ngOnDestroy(): void {
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData) {
      focusData.favourites = {
        items: this.favouriteItems
      };
    }
  }


  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }


  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.mySpace.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

  getFavorites() {
    this.mySpaceService.getFavorites().pipe(untilDestroyed(this)).subscribe(
      (items: MediaItemTypes[]) => {
        this.isDataReceived = true;
        this.favouriteItems = items;
        this.logger.debug('MySpaceComponent -> GetFavorites(): Success', items);
      },
      (error: HttpErrorResponse) => {
        this.logger.error('MySpaceComponent -> GetFavorites(): Failed', error);
        throw new Error('Error obtaining favorites');
      }
    );
  }
}
