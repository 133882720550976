import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@app/auth/models/user.model';
import { UserService } from '@app/auth/services/user/user.service';
import { Language } from '@app/core/models/language.model';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { LanguageService } from '@app/core/services/language.service';
import { LanguageIsoApi } from '@app/galgo-api/models/language-iso-api';
import { Capitalize } from '@app/shared/functions/capitalize';
import { CacheService } from '@app/shared/services/cache.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'ty-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: LanguageSelectorComponent }],

})
export class LanguageSelectorComponent extends NavigationComponent implements OnInit {

  @Output() showLanguageChange = new EventEmitter<boolean>();
  @Input() languages: Language[];
  dataUser: User;
  selectedLanguageIndex: number;
  constructor(
    public el: ElementRef,
    public logger: NGXLogger,
    private languageService: LanguageService,
    private userService: UserService,
    private cacheService: CacheService
  ) {
    super(el);
    this.setDefaultActiveChildNodeId(0);
  }

  ngOnInit(): void {
    const langSelected = this.languages?.findIndex(lang => lang.id === this.languageService.currentLang);
    if (langSelected !== -1) {
      this.selectedLanguageIndex = langSelected;
    }
  }

  onEnterKey(): boolean {
    (this.getChildNodeById(this.getSelectedChildNodeId()).el.nativeElement as HTMLElement).click();
    return true;
  }

  onUpKey(): boolean {
    if(this.getSelectedChildNodeId() < 3){
      this.changeShowLanguage(false);
      return false;
    }
    this.selectChildNodeById(this.getSelectedChildNodeId() - 3);
    return true;
  }

  onRightKey(): boolean {
    if(this.getSelectedChildNodeId() < this.languages.length - 1){
      this.selectNextChildNode();
      return true;
    }
    return false;
  }

  onDownKey(): boolean {
    const selectedChildNodeId = this.getSelectedChildNodeId();
    const fila = Math.floor(selectedChildNodeId / 3);
    if(fila < (this.languages.length - 1) / 3 && this.selectChildNodeById(this.getSelectedChildNodeId() + 3)){
      this.selectChildNodeById(this.getSelectedChildNodeId() + 3);
      return true;
    }
    this.changeShowLanguage(false);
    return false;
  }

  onLeftKey(): boolean {
    if(this.getSelectedChildNodeId() === 0){
      return false;
    }
    this.selectPreviousChildNode();
    return true;
  }

  changeShowLanguage(newShowLanguages: boolean): void {
    this.showLanguageChange.emit(newShowLanguages);
  }

  /**
   * Method to select a language.
   *
   * @param language The Language object representing the selected language.
   */

  selectLanguage(language: Language): void {
    // Set the selected language in the language service
    this.languageService.use(language.id);
    this.languageService.setLanguage(language.id);

    // Get the current user from the user service
    this.dataUser = this.userService.currentUser;

    // Get the ISO code of the selected language
    const languageIsoApi: LanguageIsoApi = LanguageIsoApi[Capitalize(language.id) as keyof typeof LanguageIsoApi];

    // Update the favorite language code of the current user
    this.dataUser.favoriteLanguageCode = languageIsoApi;

    // Set the current language in the language service
    this.languageService.currentLang = language.id;

    // Update the current user in the user service
    this.dataUser = this.userService.currentUser;

    // Hide the language selector
    this.changeShowLanguage(false);

    // Update the current user in the server
    this.userService.setUserLanguage(language.id).subscribe();

    //clear the cache
    this.cacheService.clearCache();
  }



  trackByFn(index: number, item: any): number {
    return item.id;
  }

}
