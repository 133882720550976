/* tslint:disable */
/* eslint-disable */

/**
 * Videoaccess: public (for anyone), only if registered user, payment needed, etc...
 */
export enum VideoAccessApi {
  Public = 'public',
  Register = 'register',
  Subscription = 'subscription'
}
