<div #aggregator
     id="view-aggregator">
  <ty-virtual-scroll class="virtual-scroll"
                     #virtualScroll
                     [items]="virtualScrollItems"
                     [numVisibleItems]="2"
                     [toleranceAfter]="3"
                     [toleranceBefore]="2 "
                     [firstSelectedChildNodeId]="firstSelectedChildNodeId">
    <div class="carousel"
         *ngFor="let item of virtualScroll.visibleItems; let index = index; trackBy: trackByFn">
      <div id="header-carousel">{{ item?.data.title }}</div>
      <div>
        <ty-carousel [navigableId]="item.id"
                     [data]="item.data"
                     [carouselIndex]="item.id"
                     [firstSelectedChildNodeId]="item.firstSelectedChildNodeId"
                     (selectedItem)="setSelectedItem($event)"></ty-carousel>
      </div>
    </div>
  </ty-virtual-scroll>
</div>