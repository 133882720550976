/* tslint:disable */
/* eslint-disable */

/**
 * Status
 */
export enum StatusContentExtraDataApi {
  Development = 'DEVELOPMENT',
  Preproduction = 'PREPRODUCTION',
  Production = 'PRODUCTION',
  Postproduction = 'POSTPRODUCTION',
  Completed = 'COMPLETED',
  Other = 'OTHER'
}
