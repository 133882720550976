/* tslint:disable */
/* eslint-disable */

/**
 * Size of the image
 */
export enum ImageSizeApi {
  Original = 'original',
  High = 'high',
  Medium = 'medium',
  Low = 'low'
}
