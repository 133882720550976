import { MediaItem } from '@app/core/models/media-item.model';
import { Collection } from '@app/shared/models/collection/collection.model';
import { ItemTypes } from '@app/shared/models/item-types.enum';
import { Video } from '@app/shared/models/video/video.model';
import { Container, IContainer } from '@core/models/container.model';
import { CarouselType } from './carousel-types.enum';

export type ICarousel = IContainer;
export class Carousel extends Container {
  private _displayMode: string;
  private _isRelated: boolean;
  private _filteredCollections: Collection[];
  private _filteredVideos: Video[];

  set displayMode(value: string) {
    this._displayMode = value;
  }

  get displayMode(): string {
    return this._displayMode;
  }

  set isRelated(value: boolean) {
    this._isRelated = value;
  }

  get isRelated(): boolean {
    return this._isRelated;
  }


  get isRectangular(): boolean {
    return this._displayMode === CarouselType.rectangular;
  }

  get isCircular(): boolean {
    return this._displayMode === CarouselType.circular;
  }

  get isFeatured(): boolean {
    return this._displayMode === CarouselType.featured;
  }

  get isRectangularExpanded(): boolean {
    return this._displayMode === CarouselType.rectangularExpanded;
  }

  get isVertical(): boolean {
    return this._displayMode === CarouselType.portrait;
  }

  get filteredCollections(): Collection[] {
    return this._filteredCollections;
  }

  get filteredVideos(): Video[] {
    return this._filteredVideos;
  }

  constructor(init?: ICarousel) {
    super(init);
    if (init) {
      this._displayMode = init?.displayMode;
      this._filteredCollections = this.filterCollections(init.subcontainers);
      this._filteredVideos = this.filterVideos(init.videos);
    }
  }

  private filterCollections(subContainers): Collection[] {
    return subContainers
      ?.map((collection) => new Collection(collection))
      ?.filter((collection: Collection) => collection.numOfChapters > 0);
  }

  private filterVideos(videos): Video[] {
    return videos?.filter(
      (video: MediaItem<Video>) => !video.type?.includes(ItemTypes.Advertisement)
    );
  }
}
