import { Component, Input } from '@angular/core';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { ITag } from '@app/shared/models/tag.interface';
import { liveTag, ppvTag, suscriptionTag } from '../media-thumb/media-thumb.config';

@Component({
  selector: 'ty-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent {
  @Input() duration: number;
  @Input() date: number;
  @Input() live: boolean;
  @Input() tags: string[];
  @Input() pegi: string;
  @Input() subscription: string;
  @Input() ppv: string;
  @Input() productionEndDate: number;
  @Input() productionStartDate: number;
  @Input() media: MediaItem<MediaItemTypes>;

  liveTag: ITag = liveTag;
  suscriptionTag: ITag = suscriptionTag;
  ppvTag: ITag = ppvTag;

  get productionDate(): string {
      if (!this.productionStartDate) {
          return '';
      }
      if (!this.productionEndDate || this.productionStartDate === this.productionEndDate) {
          return `${this.productionStartDate}`;
      }
      return `${this.productionStartDate} - ${this.productionEndDate}`;
  }
}


