import { Component } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { IconList } from '@core/models/icon-list.enum';
@Component({
  selector: 'ty-modal-return-button',
  templateUrl: './modal-return-button.component.html',
  styleUrls: ['./modal-return-button.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ModalReturnButtonComponent }],
})
export class ModalReturnButtonComponent extends NavigationComponent {

  get returnIconUrl(): string {
    return `./assets/icons/${IconList.returnIcon}#icon`;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }
}
