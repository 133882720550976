import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { TagType } from '@app/shared/models/tag-type.enum';
import { ITag } from '@app/shared/models/tag.interface';
import { tagConfig } from './tag.config';

@Component({
  selector: 'ty-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements AfterViewInit {
  @ViewChild('textElement', { static: false }) textElement: ElementRef;
  @ViewChild('tag', { static: false }) tagElement: ElementRef;
  @Input() content: ITag;
  @Input() set forceOpen(value: boolean) {
    this._forceOpen = value;
    if (value) {
      this.mouseEnter();
    } else {
      this.mouseLeave();
    }
  }

  showGridTextTags: boolean;
  showCarouselTextTags: boolean;
  carouselTextTagsOnHover: boolean;
  config: typeof tagConfig;
  gridTextTagsOnHover: boolean;
  text: string;
  textWidth: number;

  private _forceOpen: boolean;

  get isSubscription(): boolean {
    return this.content?.icon === TagType.subscription;
  }

  get isPpv(): boolean {
    return this.content?.icon === TagType.ppv;
  }

  get isLive(): boolean {
    return this.content?.icon === TagType.live;
  }

  constructor(
    private featureFlagsService: FeatureFlagsService
  ) {
    this._forceOpen = false;
    this.showGridTextTags = this.featureFlagsService?.currentFeaturesValue?.showGridTextTags;
    this.showCarouselTextTags = this.featureFlagsService?.currentFeaturesValue?.showCarouselTextTags;
    this.carouselTextTagsOnHover = this.featureFlagsService?.currentFeaturesValue?.carouselTextTagsOnHover;
    this.config = tagConfig;
    this.gridTextTagsOnHover = this.featureFlagsService?.currentFeaturesValue?.gridTextTagsOnHover;
    this.text = 'subscription';
  }

  ngAfterViewInit(): void {
    if (this._forceOpen) {
      this.mouseEnter();
    }
  }

  mouseEnter(): void {
    const remValor=parseFloat(getComputedStyle(document.documentElement).fontSize);
    this.textWidth = (this.textElement?.nativeElement?.clientWidth / remValor) + this.config.offsetWidth;
    this.textElement?.nativeElement?.setAttribute('style', `opacity: 1`);
  }

  mouseLeave(): void {
    this.tagElement?.nativeElement?.setAttribute(
      'style',
      `width: ${this.config.initialWidth}rem`
    );
  }

  getTagClass(): string {
    switch (true) {
      case this.isLive:
        return 'tag-live';
      case this.isSubscription:
        return 'tag-subscription';
      case this.isPpv:
        return 'tag-ppv';
      default:
        return 'tag-default';
    }
  }
}
