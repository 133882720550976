/**
 * Available stream formats.
 */
export enum StreamFormat {
  /**
   * Dynamic Adaptive Streaming over HTTP (Dash)
   */
  dash = 'dash',
  /**
   * HTTP Live Streaming (HLS)
   */
  hls = 'hls',
  /**
   * Original video (master)
   */
  original = 'original'
}
