import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { GridThumbModule } from '@shared/components/grid-thumb/grid-thumb.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { GridRowModule } from './grid-row/grid-row.module';
import { GridComponent } from './grid.component';

const ANGULAR_MODULES = [ CommonModule ];
const SHARED_MODULES = [ GridThumbModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...SHARED_MODULES,
    NavigationModule,
    VirtualScrollModule,
    GridRowModule

 ],
  declarations: [ GridComponent ],
  exports: [ GridComponent ]
})
export class GridModule { }
