import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '../../../../auth/services/user/user.service';
import { PurchasesConfiguration } from './purchases.config';

@UntilDestroy()
@Component({
  selector: 'ty-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: PurchasesComponent }]

})
export class PurchasesComponent extends NavigationComponent implements OnInit, OnDestroy{
  @ViewChild('purchases') purchases!: ElementRef;
  mediaItems: MediaItem<MediaItemTypes>[];
  currentUrl: string;
  title: string;
  isDataReceived: boolean;
  scrollTop: number;



  constructor(
    private browsingHistoryService: BrowsingHistoryService,
    private settingsService: SettingsService,
    public logger: NGXLogger,
    private userService: UserService,
    public el: ElementRef

  ) {
    super(el);
    this.title = PurchasesConfiguration.title;
    this.isDataReceived = false;
    this.scrollTop = 0;


  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    this.settingsService.languageSet$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
        if (focusData?.purchases) {
          this.mediaItems = focusData.purchases.items;
          this.isDataReceived = true;

        } else {
          this.getPurchases(); // Call the getPurchases function
        }
      },
    });
  }

  ngOnDestroy(): void {
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData) {
      focusData.purchases = {
        items: this.mediaItems,
      };
    }
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }


  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.purchases.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

  private getPurchases() {
    this.userService.getUserPurchases().pipe(untilDestroyed(this)).subscribe({
      next: (items) => {
        this.isDataReceived = true;
        this.mediaItems = items;
        this.logger.debug(
          'CollectionsMenuComponent -> GetCollections(): Success',
          items
        );
      },
      error: (error: HttpErrorResponse) => {
        this.logger.error('PurchasesComponent -> GetPurchases(): Failed', error);
      }
    });
  }

}
