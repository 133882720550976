<div class="items-list">
  <ul>
    <li *ngIf="!itemTracks[0]?.isAudio"
        class="itemsBtn"
        appNavigation
        [navigableId]="0"
        (click)="changeSubtitle(0, 'none')"
        [ngClass]="{'active': selectedItem === 'none'}">
      {{ 'controls-player.no-subtitles' | translate }}
    </li>
    <li class="itemsBtn"
        *ngFor="let item of itemTracks; let i = index"
        [ngClass]="{'active': item.language === selectedItem}"
        (click)="changeSubtitle(i + 1, item.language ?? item.srclang)"
        appNavigation
        [navigableId]="i + 1">
      {{ parseLabel(item.language)}}
    </li>
  </ul>
</div>
