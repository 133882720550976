import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgnoredFilters } from '@app/core/models/filter-spinner.enum';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private requestCount = 0;
  private ignoredFilters: string[] = Object.values(IgnoredFilters);

  constructor(private spinnerService: SpinnerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.method === 'GET' && !this.containsIgnoredFilter(request.url)) {
      this.requestCount++;
      this.spinnerService.showLoader();
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (request.method === 'GET' && !this.containsIgnoredFilter(request.url)) {
          this.requestCount--;
          if (this.requestCount === 0) {
            this.spinnerService.hideLoader();
          }
        }
      })
    );
  }

  private containsIgnoredFilter(url: string): boolean {
    return this.ignoredFilters.some(filter => url.includes(filter));
  }
}





