<h1>{{'exit.message' | translate}}</h1>
<div class="primary-button"
     (click)="exitApp()"
     appNavigation
     [navigableId]="0">
  <button>
    <h4>{{'exit.accept' | translate}}</h4>
  </button>
</div>
<div class="primary-button"
     (click)="navigateBack()"
     appNavigation
     [navigableId]="1">
  <button>
    <h4>{{'exit.cancel' | translate}}</h4>
  </button>
</div>