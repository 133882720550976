import { environment } from '@environments/environment';
import { ITimerState } from '../models/timer-state.interface';

export const videoProgressTimerState: ITimerState = {
  pause: true,
  increase: 500,
  timeInterval: 500,
  intervalDelay: 0,
  value: 0,
};

export const beatIntervalTimerState: ITimerState = {
  pause: true,
  increase: 1000,
  limit: environment.beatInterval,
  timeInterval: 1000,
  value: 0,
};

export const keepPlayingTimerState: ITimerState = {
  pause: true,
  increase: 1000,
  limit: environment.addPlayingInterval,
  timeInterval: 1000,
  value: 0,
};
