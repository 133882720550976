/* tslint:disable */
/* eslint-disable */

/**
 * The video status
 */
export enum VideoStatusApi {
  Predraft = 'PREDRAFT',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED',
  Unpublished = 'UNPUBLISHED'
}
