import { TabContentSelector } from '@shared/components/tabs/models/tab-label.enum';
import { ITab } from '@shared/components/tabs/models/tabs.interface';

export const TabsConfigurationVideo: ITab[] = [
  {
      id: 0,
      label: 'details.details',
      content_selector: TabContentSelector.details,
      active: false
  },
  {
      id: 1,
      label: 'details.similar',
      content_selector: TabContentSelector.similar,
      active: false
  },
  {
      id: 2,
      label: 'details.more-information',
      content_selector: TabContentSelector.moreInformation,
      active: false
  }
];

export const TabsConfigurationSerie: ITab[] = [
    {
      id: 0,
      label: 'details.chapters',
      content_selector: TabContentSelector.chapters,
      active: false,
      width: '24.188rem'
    },
    {
      id: 1,
      label: 'details.details',
      content_selector: TabContentSelector.details,
      active: false
    },
    {
      id: 2,
      label: 'details.similar',
      content_selector: TabContentSelector.similar,
      active: false
    },
    {
      id: 3,
      label: 'details.more-information',
      content_selector: TabContentSelector.moreInformation,
      active: false
    }
  ];

  export const TabsConfigurationMicrosite: ITab[] = [
    {
      id: 0,
      label: 'details.content',
      content_selector: TabContentSelector.similar,
      active: false,
      width: '24.188rem'
    }
  ];

  export const TabsConfigurationCollections: ITab[] = [
    {
      id: 0,
      label: 'details.content',
      content_selector: TabContentSelector.similar,
      active: false,
      width: '24.188rem'
    },
  ];

export const SetFocusInSimilarGrid = false;
