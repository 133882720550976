/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

import { CategoryCmsApi } from '../models/category-cms-api';
import { ContainerApi } from '../models/container-api';
import { EpgApi } from '../models/epg-api';
import { PaginatedResultApi } from '../models/paginated-result-api';
import { PersonCmsApi } from '../models/person-cms-api';
import { PersonalRoleCmsApi } from '../models/personal-role-cms-api';
import { RequestedActionsApi } from '../models/requested-actions-api';
import { RequestedActionsResponseApi } from '../models/requested-actions-response-api';
import { VideoCmsApi } from '../models/video-cms-api';
import { VideoFrontApi } from '../models/video-front-api';
import { VideoTabLayoutFrontApi } from '../models/video-tab-layout-front-api';
import { VideoUriFrontApi } from '../models/video-uri-front-api';

@Injectable({
  providedIn: 'root',
})
export class VideoApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation videoControllerFindByRanking
   */
  static readonly VideoControllerFindByRankingPath = '/video/ranking';

  /**
   * Videos from the ranking
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerFindByRanking()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerFindByRanking$Response(params: {
    language: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';

    /**
     * Page size. Minimum value: 1, Default: 10
     */
    size?: number;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<StrictHttpResponse<Array<VideoFrontApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerFindByRankingPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('image-format', params['image-format'], {});
      rb.query('size', params.size, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VideoFrontApi>>;
      })
    );
  }

  /**
   * Videos from the ranking
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerFindByRanking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerFindByRanking(params: {
    language: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';

    /**
     * Page size. Minimum value: 1, Default: 10
     */
    size?: number;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<Array<VideoFrontApi>> {

    return this.videoControllerFindByRanking$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VideoFrontApi>>) => r.body as Array<VideoFrontApi>)
    );
  }

  /**
   * Path part for operation videoControllerFindLives
   */
  static readonly VideoControllerFindLivesPath = '/video/lives';

  /**
   * Video Lives
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerFindLives()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerFindLives$Response(params: {
    language: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<StrictHttpResponse<Array<VideoFrontApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerFindLivesPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('image-format', params['image-format'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VideoFrontApi>>;
      })
    );
  }

  /**
   * Video Lives
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerFindLives$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerFindLives(params: {
    language: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<Array<VideoFrontApi>> {

    return this.videoControllerFindLives$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VideoFrontApi>>) => r.body as Array<VideoFrontApi>)
    );
  }

  /**
   * Path part for operation videoControllerLinealChannels
   */
  static readonly VideoControllerLinealChannelsPath = '/video/lineal-channels';

  /**
   * Lineal Channels
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerLinealChannels()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerLinealChannels$Response(params: {
    language: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<StrictHttpResponse<Array<VideoFrontApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerLinealChannelsPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('image-format', params['image-format'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VideoFrontApi>>;
      })
    );
  }

  /**
   * Lineal Channels
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerLinealChannels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerLinealChannels(params: {
    language: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<Array<VideoFrontApi>> {

    return this.videoControllerLinealChannels$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VideoFrontApi>>) => r.body as Array<VideoFrontApi>)
    );
  }

  /**
   * Path part for operation videoControllerGetFavorites
   */
  static readonly VideoControllerGetFavoritesPath = '/video/favorites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerGetFavorites()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerGetFavorites$Response(params: {
    language: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<StrictHttpResponse<Array<VideoFrontApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerGetFavoritesPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('image-format', params['image-format'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VideoFrontApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerGetFavorites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerGetFavorites(params: {
    language: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<Array<VideoFrontApi>> {

    return this.videoControllerGetFavorites$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VideoFrontApi>>) => r.body as Array<VideoFrontApi>)
    );
  }

  /**
   * Path part for operation videoControllerGetPlayings
   */
  static readonly VideoControllerGetPlayingsPath = '/video/playing';

  /**
   * Use /me/playing instead
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerGetPlayings()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  videoControllerGetPlayings$Response(params: {
    language: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<StrictHttpResponse<Array<ContainerApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerGetPlayingsPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('size', params.size, {});
      rb.query('image-format', params['image-format'], {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContainerApi>>;
      })
    );
  }

  /**
   * Use /me/playing instead
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerGetPlayings$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  videoControllerGetPlayings(params: {
    language: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<Array<ContainerApi>> {

    return this.videoControllerGetPlayings$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContainerApi>>) => r.body as Array<ContainerApi>)
    );
  }

  /**
   * Path part for operation videoControllerFindById
   */
  static readonly VideoControllerFindByIdPath = '/video/{id}';

  /**
   * Find a video
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerFindById$Response(params: {
    id: string;
    language?: string;

    /**
     * set version 2 to get results by video slug
     */
    version?: number;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<StrictHttpResponse<VideoFrontApi>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('language', params.language, {});
      rb.query('version', params.version, {});
      rb.query('image-format', params['image-format'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VideoFrontApi>;
      })
    );
  }

  /**
   * Find a video
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerFindById(params: {
    id: string;
    language?: string;

    /**
     * set version 2 to get results by video slug
     */
    version?: number;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<VideoFrontApi> {

    return this.videoControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<VideoFrontApi>) => r.body as VideoFrontApi)
    );
  }

  /**
   * Path part for operation videoControllerGetUris
   */
  static readonly VideoControllerGetUrisPath = '/video/{id}/uri';

  /**
   * Find the Url of a video
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerGetUris()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerGetUris$Response(params: {
    id: string;
    Authorization?: string;
  }): Observable<StrictHttpResponse<VideoUriFrontApi>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerGetUrisPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Authorization', params.Authorization, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VideoUriFrontApi>;
      })
    );
  }

  /**
   * Find the Url of a video
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerGetUris$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerGetUris(params: {
    id: string;
    Authorization?: string;
  }): Observable<VideoUriFrontApi> {

    return this.videoControllerGetUris$Response(params).pipe(
      map((r: StrictHttpResponse<VideoUriFrontApi>) => r.body as VideoUriFrontApi)
    );
  }

  /**
   * Path part for operation videoControllerFindRelatedVideo
   */
  static readonly VideoControllerFindRelatedVideoPath = '/video/{id}/related';

  /**
   * Get related videos [DEPRECATED]
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerFindRelatedVideo()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  videoControllerFindRelatedVideo$Response(params: {
    id: string;
    language?: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<StrictHttpResponse<Array<VideoFrontApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerFindRelatedVideoPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('language', params.language, {});
      rb.query('size', params.size, {});
      rb.query('image-format', params['image-format'], {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VideoFrontApi>>;
      })
    );
  }

  /**
   * Get related videos [DEPRECATED]
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerFindRelatedVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  videoControllerFindRelatedVideo(params: {
    id: string;
    language?: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<Array<VideoFrontApi>> {

    return this.videoControllerFindRelatedVideo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VideoFrontApi>>) => r.body as Array<VideoFrontApi>)
    );
  }

  /**
   * Path part for operation videoControllerFindRelatedContainer
   */
  static readonly VideoControllerFindRelatedContainerPath = '/video/{id}/relatedContainer';

  /**
   * Get related container with videos or subcontainers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerFindRelatedContainer()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerFindRelatedContainer$Response(params: {
    id: string;
    language?: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;

    /**
     * Container id to exclude from which the video has been selected
     */
    containerId?: number;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<StrictHttpResponse<ContainerApi>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerFindRelatedContainerPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('language', params.language, {});
      rb.query('size', params.size, {});
      rb.query('containerId', params.containerId, {});
      rb.query('image-format', params['image-format'], {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContainerApi>;
      })
    );
  }

  /**
   * Get related container with videos or subcontainers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerFindRelatedContainer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerFindRelatedContainer(params: {
    id: string;
    language?: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;

    /**
     * Container id to exclude from which the video has been selected
     */
    containerId?: number;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<ContainerApi> {

    return this.videoControllerFindRelatedContainer$Response(params).pipe(
      map((r: StrictHttpResponse<ContainerApi>) => r.body as ContainerApi)
    );
  }

  /**
   * Path part for operation videoControllerGetXmlVast
   */
  static readonly VideoControllerGetXmlVastPath = '/video/{id}/vast';

  /**
   * Get xml vast for video advertisements
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerGetXmlVast()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerGetXmlVast$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerGetXmlVastPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get xml vast for video advertisements
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerGetXmlVast$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerGetXmlVast(params: {
    id: string;
  }): Observable<string> {

    return this.videoControllerGetXmlVast$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation videoControllerGetLayout
   */
  static readonly VideoControllerGetLayoutPath = '/video/{id}/layout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoControllerGetLayout()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerGetLayout$Response(params: {
    id: string;
    language?: string;
  }): Observable<StrictHttpResponse<VideoTabLayoutFrontApi>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoControllerGetLayoutPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VideoTabLayoutFrontApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoControllerGetLayout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoControllerGetLayout(params: {
    id: string;
    language?: string;
  }): Observable<VideoTabLayoutFrontApi> {

    return this.videoControllerGetLayout$Response(params).pipe(
      map((r: StrictHttpResponse<VideoTabLayoutFrontApi>) => r.body as VideoTabLayoutFrontApi)
    );
  }

  /**
   * Path part for operation videoPreviewControllerFindById
   */
  static readonly VideoPreviewControllerFindByIdPath = '/preview/video/{id}';

  /**
   * Find a video
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoPreviewControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoPreviewControllerFindById$Response(params: {
    id: string;
    language?: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<StrictHttpResponse<VideoFrontApi>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoPreviewControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('language', params.language, {});
      rb.query('image-format', params['image-format'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VideoFrontApi>;
      })
    );
  }

  /**
   * Find a video
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoPreviewControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoPreviewControllerFindById(params: {
    id: string;
    language?: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<VideoFrontApi> {

    return this.videoPreviewControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<VideoFrontApi>) => r.body as VideoFrontApi)
    );
  }

  /**
   * Path part for operation videoClientControllerPendingRequests
   */
  static readonly VideoClientControllerPendingRequestsPath = '/video/requestAccess/pending';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerPendingRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerPendingRequests$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<Array<RequestedActionsResponseApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerPendingRequestsPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestedActionsResponseApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerPendingRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerPendingRequests(params?: {
    language?: string;
  }): Observable<Array<RequestedActionsResponseApi>> {

    return this.videoClientControllerPendingRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestedActionsResponseApi>>) => r.body as Array<RequestedActionsResponseApi>)
    );
  }

  /**
   * Path part for operation videoClientControllerRequestAccess
   */
  static readonly VideoClientControllerRequestAccessPath = '/video/{id}/requestAccess';

  /**
   * Request access to content owner to watch video
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerRequestAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerRequestAccess$Response(params: {

    /**
     * Video id
     */
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerRequestAccessPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Request access to content owner to watch video
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerRequestAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerRequestAccess(params: {

    /**
     * Video id
     */
    id: string;
  }): Observable<void> {

    return this.videoClientControllerRequestAccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation videoClientControllerDeny
   */
  static readonly VideoClientControllerDenyPath = '/video/{id}/requestAccess/deny';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerDeny()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerDeny$Response(params: {

    /**
     * The RequestAction ID
     */
    id: string;
  }): Observable<StrictHttpResponse<RequestedActionsApi>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerDenyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestedActionsApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerDeny$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerDeny(params: {

    /**
     * The RequestAction ID
     */
    id: string;
  }): Observable<RequestedActionsApi> {

    return this.videoClientControllerDeny$Response(params).pipe(
      map((r: StrictHttpResponse<RequestedActionsApi>) => r.body as RequestedActionsApi)
    );
  }

  /**
   * Path part for operation videoClientControllerGrant
   */
  static readonly VideoClientControllerGrantPath = '/video/{id}/requestAccess/grant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerGrant()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGrant$Response(params: {

    /**
     * The RequestAction ID
     */
    id: string;
  }): Observable<StrictHttpResponse<RequestedActionsApi>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerGrantPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestedActionsApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerGrant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGrant(params: {

    /**
     * The RequestAction ID
     */
    id: string;
  }): Observable<RequestedActionsApi> {

    return this.videoClientControllerGrant$Response(params).pipe(
      map((r: StrictHttpResponse<RequestedActionsApi>) => r.body as RequestedActionsApi)
    );
  }

  /**
   * Path part for operation videoClientControllerGetById
   */
  static readonly VideoClientControllerGetByIdPath = '/video/cms/item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetById$Response(params: {

    /**
     * The video ID
     */
    id: string;
  }): Observable<StrictHttpResponse<VideoCmsApi>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VideoCmsApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetById(params: {

    /**
     * The video ID
     */
    id: string;
  }): Observable<VideoCmsApi> {

    return this.videoClientControllerGetById$Response(params).pipe(
      map((r: StrictHttpResponse<VideoCmsApi>) => r.body as VideoCmsApi)
    );
  }

  /**
   * Path part for operation videoClientControllerCreateOrUpdate
   */
  static readonly VideoClientControllerCreateOrUpdatePath = '/video/cms/item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerCreateOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  videoClientControllerCreateOrUpdate$Response(params: {
    body: VideoCmsApi
  }): Observable<StrictHttpResponse<VideoCmsApi>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerCreateOrUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VideoCmsApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerCreateOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  videoClientControllerCreateOrUpdate(params: {
    body: VideoCmsApi
  }): Observable<VideoCmsApi> {

    return this.videoClientControllerCreateOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<VideoCmsApi>) => r.body as VideoCmsApi)
    );
  }

  /**
   * Path part for operation videoClientControllerGetCategories
   */
  static readonly VideoClientControllerGetCategoriesPath = '/video/cms/category/{typeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerGetCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetCategories$Response(params: {

    /**
     * The categoryType ID
     */
    typeId: string;
  }): Observable<StrictHttpResponse<Array<CategoryCmsApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerGetCategoriesPath, 'get');
    if (params) {
      rb.path('typeId', params.typeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CategoryCmsApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerGetCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetCategories(params: {

    /**
     * The categoryType ID
     */
    typeId: string;
  }): Observable<Array<CategoryCmsApi>> {

    return this.videoClientControllerGetCategories$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CategoryCmsApi>>) => r.body as Array<CategoryCmsApi>)
    );
  }

  /**
   * Path part for operation videoClientControllerGetCreditPersons
   */
  static readonly VideoClientControllerGetCreditPersonsPath = '/video/cms/credits/person';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerGetCreditPersons()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetCreditPersons$Response(params?: {
  }): Observable<StrictHttpResponse<Array<PersonCmsApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerGetCreditPersonsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonCmsApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerGetCreditPersons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetCreditPersons(params?: {
  }): Observable<Array<PersonCmsApi>> {

    return this.videoClientControllerGetCreditPersons$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PersonCmsApi>>) => r.body as Array<PersonCmsApi>)
    );
  }

  /**
   * Path part for operation videoClientControllerGetCreditPersonalRoles
   */
  static readonly VideoClientControllerGetCreditPersonalRolesPath = '/video/cms/credits/personal-role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerGetCreditPersonalRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetCreditPersonalRoles$Response(params?: {
  }): Observable<StrictHttpResponse<Array<PersonalRoleCmsApi>>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerGetCreditPersonalRolesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonalRoleCmsApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerGetCreditPersonalRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetCreditPersonalRoles(params?: {
  }): Observable<Array<PersonalRoleCmsApi>> {

    return this.videoClientControllerGetCreditPersonalRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PersonalRoleCmsApi>>) => r.body as Array<PersonalRoleCmsApi>)
    );
  }

  /**
   * Path part for operation videoClientControllerGetOwnerVideos
   */
  static readonly VideoClientControllerGetOwnerVideosPath = '/video/owner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoClientControllerGetOwnerVideos()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetOwnerVideos$Response(params?: {
    language?: string;
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PaginatedResultApi & { 'data'?: Array<VideoFrontApi> }>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.VideoClientControllerGetOwnerVideosPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('status', params.status, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedResultApi & { 'data'?: Array<VideoFrontApi> }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoClientControllerGetOwnerVideos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoClientControllerGetOwnerVideos(params?: {
    language?: string;
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<PaginatedResultApi & { 'data'?: Array<VideoFrontApi> }> {

    return this.videoClientControllerGetOwnerVideos$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedResultApi & { 'data'?: Array<VideoFrontApi> }>) => r.body as PaginatedResultApi & { 'data'?: Array<VideoFrontApi> })
    );
  }

  /**
   * Path part for operation epgControllerFind
   */
  static readonly EpgControllerFindPath = '/epg';

  /**
   * Get EPG
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `epgControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  epgControllerFind$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<EpgApi[]>> {

    const rb = new RequestBuilder(this.rootUrl, VideoApiService.EpgControllerFindPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EpgApi[]>;
      })
    );
  }

  /**
   * Get EPG
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `epgControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  epgControllerFind(params?: {
    language?: string;
  }): Observable<EpgApi[]> {

    return this.epgControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<EpgApi[]>) => r.body as EpgApi[])
    );
  }

}

