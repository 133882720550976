import { Video } from '@shared/models/video/video.model';
import { ItemTypeApi } from '../../galgo-api/models/item-type-api';
import { PlayerTypeApi } from '../../galgo-api/models/player-type-api';
export class PlayerStatus {
  controls: string[];
  enableControlsAndAnalytics: boolean;
  ended: boolean;
  forward: boolean;
  isaudio: boolean;
  itemType: ItemTypeApi;
  metas: Video;
  pause: boolean;
  paused: boolean;
  play: boolean;
  playerType: PlayerTypeApi;
  playing: boolean;
  rewind: boolean;
  showControls: boolean;
  title: string;
  url: string;

  constructor() {
    this.controls = [];
    this.enableControlsAndAnalytics = false;
    this.ended = false;
    this.forward = false;
    this.isaudio = false;
    this.itemType = ItemTypeApi.Video;
    this.pause = false;
    this.paused = false;
    this.play = true;
    this.playerType = PlayerTypeApi.Standard;
    this.playing = false;
    this.rewind = false;
    this.showControls = false;
    this.title = '';
  }
}
