import { Component, Input, OnInit } from '@angular/core';
import { CatalogResponseApi } from '@app/galgo-api/models';

@Component({
  selector: 'ty-catalog-item',
  templateUrl: './catalog-item.component.html',
  styleUrls: ['./catalog-item.component.scss']
})
export class CatalogItemComponent implements OnInit {

  @Input() catalog: CatalogResponseApi;
  @Input() isHovering: boolean;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  getCatalogImage(): string {
    return this.catalog?.thumbnail?.portraits[0].url;
  }

}
