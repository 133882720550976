/* tslint:disable */
/* eslint-disable */

/**
 * Language
 */
export enum SubtitleLanguagesIsoApi {
  Ca = 'ca',
  De = 'de',
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Nl = 'nl',
  Pt = 'pt',
  Sl = 'sl'
}
