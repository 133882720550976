  <qrcode
    [qrdata]="qrUrl"
    [allowEmptyString]="qrCodeConfig.allowEmptyString"
    [ariaLabel]="qrCodeConfig.ariaLabel"
    [cssClass]="'center'"
    [elementType]="qrCodeConfig.elementType"
    [errorCorrectionLevel]="qrCodeConfig.errorCorrectionLevel"
    [imageSrc]="qrCodeConfig.imageSrc"
    [imageHeight]="qrCodeConfig.imageHeight"
    [imageWidth]="qrCodeConfig.imageWidth"
    [margin]="qrCodeConfig.margin"
    [scale]="qrCodeConfig.scale"
    [title]="qrCodeConfig.title"
    [width]="qrCodeConfig.width">
  </qrcode>
