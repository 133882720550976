/* tslint:disable */
/* eslint-disable */

/**
 * action to be performed
 */
export enum FileActionApi {
  Write = 'write',
  Resumable = 'resumable',
  Publish = 'publish',
  Read = 'read'
}
