import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { IButtonConfig } from './button-config.interface';

@Component({
  selector: 'ty-button-list',
  templateUrl: './button-list.component.html',
  styleUrls: ['./button-list.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ButtonListComponent }]
})
export class ButtonListComponent extends NavigationComponent {

  @Input() btnList: IButtonConfig[];
  @Output() clickEvent?: EventEmitter<string>;

  constructor(
    public el: ElementRef
   ) {
    super(el);
    this.clickEvent = new EventEmitter<string>();
  }

  clickButton(button: IButtonConfig){
    this.clickEvent.emit(button.action);
  }

}
