import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';



@Component({
  selector: 'ty-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: PublicLayoutComponent }]
})
export class PublicLayoutComponent extends NavigationComponent implements OnInit {
  routeActive: string;

  get disableOverlay(): boolean {
    return this.routeActive !== AppRoutes.restrictedAccess;
  }

  constructor(
    private router: Router,
    public el: ElementRef
  ) {
    super(el);
  }

  ngOnInit(): void {
    this.routeActive = this.router.url;
  }

}
