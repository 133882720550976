import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContainerTypes } from '@app/core/models/container-types.enum';
import { Container, IContainerParams } from '@app/core/models/container.model';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { ContainerService } from '@app/core/services/container.service';
import { LanguageService } from '@app/core/services/language.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { Collection } from '@app/shared/models/collection/collection.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { CollectionsMenuConfiguration } from './collections-menu.config';

@UntilDestroy()
@Component({
  selector: 'ty-collections-menu',
  templateUrl: './collections-menu.component.html',
  styleUrls: ['./collections-menu.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: CollectionsMenuComponent }]

})
export class CollectionsMenuComponent extends NavigationComponent implements OnInit, OnDestroy{
  @ViewChild('collections') collections!: ElementRef;

  mediaItems: MediaItem<MediaItemTypes>[];
  containerItems: Container[];
  isDataReceived: boolean;
  currentUrl: string;
  title: string;
  scrollTop: number;


  constructor(
    private browsingHistoryService: BrowsingHistoryService,
    private containerService: ContainerService,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    public logger: NGXLogger,
    public el: ElementRef

  ) {
    super(el);
    this.title = CollectionsMenuConfiguration.title;
    this.isDataReceived = false;
    this.scrollTop = 0;

  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    this.settingsService.languageSet$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
        if (focusData?.collectionmenu) {
          this.mediaItems = focusData.collectionmenu.items;
          this.isDataReceived = true;

        } else {
          this.getCollections();
        }
      },
    });
  }

  ngOnDestroy(): void {
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData) {
      focusData.collectionmenu = {
        items: this.mediaItems,
      };
    }
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }


  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.collections.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

  private getCollections() {
    const params: IContainerParams = {
      language: this.languageService.currentLang,
      containerType: ContainerTypes.collection,
      page: 1,
      size: 15,
    };
    this.containerService.getContainerByCategoryType(params).pipe(untilDestroyed(this)).subscribe({
      next: (items: Collection[]) => {
        this.isDataReceived = true;
        this.mediaItems = items;
        this.logger.debug(
          'CollectionsMenuComponent -> GetCollections(): Success',
          items
        );
      },
      error: (error: HttpErrorResponse) => {
        this.logger.error(
          'CollectionsMenuComponent -> GetCollections(): Failed',
          error
        );
      }
    });

  }


}
