import { environment } from '@environments/environment';
import {default as apiMock} from '../../../../api-mock/db.json';

export interface IUrlMock {
  url: string;
  json: any;
}

export const urlMocks = [
  {
    url: environment.apiUrl + '/me/favorites',
    json: apiMock.favourites,
  }
];
