import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { SvgIconItemModule } from '@shared/components/svg-icon-item/svg-icon-item.module';
import { CustomRouterModule } from '../router/custom-router.module';
import { KeyboardComponent } from './keyboard.component';

const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    NavigationModule,
    SvgIconItemModule,
    CustomRouterModule,
    NavigationModule
  ],
  declarations: [
    KeyboardComponent,
  ],
  exports: [
    KeyboardComponent,
  ]
})
export class KeyboardModule { }
