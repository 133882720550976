/* tslint:disable */
/* eslint-disable */

/**
 * The company activity
 */
export enum CompanyActivityApi {
  Festival = 'FESTIVAL',
  AudiovisualMarket = 'AUDIOVISUAL_MARKET',
  AudiovisualLab = 'AUDIOVISUAL_LAB',
  FilmLibrary = 'FILM_LIBRARY',
  SalesAgency = 'SALES_AGENCY',
  Distribution = 'DISTRIBUTION',
  Streaming = 'STREAMING',
  BroadcastingTvOperation = 'BROADCASTING_TV_OPERATION',
  Exhibition = 'EXHIBITION',
  Production = 'PRODUCTION',
  OfficialBodyPromotionalAgency = 'OFFICIAL_BODY_PROMOTIONAL_AGENCY',
  Investment = 'INVESTMENT',
  Aggregation = 'AGGREGATION',
  Association = 'ASSOCIATION',
  Other = 'OTHER'
}
