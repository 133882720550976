import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/auth/services/user/user.service';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { TopicApi } from '@app/galgo-api/models';
import { CustomerDataService } from '@core/services/customer-data.service';
import { FeatureFlagsService } from '@core/services/feature-flags.service';

@Component({
  selector: 'ty-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: LandingPageComponent }],
})
export class LandingPageComponent extends NavigationComponent {
  appRoutes: typeof AppRoutes;
  customerProductName: string;
  defaultLogoUrl: string;
  exploreMode: boolean;
  hideCreateAccount: boolean;

  get clientTopic(): TopicApi {
    return this.customerDataService.companyData?.clientTopic;
  }

  constructor(
    private featureFlagsService: FeatureFlagsService,
    private customerDataService: CustomerDataService,
    private router: Router,
    private userService: UserService,
    public el: ElementRef
  ) {
    super(el);
    this.appRoutes = AppRoutes;

    if (this.userService.currentUser) {
      this.goTo(this.appRoutes.home);
    }

    this.customerProductName = this.customerDataService?.customerProductName;
    this.defaultLogoUrl = this.customerDataService?.logoFile;
    this.exploreMode = this.featureFlagsService?.currentFeaturesValue?.exploreMode;
    this.hideCreateAccount = this.featureFlagsService?.currentFeaturesValue?.hideCreateAccountButton;
  }


  goTo(route: string): void {
    this.router.navigate([route]);
  }

  onBackKey(): boolean {
    if(this.featureFlagsService?.currentFeaturesValue?.haveCatalogs){
      this.router.navigate([AppRoutes.catalogSelector]);
    }else{
      this.router.navigate([AppRoutes.exitApp]);
    }
    return true;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeId() === 3) {
      this.selectNextChildNode();
    }
    return true;
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() === 4) {
      this.selectPreviousChildNode();
    }
    return true;
  }


  onDownKey(): boolean {
    if (this.getSelectedChildNodeId() < 3) {
      this.selectNextChildNode();
    }
    return true;
  }

  onUpKey(): boolean {
    if (this.getSelectedChildNodeId() < 4) {
      this.selectPreviousChildNode();
    } else {
      this.selectPreviousChildNode();
      this.selectPreviousChildNode();
    }
    return true;
  }

}
