import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { IconList } from '@app/core/models/icon-list.enum';
import { AppRoutes, ExternalAppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { IFeatureToggle } from '@core/models/feature-toggle.interface';
import { BrowsingHistoryService } from '@core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@core/services/customer-data.service';
import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ty-login-form-page',
  templateUrl: './login-form-page.component.html',
  styleUrls: ['./login-form-page.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: LoginFormPageComponent }]
})
export class LoginFormPageComponent extends NavigationComponent {
  appRoutes: typeof AppRoutes;
  featureToggleData: Observable<IFeatureToggle>;
  showPassword: boolean;
  showBackButton: boolean;

  isKeyboardVisible: boolean;
  errorInform: boolean;

  get helpUrlRoute(): string {
    return `${this.customerDataService.webBaseUrl}/${ExternalAppRoutes.help}`;
  }

  get returnIconUrl(): string {
    return `./assets/icons/${IconList.returnIcon}#icon`;
  }


  constructor(
    private customerDataService: CustomerDataService,
    private featureFlagsService: FeatureFlagsService,
    private browsingHistoryService: BrowsingHistoryService,
    private router: Router,
    public el: ElementRef
  ) {
    super(el);
    this.appRoutes = AppRoutes;
    this.featureToggleData = this.featureFlagsService.features;
    this.showPassword = true;
    this.isKeyboardVisible = false;
    this.errorInform = false;
    this.showBackButton = this.featureFlagsService?.currentFeaturesValue?.showBackButton;
    this.setDefaultActiveChildNodeId(1);
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onUpKey(): boolean {
    this.selectPreviousChildNode();
    return true;
  }

  return(): void {
    this.router.navigate([AppRoutes.login]);
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }

  isVirtualKeyboardVisible(event: boolean) {
    this.isKeyboardVisible = event;
  }

  setErrorInForm(event: boolean) {
    this.errorInform = event;
  }

}
