<div>
  <div appNavigation
       [navigableId]="0"
       (click)="navigateBack()"
       id="return-button">
    <span class="text">
      <i class="gi-lg gi-return-icon"></i>
      {{'buttons.return' | translate}}
    </span>
  </div>
</div>