import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { IFeatureToggle } from '@app/core/models/feature-toggle.interface';
import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestrictedAccessGuard implements CanActivate {

  featureToggleData: Observable<IFeatureToggle>;
  features: IFeatureToggle;

  constructor(
    private router: Router,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.getFeatures();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const accessParam = route.queryParams.k;
    if (accessParam) {
      localStorage.setItem('tempKey', accessParam);
    }

    const accessKey = localStorage.getItem('tempKey');

    if (!accessKey && this.features.restrictedAccess) {
      this.router.navigate(['restricted-access']);
      return false;
    }
    return true;
  }

  private getFeatures() {
    this.featureToggleData = this.featureFlagsService.features;
    this.features = this.featureFlagsService.currentFeaturesValue;
  }
}
