import { Component, Input } from '@angular/core';
import { CatalogService } from '@app/auth/services/catalog/catalog.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { CustomerDataService } from '@core/services/customer-data.service';

@Component({
  selector: 'ty-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  @Input() logo: string;
  @Input() showTitle = true;
  haveCatalog: boolean;

  get customerName(): string {
    return this.customerDataService.enterpriseName;
  }

  get customerProductName(): string {
    return this.customerDataService?.customerProductName;
  }
  constructor(
    private customerDataService: CustomerDataService,
    private featureflags: FeatureFlagsService,
    private catalogService: CatalogService,
    ){
    this.haveCatalog = this.featureflags.currentFeaturesValue.haveCatalogs;
    }

    getCatalogImage(): string {
      return this.catalogService.getImageCatalog();
    }


}
