import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { LegalTextApi } from '@api/models';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { LegalConditionsCustomService } from '@core/legal-conditions/legal-conditions.service';
import { throwError } from 'rxjs';

@Component({
  selector: 'ty-legal-conditions',
  templateUrl: './legal-conditions.component.html',
  styleUrls: ['./legal-conditions.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: LegalConditionsComponent },
  ],
})
export class LegalConditionsComponent
  extends NavigationComponent
  implements OnInit
{
  LegalTextApi: string;
  isLoading: boolean;

  constructor(
    private legalConditionsService: LegalConditionsCustomService,
    public el: ElementRef
  ) {
    super(el);

  }

  ngOnInit(): void {
    this.getTermsOfUse();
  }

  /**
   * Recieves all legal conditions and instances the terms of use markdown text
   *
   * @private
   * @memberof LegalTextComponent
   */
  private getTermsOfUse() {
    this.legalConditionsService.getLegalConditions().subscribe({
      next: (response: LegalTextApi) => {
        this.LegalTextApi = response.legalConditions;
      },
      error: (error: HttpErrorResponse) =>
        throwError(() => new HttpErrorResponse(error)),
    });
  }
}
