import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { QrCodeComponent } from './qr-code.component';

const ANGULAR_MODULES = [ CommonModule ];
const EXTERNAL_MODULES = [ TranslateModule ];

@NgModule({
  imports: [
    ANGULAR_MODULES,
    EXTERNAL_MODULES,
    QRCodeModule
  ],
  declarations: [ QrCodeComponent ],
  exports: [ QrCodeComponent ]
})
export class QrCodeModuleCustom { }
