<div id="purchases-menu">
  <h4>{{ title | translate }}</h4>
  <div class="black-gradient-overlay"></div>
  <div class="grid-overflow-hidden"
       #purchases>
    <ty-grid class="grid"
             *ngIf="mediaItems?.length && this.isDataReceived"
             [items]="mediaItems"
             [navigableId]="0"
             (updateScroll)="updateScroll()">
    </ty-grid>
  </div>
  <div class="no-purchases-wrapper"
       *ngIf="!mediaItems?.length && this.isDataReceived">
    <span class="title">{{ "purchases.no-purchases" | translate }}</span>
    <span class="text">{{ "purchases.text" | translate }}</span>
  </div>
</div>