export const languageMap = {
  spa: 'Español',
  es: 'Español',
  cat: 'Català',
  ca: 'Català',
  eng: 'English',
  en: 'English',
  eus: 'Euskara',
  eu: 'Euskara',
  fra: 'Français',
  fr: 'Français',
  deu: 'Deutsch',
  de: 'Deutsch',
  glg: 'Galego',
  gl: 'Galego',
  ita: 'Italiano',
  it: 'Italiano',
  por: 'Português',
  pt: 'Português',
  rus: 'Русский',
  ru: 'Русский',
  zho: '中文',
  zh: '中文',
  jpn: '日本語',
  ja: '日本語',
  kor: '한국어',
  ko: '한국어',
  ara: 'العربية',
  ar: 'العربية',
  hin: 'हिन्दी',
  hi: 'हिन्दी',
  ben: 'বাংলা',
  bn: 'বাংলা',
  urd: 'اردو',
  ur: 'اردو',
  tam: 'தமிழ்',
  ta: 'தமிழ்',
  tel: 'తెలుగు',
  te: 'తెలుగు',
  mar: 'मराठी',
  mr: 'मराठी',
  guj: 'ગુજરાતી',
  gu: 'ગુજરાતી',
  pol: 'Polski',
  pl: 'Polski',
  ukr: 'Українська',
  uk: 'Українська',
  nld: 'Nederlands',
  nl: 'Nederlands',
  tur: 'Türkçe',
  tr: 'Türkçe',
  swe: 'Svenska',
  sv: 'Svenska',
  fin: 'Suomi',
  fi: 'Suomi',
  dan: 'Dansk',
  da: 'Dansk',
  nor: 'Norsk',
  no: 'Norsk',
  hun: 'Magyar',
  hu: 'Magyar',
  ces: 'Čeština',
  cs: 'Čeština',
  gre: 'Ελληνικά',
  el: 'Ελληνικά',
  heb: 'עברית',
  he: 'עברית',
  tha: 'ไทย',
  th: 'ไทย',
  val: 'Valencià',
  vie: 'Tiếng Việt',
  vi: 'Tiếng Việt',
  ind: 'Bahasa Indonesia',
  id: 'Bahasa Indonesia',
  mal: 'മലയാളം',
  ml: 'മലയാളം',
  yid: 'ייִדיש',
  yi: 'ייִדיש',
  fil: 'Filipino',
  tl: 'Filipino'
};
