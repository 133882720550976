import { TagType } from '@app/shared/models/tag-type.enum';
import { ITag } from '@shared/models/tag.interface';

export const suscriptionTag: ITag = {
  icon: TagType.subscription,
  text: 'Suscription',
};

export const ppvTag: ITag = {
  icon: TagType.ppv,
  text: 'Price'
};

export const liveTag: ITag = {
  icon: TagType.live,
  text: 'Live'
};
