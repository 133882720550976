import { Component, ElementRef } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';

@Component({
  selector: 'ty-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: MainLayoutComponent }]
})
export class MainLayoutComponent extends NavigationComponent {
  constructor(
    private browsingHistoryService: BrowsingHistoryService,
    public el: ElementRef
  ) {
    super(el);
    this.setDefaultActiveChildNodeId(1);
  }

  onLeftKey(): boolean {
    this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }

  onEnterKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
      this.selectNextChildNode();
    }
    return true;
  }
}
