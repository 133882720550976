import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { UserService } from '@app/auth/services/user/user.service';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { mySpaceButtonIcons } from '@app/pages/protected/media-detail/configuration/video-detail.config';
import { ItemTypes } from '@app/shared/models/item-types.enum';
import { IconList } from '@core/models/icon-list.enum';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { MySpaceService } from '@core/services/my-space.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from '@shared/components/modal/services/modal.service';
import { IIconSource } from '@shared/models/button/icon-source.interface';
import { NGXLogger } from 'ngx-logger';
import { throwError } from 'rxjs';
import { first } from 'rxjs/operators';
import { FAVOURITE_MODAL_DATA, ModalActions } from '../modal/config';

@UntilDestroy()
@Component({
  selector: 'ty-my-space-button',
  templateUrl: './my-space-button.component.html',
  styleUrls: ['./my-space-button.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: MySpaceButtonComponent },
  ],
})
export class MySpaceButtonComponent
  extends NavigationComponent
{
  @Input() set id(value: string) {
    this._id = value;
    this.getFavorites();

  }
  @Input() itemType: ItemTypes = ItemTypes.Video || ItemTypes.Collection;

  buttonText: string;
  icons: IIconSource[];
  selectedIcons: IIconSource;
  iconName: string;
  favouriteItems: MediaItem<MediaItemTypes>[];
  favouriteState: boolean;
  isKeyboardVisible: boolean;
  private _id: string;

  constructor(
    private analyticsService: AnalyticsService,
    private browsingHistoryService: BrowsingHistoryService,
    private modalService: ModalService,
    private mySpaceService: MySpaceService,
    private router: Router,
    private userService: UserService,
    public logger: NGXLogger,
    public el: ElementRef
  ) {
    super(el);
    this.icons = mySpaceButtonIcons;
    this.buttonText = 'menu.favourites';
    this.isKeyboardVisible = false;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  focus(): void {
    setTimeout(() => {
      if (this.favouriteState) {
        this.buttonText = 'my-space.added-hover';
      }
      this.iconName = this.selectedIcons?.filled;
    }, 0);
  }

  blur(): void {
    setTimeout(() => {
      if (this.favouriteState) {
        this.buttonText = 'my-space.added';
      }
      this.iconName = this.selectedIcons?.unfilled;
    }, 0);
  }

  changeState(): void {
    this.checkLoginFavorites();
    if (this.favouriteState) {
      this.removeFavouriteMediaItem();
    } else {
      this.sendFavouriteMediaItem();
    }
  }

  isVirtualKeyboardVisible(event: boolean) {
    this.isKeyboardVisible = event;
  }

  private checkLoginFavorites(): void{
    if (!this.userService.isLoggedIn()){
        this.modalService.open(FAVOURITE_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction));
    }
  }
  private getFavorites(): void {
    if(this.userService.isLoggedIn()){
      this.mySpaceService
      .getFavorites()
      .pipe(untilDestroyed(this))
      .subscribe(
        (items: MediaItem<MediaItemTypes>[]) => {
          this.favouriteItems = items;
          this.favouriteState = this.mySpaceService.setMySpaceState(this.favouriteItems, this._id);
          this.setText();
          this.setIcons();
          this.iconName = this.selectedIcons?.unfilled;
          this.logger.debug(
            'MySpaceComponent -> GetFavorites(): Success',
            items
          );
        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            'MySpaceComponent -> GetFavorites(): Failed',
            error
          );
          throw new Error('Error obtaining favorites');
        }
      );
    } else {
      this.favouriteState = this.mySpaceService.setMySpaceState(this.favouriteItems, this._id);
      this.setText();
      this.setIcons();
      this.iconName = this.selectedIcons?.unfilled;
    }
  }

  private manageModalAction(action: ModalActions): void {
    switch(action) {
      case ModalActions.identify:
        this.router.navigate([AppRoutes.login]);
        break;
      case ModalActions.close:
      default:
        this.selectChildNodeById(0);
        break;
    }
  }

  private sendFavouriteMediaItem(): void {
    if (!this.userService.isLoggedIn()){
      return;
    } else {
      this.mySpaceService
      .sendFavouriteMediaItem(this._id, this.itemType)
      .pipe(first())
      .subscribe({
        next: () => {
          this.logger.debug(
            'MySpaceService -> SendFavouriteMediaItem(): Success'
          );
          this.analyticsService.logEvent(GalgoEvent.addToFavorites, {
            containerId: this._id,
          });
          this.changeMySpaceState();
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error(
            'MySpaceService -> SendFavouriteMediaItem(): Failed',
            error
          );
          throwError(() => new HttpErrorResponse(error));
        },
        complete: () => {
          this.browsingHistoryService.removeFocusData(AppRoutes.mySpace);
        }
      });
    }
  }

  private removeFavouriteMediaItem(): void {
    this.mySpaceService
      .removeFavouriteMediaItem(this._id, this.itemType)
      .pipe(first())
      .subscribe({
        next: () => {
          this.logger.debug(
            'MySpaceService -> removeFavouriteMediaItem(): Success'
          );
          this.changeMySpaceState();
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error(
            'MySpaceService -> removeFavouriteMediaItem(): Failed',
            error
          );
          throwError(() => new HttpErrorResponse(error));
        },
        complete: () => {
          this.browsingHistoryService.removeFocusData(AppRoutes.mySpace);
        }
      });
  }

  private changeMySpaceState(): void {
    this.favouriteState = !this.favouriteState;
    this.setText();
    this.setIcons();
  }

  private setIcons(): void {
    this.selectedIcons = this.icons?.find((icon: IIconSource) => {
      if (this.favouriteState) {
      this.iconName = IconList.removeMySpaceIcon;
      } else {
        this.iconName = IconList.addMySpaceIcon;
      }
      return icon?.filled === this.iconName;
    });
  }

  private setText(): void {
    if (this.favouriteState) {
      this.buttonText = 'my-space.added';
    } else {
      this.buttonText = 'my-space.add';
    }
  }
}
