<div class="chapters-wrapper">
  <ty-seasons-selector [navigableId]="0"
                       class="seasons"
                       [seasons]="seasons"
                       [selectedSeason]="selectedSeason"
                       (selectSeason)="setSelectSeason($event)"></ty-seasons-selector>
  <ty-chapters-selector [navigableId]="1"
                        class="chapters"
                        [seasonNumber]="selectedSeason"
                        [chapters]="chapters"></ty-chapters-selector>
</div>