<div class="grid-wrapper">
  <ty-grid *ngIf="mediaItems"
           [items]="mediaItems"
           [setInitFocus]="setFocusInGrid"
           [navigable]="true"
           [navigableId]="0"
           (updateScroll)="updateScroll()"
           #similar
           [galgoEvent]="galgoEvent"
           [videoId]="contentId"></ty-grid>
</div>
