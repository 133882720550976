import { Component } from '@angular/core';
import { DogLogoApi, DogLogoPositionApi } from '@app/galgo-api/models';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';

@Component({
  selector: 'ty-dog',
  templateUrl: './dog.component.html',
  styleUrls: ['./dog.component.scss']
})
export class DogComponent {
  dogPosition: DogLogoPositionApi;
  dogLog: DogLogoApi;

  get getDogLog(): string {
    return this.dogLog?.uri ?? '';
  }

  constructor(private videoDetailService: VideoDetailService) {
    this.dogLog = this.videoDetailService.getDogLogo();
    this.dogPosition = this.dogLog?.position || DogLogoPositionApi.TopRight;
   }

}
