import { Injectable } from '@angular/core';
import { ContainerApi } from '@api/models/container-api';
import { ContainerApiService } from '@api/services/container-api.service';
import { ContainerTypeApi } from '@app/galgo-api/models/container-type-api';
import { CacheService } from '@app/shared/services/cache.service';
import { IBaseApiParams } from '@core/models/base-api-params.interface';
import {
  Container,
  IContainer,
  IContainerByIdParams,
  IContainerParams
} from '@core/models/container.model';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { Carousel } from '@shared/features/carousel/model/carousel.model';
import { Collection } from '@shared/models/collection/collection.model';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class ContainerService {

  constructor(
    private containerService: ContainerApiService,
    private languageService: LanguageService,
    public logger: NGXLogger,
    private cacheService: CacheService

  ) {}

  getContainerById(params: IContainerByIdParams): Observable<Container> {
    const cacheKey = this.cacheService.generateCacheKey('getContainerById');

    return this.cacheService.getFromCache<Container>(cacheKey).pipe(
      switchMap(cachedResult => {
        if (cachedResult) {
          return of(cachedResult);
        }

        return this.containerService.containerControllerFindById(params).pipe(
          map((response: IContainer) => new Container(response)),
          tap(result => {
            this.cacheService.addToCache(cacheKey, result);
          })
        );
      })
    );
  }

  getContainerByCategoryType(params: IContainerParams): Observable<Container[]> {
    // To avoid error in containerType interface
    const adjustedParams = {
    ...params,
    containerType: params.containerType as unknown as ContainerTypeApi
  };
    return this.containerService
      .containerControllerFind(adjustedParams)
      .pipe(
        map((response: ContainerApi[]) =>
          response.map((container: ContainerApi) => new Container(container as IContainer)
        ))
      );
  }

  getCollectionByContainerId(params: IContainerByIdParams): Observable<Collection> {
    return this.containerService
      .containerControllerFindById(params)
      .pipe(map((response: IContainer) => new Collection(response)));
  }

  getContainerMediaItemsById(
    params: IContainerByIdParams
  ): Observable<MediaItem<MediaItemTypes>[]> {
    return this.containerService
      .containerControllerFindById(params)
      .pipe(
        map((response: IContainer) =>
          this.getMediaItemsFromContainer(new Container(response))
        )
      );
  }

  getCustomCarousels(params: IBaseApiParams): Observable<Carousel[]> {
    const cacheKey = this.cacheService.generateCacheKey('getCustomCarousels');

    return this.cacheService.getFromCache<Carousel[]>(cacheKey).pipe(
      switchMap(cachedResult => {
        if (cachedResult) {
          return of(cachedResult);
        }

        return this.containerService.containerControllerGetCustomContainers(params).pipe(
          map((containers: IContainer[]) =>
            containers.map((carousel: IContainer) => {
              carousel.isCustom = true;
              return new Carousel(carousel);
            })
          ),
          tap(result => {
            this.cacheService.addToCache(cacheKey, result);
          })
        );
      })
    );
  }

  getCustomContainers(params: IBaseApiParams): Observable<Container[]> {
    return this.containerService
      .containerControllerGetCustomContainers(params)
      .pipe(
        map((response: ContainerApi[]) =>
          response.map(
            (container: ContainerApi) => new Container(container as IContainer)
          )
        )
      );
  }

  getRelatedContainer(params: IContainerByIdParams): Observable<Container> {
    return this.containerService.containerControllerFindRelatedContainer(params).pipe(
      map((container: IContainer) => {
        const relatedContainer = new Container(container);
        relatedContainer.title = this.languageService.instant('common.titles.related-videos');
        return relatedContainer;
      })
    );
  }

  getRelatedContainerAsCarousel(params: IContainerByIdParams): Observable<Carousel> {
    return this.containerService.containerControllerFindRelatedContainer(params).pipe(
      map((container: IContainer) => {
        const relatedCarousel = new Carousel(container);
        relatedCarousel.title = this.languageService.instant('common.titles.related-videos');
        relatedCarousel.isRelated = true;
        return relatedCarousel;
      })
    );
  }

  getMediaItemsFromContainer(container: Container): MediaItem<MediaItemTypes>[] {
    const collections: MediaItem<Collection>[] = container.subContainers.map(
      (element: IContainer) => new Collection(element)
    );

    return [...container.videos, ...collections];
  }
}
