import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RibbonModule } from '@shared/components/ribbon/ribbon.module';
import { SubscriptionModule } from '@shared/components/subscription/subscription.module';
import { TagModule } from '../tag/tag.module';
import { GridThumbComponent } from './grid-thumb.component';

const ANGULAR_MODULES = [ CommonModule ];
const SHARED_MODULES = [ SubscriptionModule, RibbonModule ];

@NgModule({
    declarations: [GridThumbComponent],
    exports: [GridThumbComponent],
    imports: [
        ...ANGULAR_MODULES,
        ...SHARED_MODULES,
        TagModule
    ]
})
export class GridThumbModule { }
