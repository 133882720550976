import { Injectable } from '@angular/core';
import { ItemTypeApi, VideoAccessApi } from '@api/models';
import { ISubscriptionResult } from '@app/auth/models/check-subscription.interface';
import { SubscriptionPlan } from '@app/auth/models/subscription-plan.model';
import { SubscriptionResults } from '@app/auth/models/subscription-results.enum';
import { AuthService } from '@app/auth/services/auth-custom/auth.service';
import { UserService } from '@app/auth/services/user/user.service';
import { IFeatureToggle } from '@app/core/models/feature-toggle.interface';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { Collection } from '@shared/models/collection/collection.model';
import { ItemTypes } from '@shared/models/item-types.enum';
import { Video } from '@shared/models/video/video.model';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckSubscriptionService {
  features: IFeatureToggle;
  featureToggleData: Observable<IFeatureToggle>;
  constructor(
    private logger: NGXLogger,
    private authCustomService: AuthService,
    private userService: UserService,
    private featureFlagsService: FeatureFlagsService
  ) {
    this.getFeatures();
  }

  blockChapters(collection: MediaItem<Collection>): boolean[] {
    const allVideos: MediaItem<Video>[] = collection?.getAllChapters();
    const canAccessToVideos: boolean[] = [];
    for (const video of allVideos) {
      canAccessToVideos.push(!this.blockMedia(video).result);
    }
    return canAccessToVideos;
  }

  blockMedia(mediaItem: MediaItem<MediaItemTypes>): ISubscriptionResult {
    let hasSubscription: boolean;
    let blockPlayer = false;
    let products: string[];
    let reason = SubscriptionResults.noUserAuthenticated;
    switch (mediaItem?.type) {
      case ItemTypes.Collection:
        const collection = mediaItem;
        hasSubscription = !!collection?.subscription?.name;
        products = [collection?.subscription?._id];
        if (collection?.access === VideoAccessApi.Register) {
          return { result: !this.userService.isLoggedIn(), reason };
        }
        break;
      case ItemTypes.Video:
        const video = mediaItem;
        hasSubscription = video?.access === VideoAccessApi.Subscription;
        if (video?.access === VideoAccessApi.Register) {
          return { result: !this.userService.isLoggedIn(), reason };
        }
        products = video?.products;
        break;
    }

    if (this.features?.enableSubscription && hasSubscription) {
      const token = this.authCustomService?.token;
      const userSubscriptions: string[] =
        this.userService?.userProducts?.subscriptions?.map(
          (value: SubscriptionPlan) => value.id
        ) || [];
      const userPaidVideos: string[] = this.userService?.userProducts?.paidVideos || [];
      const purchased = [...userSubscriptions, ...userPaidVideos];

      if (!token?.accessToken) {
        this.logger.info(
          'CheckSubscriptionService -> blockMedia: Error',
          'No access token, signInRequired'
        );
        reason = SubscriptionResults.noUserAuthenticated;
        blockPlayer = true;
      } else {
        if (!!products?.length && !(!!purchased?.length)) {
          this.logger.info(
            'CheckSubscriptionService -> blockMedia: Error',
            'No user subscriptions, subscriptionRequired'
          );
          reason = this.checkTypeOfError(mediaItem);
          blockPlayer = true;
        } else {
          if (!products.some((value: string) => purchased.includes(value))) {
            this.logger.info('CheckSubscriptionService -> blockMedia: Error');
            reason = this.checkTypeOfError(mediaItem);
            blockPlayer = true;
          }
        }
      }
    }
    return { result: blockPlayer, reason };
  }

  private checkTypeOfError(mediaItem: MediaItem<MediaItemTypes>) {
    switch (mediaItem?.type) {
      case ItemTypeApi.Container:
        return SubscriptionResults.subscriptionError;
      case ItemTypeApi.Video:
        const video = mediaItem;
        if (video.layout?.ppv?._id && video.layout?.subscription?._id) {
          return SubscriptionResults.noUserSubscriptionsAndPpv;
        }
        if (video.layout?.ppv?._id) {
          return SubscriptionResults.ppvError;
        }
        if (video.layout?.subscription?._id) {
          return SubscriptionResults.subscriptionError;
        }
        return undefined;
      default:
        return undefined;
    }
  }

  private getFeatures() {
    this.featureToggleData = this.featureFlagsService.features;
    this.features = this.featureFlagsService.currentFeaturesValue;
  }
}
