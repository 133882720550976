import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponseApi } from '@api/models';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { PlayerDrivers } from '@app/galgo-player/models';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { IPlayPauseButton } from '@app/shared/models/button-player.interface';
import { PlayerStatus } from '@app/shared/models/player-status.model';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { ActionList } from '@core/models/action-list.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IButtonConfig } from '@shared/components/button-list/button-config.interface';
import { IIconSource } from '@shared/models/button/icon-source.interface';
import { NGXLogger } from 'ngx-logger';
import { buttonsPlayerSelector } from '../controls-player/configuration/player-general.config';
@UntilDestroy()
@Component({
  selector: 'ty-button-player',
  templateUrl: './button-player.component.html',
  styleUrls: ['./button-player.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: ButtonPlayerComponent },
  ],
})
export class ButtonPlayerComponent
  extends NavigationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() config?: IButtonConfig | IPlayPauseButton;
  isVideoPaused: boolean;
  driver: PlayerDrivers;
  public focus = false;
  private _configButton: IButtonConfig;
  private _configPlayPauseButton: IPlayPauseButton;


  get configButton(): IButtonConfig {
    return this._configButton;
  }

  get configPlayPauseButton(): IPlayPauseButton {
    return this._configPlayPauseButton;
  }

  get action(): ActionList {
    if (this.configPlayPauseButton) {
      if (this.isVideoPaused) {
        return this.configPlayPauseButton?.play?.action;
      } else {
        return this.configPlayPauseButton?.pause?.action;
      }
    } else {
      return this.configButton?.action;
    }
  }

  get buttonsPlayerSelector(): string {
    return buttonsPlayerSelector;
  }

  get icon(): IIconSource {
    switch (this.action) {
      case ActionList.play:
        return this.configPlayPauseButton?.play?.icon;
      case ActionList.pause:
        return this.configPlayPauseButton?.pause?.icon;
      default:
        return this.configButton?.icon;
    }
  }
  get iconName(): string {
    if (
      document.getElementById(this.buttonsPlayerSelector + this.action) ===
      document.activeElement
    ) {
      return this.icon?.filled;
    } else {
      return this.icon?.unfilled;
    }
  }
  constructor(
    private changeDetector: ChangeDetectorRef,
    public logger: NGXLogger,
    private galgoPlayerService: GalgoPlayerService,
    public el: ElementRef,
    private router: Router,
    private videoDetailService: VideoDetailService

  ) {
    super(el);
    this.driver = this.galgoPlayerService.getDriver();
  }
  ngOnDestroy(): void {}

  public changeIcon() {
    this.focus = true;
  }

  public iconOut() {
    this.focus = false;
  }

  onEnterKey(): boolean {
    (
      this.getChildNodeByPosition(this.getSelectedChildNodeId()).el
        .nativeElement as HTMLElement
    ).click();
    return true;
  }

  ngOnInit(): void {
    if (this.config?.action === ActionList.playPause) {
      this.setConfigButtonPlayPause();
    } else {
      this.setConfigButton();
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.playingChanges();
  }

  buttonPlayerEvent() {
      switch (this.action) {
        case ActionList.play:
          this.galgoPlayerService.driver?.play();
          this.galgoPlayerService.updateStatus((status) => (status.playing = true));
          break;
        case ActionList.pause:
          this.galgoPlayerService.driver?.pause();
          this.galgoPlayerService.updateStatus((status) => (status.playing = false));
          break;
        case ActionList.rewind:
          this.galgoPlayerService.driver?.rewind(10);
          break;
        case ActionList.forward:
          this.galgoPlayerService.driver?.forward(10);
          break;
        case ActionList.nextChapter:
          this.router.navigate([AppRoutes.player, this.videoDetailService.getVideoNext()._id]);
          this.galgoPlayerService.driver?.play();
          break;
      }
  }

  private playingChanges() {
    this.galgoPlayerService
      .select((status) => status.playing)
      .pipe(untilDestroyed(this))
      .subscribe(
        (status: PlayerStatus) => {
          this.isVideoPaused = !status.playing;
          this.changeDetector.detectChanges();
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'ButtonPlayerComponent -> PlayingChanges(): Failed',
            error
          );
        }
      );
  }

  private setConfigButton() {
    this._configButton = this.config as IButtonConfig;
  }

  private setConfigButtonPlayPause() {
    this._configPlayPauseButton = this.config as IPlayPauseButton;
  }
}
