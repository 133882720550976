<div id="grid-thumb"
     (mouseenter)="mouseEnter()"
     (mouseleave)="mouseLeave()">
  <div class="thumb"
       #thumbElement
       [style.background-image]="'url('+ thumbnail || defaultPortrait +')'"></div>
  <div class="info">
    <div class="metadata">
      <ty-tag *ngIf="isLive"
              [content]="liveTag"
              [forceOpen]="focused">
      </ty-tag>
      <ty-tag *ngIf="subscription"
              [content]="suscriptionTag"
              [forceOpen]="focused">
      </ty-tag>
      <ty-tag *ngIf="ppv"
              [content]="ppvTag"
              [forceOpen]="focused"></ty-tag>
    </div>
    <h3 *ngIf="useThumbnailTitle; else useTitle">{{thumbnailTitle}}</h3>
    <ng-template #useTitle>
      <h3>{{title}}</h3>
    </ng-template>
  </div>
</div>