<div class="left-col">
  <span class="shortDescription" *ngIf="shortDescription">{{shortDescription}}</span>
  <div class="description" *ngIf="description">{{description}}</div>
</div>
<div class="right-col">
  <ty-title-info [label]="dateLabel" [info]="date" *ngIf="date"></ty-title-info>
  <ty-title-info [label]="pegiLabel" [info]="[pegi]" *ngIf="pegi"></ty-title-info>
</div>
<div class="right-col">
  <ty-title-info [label]="producerLabel" [info]="[producer]" *ngIf="producer"></ty-title-info>
  <ty-title-info [label]="labelsLabel" [info]="labels" *ngIf="showLabels"></ty-title-info>
</div>
