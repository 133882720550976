import { Component, Input } from '@angular/core';

@Component({
  selector: 'ty-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.scss']
})
export class RibbonComponent {
  @Input() ranking: number;
}
