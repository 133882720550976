<div class="details-descriptions"
     id="details-descriptions"
     appNavigation
     [navigableId]="0">
  <div class="display">
    <div class="text-wrapper"
         id="markdown">
      <markdown class="markdown"
                [data]="detailDescription"></markdown>
    </div>
  </div>
</div>