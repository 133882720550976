<div id="legal-display">
  <div class="display">
    <div class="head">
      {{title | translate}}
      <div class="primary-button"
           (click)="close()"
           appNavigation
           [navigableId]="0">
        <button class="button-medium">{{'buttons.close' |
          translate}}</button>
      </div>
    </div>
    <div class="separator"></div>
    <div class="text-wrapper"
         id="markdown">
      <markdown class="markdown"
                [data]="text"
                appNavigation
                [navigableId]="1"></markdown>
    </div>
  </div>
</div>