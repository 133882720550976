import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateYear'
})
export class DateYearPipe implements PipeTransform {

  transform(value?: number): string {
    if (!!value) {
      return `${this.getYear(value)}`;
    }

    return '';
  }

  private getYear(value: number): string {

    const fullDate = new Date(value);
    return fullDate.getFullYear().toString();
  }


}
