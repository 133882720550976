import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { LoginRedirectGuard } from '@app/auth/guards/login-redirect.guard';
import { MainLayoutComponent } from './main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'settings',
        pathMatch: 'full',
      },
       {
         path: 'home',
         canActivate: [AuthGuard],
         loadChildren: () => import('./home/home-page.module').then((m) => m.HomeModule),
       },
      {
        path: 'video',
        canActivate: [AuthGuard],
        loadChildren: () => import('./media-detail/media-detail.module').then((m) => m.MediaDetailModule),
      },
      {
        path: 'series',
        canActivate: [AuthGuard],
        loadChildren: () => import('./media-detail/media-detail.module').then((m) => m.MediaDetailModule),
      },
      {
        path: 'microsites',
        canActivate: [AuthGuard],
        loadChildren: () => import('./media-detail/media-detail.module').then((m) => m.MediaDetailModule),
      },
      {
        path: 'collections',
        canActivate: [AuthGuard],
        loadChildren: () => import('./media-detail/media-detail.module').then((m) => m.MediaDetailModule),
      },
      {
        path: 'my-space',
        canActivate: [LoginRedirectGuard],
        loadChildren: () => import('./my-space/my-space.module').then((m) => m.MySpaceModule),
      },
      {
        path: 'search',
        canActivate: [AuthGuard],
        loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'collections-menu',
        canActivate: [AuthGuard],
        loadChildren: () => import('./collections-menu/collections-menu.module').then((m) => m.CollectionsMenuModule),
      },
      {
        path: 'genres',
        canActivate: [AuthGuard],
        loadChildren: () => import('./genres/genres.module').then((m) => m.GenresModule),
      },
      {
        path: 'purchases',
        canActivate: [LoginRedirectGuard],
        loadChildren: () => import('./purchases/purchases.module').then((m) => m.PurchasesModule),
      },
      {
        path: 'see-more',
        canActivate: [AuthGuard],
        loadChildren: () => import('./see-more/see-more.module').then((m) => m.SeeMoreModule),
      },
      {
        path: 'settings',
        canActivate: [LoginRedirectGuard],
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'lives',
        canActivate: [AuthGuard],
        loadChildren: () => import('./lives/lives.module').then((m) => m.LivesModule),
      },
      {
        path: 'login-redirect',
        loadChildren: () => import('./login-redirect/login-redirect.module').then((m) => m.LoginRedirectModule),
      },
    ]
  },
  {
    path: 'player',
    loadChildren: () => import('./player/player.module').then((m) => m.PlayerModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProtectedPagesRoutingModule { }
