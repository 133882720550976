import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { ButtonModule } from '@shared/components/button/button.module';
import { ButtonListComponent } from './button-list.component';

const ANGULAR_MODULES = [ CommonModule ];
const SHARED_MODULES = [ ButtonModule, NavigationModule];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...SHARED_MODULES ],
  declarations: [ ButtonListComponent],
  exports: [ ButtonListComponent]
})
export class ButtonListModule { }
