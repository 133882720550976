/* tslint:disable */
/* eslint-disable */

/**
 * availability of license: INCLUDED: list is whitelist // EXCLUDED: list is blacklist
 */
export enum LicenseAvailabilityApi {
  Included = 'included',
  Excluded = 'excluded'
}
