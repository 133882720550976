import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { LoadingSpinnerModule } from '@app/shared/components/loading-spinner/loading-spinner.module';
import { TranslateModule } from '@ngx-translate/core';
import { GridModule } from '@shared/components/grid/grid.module';
import { MySpaceRoutingModule } from './my-space-routing.module';
import { MySpaceComponent } from './my-space/my-space.component';

const ANGULAR_MODULES = [ CommonModule ];
const COMPONENTS_MODULES = [
  GridModule,
  LoadingSpinnerModule
];
const EXTERNAL_MODULES = [ TranslateModule ];
@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...COMPONENTS_MODULES,
    ...EXTERNAL_MODULES,
    MySpaceRoutingModule,
    NavigationModule,
  ],
  declarations: [
    MySpaceComponent
  ]
})
export class MySpaceModule { }
