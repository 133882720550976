import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { IModalData } from '@app/shared/models/modal-data.interface';
import { ModalActions } from './config';

@Component({
  selector: 'ty-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ModalComponent }],
})
export class ModalComponent extends NavigationComponent {
  @Input() data: IModalData;

  @Output() closeModal: EventEmitter<ModalActions>;

  closeAction: ModalActions;

  constructor(
    public elementRef: ElementRef
  ) {
    super(elementRef);
    this.closeModal = new EventEmitter();
    this.closeAction = ModalActions.close;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onLeftKey(): boolean {
      this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
   this.selectNextChildNode();
    return true;
  }

  onUpKey(): boolean {
      return true;
  }

  onDownKey(): boolean {
      return true;
  }

  onBackKey(): boolean {
    this.closeModal.emit(this.closeAction);
    return true;
  }

  actionClicked(action: ModalActions): void {
    this.closeModal.emit(action);
  }
}
