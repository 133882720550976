import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RibbonComponent } from './ribbon.component';
const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [ ...ANGULAR_MODULES ],
  declarations: [ RibbonComponent ],
  exports: [ RibbonComponent ]
})
export class RibbonModule { }
