<div *ngIf="!haveCatalog"
     class="title-content">
     <ng-container *ngTemplateOutlet="showTitle ? showTitleText : noShowTitleText"></ng-container>
</div>
<img *ngIf="haveCatalog"
     class="catalog-image"
     [src]="getCatalogImage()"
     alt="{{customerProductName}}" />

<ng-template #showTitleText>
     <img [src]="logo" />
     <h3 >{{customerName}}</h3>
</ng-template>

<ng-template #noShowTitleText>
     <img [src]="logo" class="logoMenuFullWidth"/>
</ng-template>
