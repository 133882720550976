/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderApi } from '../models/order-api';
import { OrderBudgetApi } from '../models/order-budget-api';
import { OrderConfigResponseApi } from '../models/order-config-response-api';
import { OrderDownloadUriApi } from '../models/order-download-uri-api';
import { OrderResponseApi } from '../models/order-response-api';
import { PaginatedResultApi } from '../models/paginated-result-api';
import { UploadFileDtoApi } from '../models/upload-file-dto-api';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation orderClientControllerFind
   */
  static readonly OrderClientControllerFindPath = '/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerFind$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PaginatedResultApi & {
'data'?: Array<OrderResponseApi>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerFindPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedResultApi & {
        'data'?: Array<OrderResponseApi>;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerFind(params?: {
    page?: number;
    limit?: number;
  }): Observable<PaginatedResultApi & {
'data'?: Array<OrderResponseApi>;
}> {

    return this.orderClientControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedResultApi & {
'data'?: Array<OrderResponseApi>;
}>) => r.body as PaginatedResultApi & {
'data'?: Array<OrderResponseApi>;
})
    );
  }

  /**
   * Path part for operation orderClientControllerSave
   */
  static readonly OrderClientControllerSavePath = '/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  orderClientControllerSave$Response(params: {
    body: OrderApi
  }): Observable<StrictHttpResponse<Array<OrderApi>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  orderClientControllerSave(params: {
    body: OrderApi
  }): Observable<Array<OrderApi>> {

    return this.orderClientControllerSave$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderApi>>) => r.body as Array<OrderApi>)
    );
  }

  /**
   * Path part for operation orderClientControllerCalculateBudget
   */
  static readonly OrderClientControllerCalculateBudgetPath = '/order/{id}/budget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerCalculateBudget()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerCalculateBudget$Response(params: {

    /**
     * order Id
     */
    id: string;
  }): Observable<StrictHttpResponse<OrderBudgetApi>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerCalculateBudgetPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderBudgetApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerCalculateBudget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerCalculateBudget(params: {

    /**
     * order Id
     */
    id: string;
  }): Observable<OrderBudgetApi> {

    return this.orderClientControllerCalculateBudget$Response(params).pipe(
      map((r: StrictHttpResponse<OrderBudgetApi>) => r.body as OrderBudgetApi)
    );
  }

  /**
   * Path part for operation orderClientControllerFindById
   */
  static readonly OrderClientControllerFindByIdPath = '/order/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerFindById$Response(params: {

    /**
     * order Id
     */
    id: string;
  }): Observable<StrictHttpResponse<OrderResponseApi>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderResponseApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerFindById(params: {

    /**
     * order Id
     */
    id: string;
  }): Observable<OrderResponseApi> {

    return this.orderClientControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<OrderResponseApi>) => r.body as OrderResponseApi)
    );
  }

  /**
   * Path part for operation orderClientControllerUpdate
   */
  static readonly OrderClientControllerUpdatePath = '/order/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  orderClientControllerUpdate$Response(params: {
    id: string;
    body: OrderApi
  }): Observable<StrictHttpResponse<Array<OrderApi>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  orderClientControllerUpdate(params: {
    id: string;
    body: OrderApi
  }): Observable<Array<OrderApi>> {

    return this.orderClientControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderApi>>) => r.body as Array<OrderApi>)
    );
  }

  /**
   * Path part for operation orderClientControllerDelete
   */
  static readonly OrderClientControllerDeletePath = '/order/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerDelete$Response(params: {

    /**
     * order Id
     */
    id: string;
  }): Observable<StrictHttpResponse<Array<OrderApi>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerDelete(params: {

    /**
     * order Id
     */
    id: string;
  }): Observable<Array<OrderApi>> {

    return this.orderClientControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderApi>>) => r.body as Array<OrderApi>)
    );
  }

  /**
   * Path part for operation orderClientControllerUpdateDocs
   */
  static readonly OrderClientControllerUpdateDocsPath = '/order/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerUpdateDocs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  orderClientControllerUpdateDocs$Response(params: {
    id: string;
    body: OrderApi
  }): Observable<StrictHttpResponse<OrderApi>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerUpdateDocsPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerUpdateDocs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  orderClientControllerUpdateDocs(params: {
    id: string;
    body: OrderApi
  }): Observable<OrderApi> {

    return this.orderClientControllerUpdateDocs$Response(params).pipe(
      map((r: StrictHttpResponse<OrderApi>) => r.body as OrderApi)
    );
  }

  /**
   * Path part for operation orderClientControllerFindConfig
   */
  static readonly OrderClientControllerFindConfigPath = '/order/config/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerFindConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerFindConfig$Response(params: {
    language?: string;

    /**
     * Possible values: linkBOE,reason, sector, 
     *   organization, useOfMaterial, format, newProductionZoneType,licenceDurationType, profile
     */
    id: string;
  }): Observable<StrictHttpResponse<Array<OrderConfigResponseApi>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerFindConfigPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderConfigResponseApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerFindConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerFindConfig(params: {
    language?: string;

    /**
     * Possible values: linkBOE,reason, sector, 
     *   organization, useOfMaterial, format, newProductionZoneType,licenceDurationType, profile
     */
    id: string;
  }): Observable<Array<OrderConfigResponseApi>> {

    return this.orderClientControllerFindConfig$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderConfigResponseApi>>) => r.body as Array<OrderConfigResponseApi>)
    );
  }

  /**
   * Path part for operation orderClientControllerUpdateStatus
   */
  static readonly OrderClientControllerUpdateStatusPath = '/order/update-status/{id}/{status}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerUpdateStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerUpdateStatus$Response(params: {
    id: string;
    status: 'DRAFT' | 'PENDING_VALIDATE_FILMO' | 'NOT_COMPLETED_INFORMATION' | 'APPROVED_NOT_PAID' | 'PAID_NOT_USER_SIGNED' | 'NOT_FILMO_SIGNED' | 'COMPLETED' | 'REJECTED' | 'CANCELED_BY_USER';
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerUpdateStatusPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerUpdateStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerUpdateStatus(params: {
    id: string;
    status: 'DRAFT' | 'PENDING_VALIDATE_FILMO' | 'NOT_COMPLETED_INFORMATION' | 'APPROVED_NOT_PAID' | 'PAID_NOT_USER_SIGNED' | 'NOT_FILMO_SIGNED' | 'COMPLETED' | 'REJECTED' | 'CANCELED_BY_USER';
  }): Observable<void> {

    return this.orderClientControllerUpdateStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation orderClientControllerUpload
   */
  static readonly OrderClientControllerUploadPath = '/order/{id}/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerUpload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  orderClientControllerUpload$Response(params: {

    /**
     * Order ID
     */
    id: string;
    body: UploadFileDtoApi
  }): Observable<StrictHttpResponse<OrderApi>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerUploadPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerUpload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  orderClientControllerUpload(params: {

    /**
     * Order ID
     */
    id: string;
    body: UploadFileDtoApi
  }): Observable<OrderApi> {

    return this.orderClientControllerUpload$Response(params).pipe(
      map((r: StrictHttpResponse<OrderApi>) => r.body as OrderApi)
    );
  }

  /**
   * Path part for operation orderClientControllerDownloadDocument
   */
  static readonly OrderClientControllerDownloadDocumentPath = '/order/{id}/download/{file}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerDownloadDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerDownloadDocument$Response(params: {

    /**
     * Order ID
     */
    id: string;

    /**
     * File name
     */
    file: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerDownloadDocumentPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('file', params.file, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerDownloadDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerDownloadDocument(params: {

    /**
     * Order ID
     */
    id: string;

    /**
     * File name
     */
    file: string;
  }): Observable<void> {

    return this.orderClientControllerDownloadDocument$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation orderClientControllerGetUris
   */
  static readonly OrderClientControllerGetUrisPath = '/order/{id}/uris';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderClientControllerGetUris()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerGetUris$Response(params: {

    /**
     * Order ID
     */
    id: string;
  }): Observable<StrictHttpResponse<Array<OrderDownloadUriApi>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderApiService.OrderClientControllerGetUrisPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderDownloadUriApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `orderClientControllerGetUris$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderClientControllerGetUris(params: {

    /**
     * Order ID
     */
    id: string;
  }): Observable<Array<OrderDownloadUriApi>> {

    return this.orderClientControllerGetUris$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderDownloadUriApi>>) => r.body as Array<OrderDownloadUriApi>)
    );
  }

}
