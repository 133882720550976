import { GalgoEventType, IGalgoEvent, otherEvents, purchaseEvents, userEvents, videoEvents }
  from '@app/analytics/models/galgo-event.interface';

export const analyticsEventsConfig: IGalgoEvent[] = [
  {
    type: GalgoEventType.video,
    events: videoEvents,
  },
  {
    type: GalgoEventType.user,
    events: userEvents,
  },
  {
    type: GalgoEventType.purchase,
    events: purchaseEvents,
  },
  {
    type: GalgoEventType.other,
    events: otherEvents,
  },
];

