import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { LoadingSpinnerModule } from '@app/shared/components/loading-spinner/loading-spinner.module';
import { TranslateModule } from '@ngx-translate/core';
import { GridModule } from '@shared/components/grid/grid.module';
import { KeyboardModule } from '@shared/components/keyboard/keyboard.module';
import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search/search.component';

const ANGULAR_MODULES = [ CommonModule ];
const COMPONENTS_MODULES = [
  GridModule,
  KeyboardModule,
  LoadingSpinnerModule
];
const EXTERNAL_MODULES = [ TranslateModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...COMPONENTS_MODULES,
    ...EXTERNAL_MODULES,
    SearchRoutingModule,
    NavigationModule
  ],
  declarations: [
    SearchComponent
  ]
})
export class SearchModule { }
