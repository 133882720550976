export const IMAGE_FORMAT_ENDPOINTS: (string | RegExp)[] = [
  '/catalogs',
  '/container',
  '/container/custom',
  /^\/container\/[^/]+$/,
  /^\/container\/[^/]+\/related$/,
  /^\/person\/[^/]+$/,
  /^\/preview\/video\/[^/]+$/,
  '/search',
  '/search/filters',
  '/search/popular',
  '/video/favorites',
  /^\/video\/[^/]+$/,
  /^\/video\/[^/]+\/relatedContainer$/,
  '/video/lineal-channels',
  '/video/lives',
  '/video/ranking'
];
