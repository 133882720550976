import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { CatalogResponseApi } from '@app/galgo-api/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  catalogSelected: BehaviorSubject<string>;
  frontUrlCatalogSelected: BehaviorSubject<string>;
  imageCatalog: string;
  catalogSelectedId: string;

  constructor(
    private environmentService: EnvironmentService
  ) {
    this.catalogSelected = new BehaviorSubject<string>(environmentService.apiUrl);
    this.frontUrlCatalogSelected = new BehaviorSubject<string>(environmentService.apiUrl);
   }

  setCatalog(catalog: CatalogResponseApi): void {
    this.setImageCatalog(catalog.thumbnail.portraits[0].url);
    this.catalogSelected.next(catalog.backUrl);
  }

  setCatalogSelectedId(catalogId: string): void {
    this.catalogSelectedId = catalogId;
  }

  getCatalogSelectedId(): string {
    return this.catalogSelectedId;
  }

  setFrontUrlCatalog(catalog: CatalogResponseApi): void {
    this.frontUrlCatalogSelected.next(catalog.frontUrl);
  }

  setImageCatalog(image: string): void {
    this.imageCatalog = image;
  }

  getImageCatalog(): string {
    return this.imageCatalog;
  }

  getCatalogSelected(): string {
    return this.catalogSelected.value;
  }

  getFrontUrlCatalog(): string {
    return this.frontUrlCatalogSelected.value;
  }

}
