import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LabelsComponent } from './labels.component';

const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [ ...ANGULAR_MODULES ],
  declarations: [ LabelsComponent ],
  exports: [ LabelsComponent ]
})
export class LabelsModule { }
