import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CustomErrorApi, ErrorResponseApi, ImageSizeApi, UserFrontApi, VideoAccessApi } from '@api/models';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { ISubscriptionResult } from '@app/auth/models/check-subscription.interface';
import { SubscriptionResults } from '@app/auth/models/subscription-results.enum';
import { CheckSubscriptionService } from '@app/auth/services/subscription/check-subscription.service';
import { UserService } from '@app/auth/services/user/user.service';
import { ActionList } from '@app/core/models/action-list.enum';
import { Container } from '@app/core/models/container.model';
import { ILayoutPayment } from '@app/core/models/layout-payment.interface';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { PlayingService } from '@app/core/services/playing.service';
import { playerOptions } from '@app/galgo-player/config/galgo-player.config';
import { IGalgoPlayerOptions, ITrack, PlayerDetailType } from '@app/galgo-player/models';
import { IDrmConfig } from '@app/galgo-player/models/drm-config.interface';
import {
  GENREIC_ERROR_MODAL_DATA,
  ModalActions,
  REGISTER_MODAL_DATA,
  SUBSCRIPTION_MODAL_DATA
} from '@app/shared/components/modal/config';
import { TabContentSelector } from '@app/shared/components/tabs/models/tab-label.enum';
import { Collection } from '@app/shared/models/collection/collection.model';
import { ItemTypes } from '@app/shared/models/item-types.enum';
import { PlayerStatus } from '@app/shared/models/player-status.model';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { PlayerDetailsService } from '@app/shared/services/player-details.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IButtonConfig } from '@shared/components/button-list/button-config.interface';
import { IDetailConfig } from '@shared/components/detail/detail-config';
import { ModalService } from '@shared/components/modal/services/modal.service';
import { ITab } from '@shared/components/tabs/models/tabs.interface';
import { TabsService } from '@shared/components/tabs/services/tabs.service';
import { Video } from '@shared/models/video/video.model';
import { NGXLogger } from 'ngx-logger';
import { videoPlayerEvents } from '../../player/player/config/video-details.config';
import { SettingsService } from '../../settings/services/settings.service';
import {
  TabsConfigurationCollections,
  TabsConfigurationMicrosite,
  TabsConfigurationSerie,
  TabsConfigurationVideo
} from '../configuration/tabs.config';
import { CollectionDetailService } from '../services/collection-detail.service';
import { VideoDetailService } from '../services/video-detail.service';
import {
  videoDetailBtnListConfig,
  videoDetailConfig,
} from './media-detail.config';

@UntilDestroy()
@Component({
  selector: 'ty-media-detail',
  templateUrl: './media-detail.component.html',
  styleUrls: ['./media-detail.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: MediaDetailComponent },
  ],
})
export class MediaDetailComponent
  extends NavigationComponent
  implements OnInit, AfterViewInit {
  @HostBinding('style.backgroundColor') backgroundColor: string;

  adsUrl: string;
  blockVideoResult: ISubscriptionResult;
  drmConfig: IDrmConfig;
  itemType: ItemTypes;
  containerType: string;
  isHeaderHidden: boolean;
  isKeyboardVisible: boolean;
  isLoading: boolean;
  isModalOpen: boolean;
  isTrailer: boolean;
  mediaItem: Video;
  options: IGalgoPlayerOptions;
  playerDetails: PlayerDetailType;
  price: string;
  seasons: Container[];
  selectedTab: string;
  showBackButton: boolean;
  showConfirm: boolean;
  showTrailer: boolean;
  showPpvModal = false;
  trailer: string;
  trailerFlag: boolean;
  trailerStart: boolean;
  videoDetailBtnListConfig: IButtonConfig[];
  videoDetailConfig: IDetailConfig;
  mediaId: string;
  mediaMetas: Video | Collection;
  mediaItems: MediaItem<MediaItemTypes>[];
  videoSubtitles: ITrack[];
  currentUrl: string;
  returnSelectedTab: ITab;
  actualFocus: number;


  get blockVideoResultReason(): SubscriptionResults {
    return this.blockVideoResult.reason;
  }

  get blockNoUserAuthenticated(): boolean {
    return (
      this.blockVideoResult?.reason === SubscriptionResults.noUserAuthenticated
    );
  }

  get blockNoUserSubscriptions(): boolean {
    return (
      this.blockVideoResult?.reason === SubscriptionResults.noUserSubscriptions
    );
  }

  get blockSubscriptionError(): boolean {
    return (
      this.blockVideoResult?.reason === SubscriptionResults.subscriptionError
    );
  }

  get blockPpvError(): boolean {
    return this.blockVideoResult?.reason === SubscriptionResults.ppvError;
  }

  get blockNoUserSubscriptionsAndPpv(): boolean {
    return (
      this.blockVideoResult?.reason ===
      SubscriptionResults.noUserSubscriptionsAndPpv
    );
  }

  get payPerView(): ILayoutPayment {
    return this.mediaMetas?.layout?.ppv;
  }

  get subscription(): ILayoutPayment {
    return this.mediaMetas?.layout?.subscription;
  }

  get tabs(): ITab[] {
    return this.tabsService.tabs;
  }

  get thumbnail(): string {
    const landscapes = this.mediaMetas?.thumbnail?.landscapes;
    const landscape = this.mediaMetas?.thumbnail?.landscape;
    const preferenceOrder = [ImageSizeApi.High, ImageSizeApi.Original];

    if (landscapes?.length > 0) {
      const found = preferenceOrder.map(preference => landscapes.find(value => value.size === preference)).find(url => url);
      return found?.url ?? this.defaultThumbnail;
    }
    return landscape ?? this.defaultThumbnail;
  }

  get defaultThumbnail(): string {
    return this.customerDataService.defaultBanner;

  }

  get videoTitle(): string {
    return this.mediaMetas?.title;
  }

  get videoId(): string {
    return this.mediaMetas?.id;
  }

  constructor(
    public logger: NGXLogger,
    public el: ElementRef,
    private analyticsService: AnalyticsService,
    private browserHistoryService: BrowsingHistoryService,
    private changeDetector: ChangeDetectorRef,
    private checkSubscriptionService: CheckSubscriptionService,
    private collectionDetailService: CollectionDetailService,
    private customerDataService: CustomerDataService,
    private featureFlagsService: FeatureFlagsService,
    private galgoPlayerService: GalgoPlayerService,
    private location: Location,
    private modalService: ModalService,
    private playerDetailsService: PlayerDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private tabsService: TabsService,
    private userService: UserService,
    private videoDetailService: VideoDetailService,
    private browsingHistoryService: BrowsingHistoryService,
    private playingService: PlayingService
  ) {
    super(el);
    this.isHeaderHidden = false;
    this.isKeyboardVisible = false;
    this.isModalOpen = false;
    this.isTrailer = false;
    this.options = playerOptions;
    this.settingsService.lastView$ = 'detail-info';
    this.showBackButton = this.featureFlagsService?.currentFeaturesValue?.showBackButton;
    this.showConfirm = false;
    this.showTrailer = true;
    this.trailerFlag = this.featureFlagsService?.currentFeaturesValue?.enableTrailers;
    this.trailerStart = false;
    this.currentUrl = '';
    this.backgroundColor = '';

    this.videoDetailBtnListConfig = videoDetailBtnListConfig;
    this.videoDetailConfig = videoDetailConfig;

    this.setDefaultActiveChildNodeId(1);
  }

  ngOnInit(): void {
    this.galgoPlayerService.reset();

    this.tabsService.resetTabsActive();
    this.urlMediaType();
    this.getMediaIdFromUrl();
    this.selectTabsConfig();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.getPlayerEvents();
  }

  urlMediaType(): void {
    const urlActual = this.location.path();
    const types = /(\/video|\/series|\/microsite|\/collection)/;
    const matches = urlActual.match(types);

    if (matches && matches.length > 0) {
      const lowerItemType = matches[0].substring(1);
      this.containerType = lowerItemType.charAt(0).toUpperCase() + lowerItemType.slice(1);
    }
  }

  selectTabsConfig(): void {
    switch (this.containerType) {
      case 'Video':
        this.tabsService.setTabsConfig(TabsConfigurationVideo);
        break;

      case 'Series':
        this.tabsService.setTabsConfig(TabsConfigurationSerie);
        break;

      case 'Collection':
        this.tabsService.setTabsConfig(TabsConfigurationCollections);
        break;

      case 'Microsite':
        this.tabsService.setTabsConfig(TabsConfigurationMicrosite);
        break;

      default:
        this.logger.debug('MediaDetailComponent => Error in selectTabsConfig()');
        break;
    }
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onUpKey(): boolean {
    const selectedChildNodeId = this.getSelectedChildNodeId();
    const showBackButton = this.featureFlagsService.currentFeaturesValue.showBackButton;

    if (selectedChildNodeId > 0) {
      if (showBackButton && selectedChildNodeId === 2) {
        this.selectChildNodeById(0);
      } else if (!showBackButton && selectedChildNodeId <= 2) {
        return true;
      } else {
        this.selectPreviousChildNode();
      }
    }

    return true;
  }

  onDownKey(): boolean {
    if (
      this.getSelectedChildNodeId() === 1 ||
      this.getSelectedChildNodeId() === 2
    ) {
      this.isHeaderHidden = true;
      this.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      this.selectChildNodeById(3);
    }

    this.selectNextChildNode();
    return true;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeId() === 1) {
      this.selectNextChildNode();
    }
    return true;
  }

  onLeftKey(): boolean {
      if (this.getSelectedChildNodeId() === 2) {
        this.selectPreviousChildNode();
        return true;
      } else {
        this.tabsService.resetTabsActive();
        this.actualFocus = this.getSelectedChildNodeId();
        return false;
      }
  }

  onBackKey(): boolean {

    const tabContents = [
      TabContentSelector.similar,
      TabContentSelector.chapters,
      TabContentSelector.moreInformation,
      TabContentSelector.details
    ];

    if (this.isHeaderHidden || tabContents.includes(this.settingsService.lastView$ as TabContentSelector)) {
    this.closeTabs();
    this.settingsService.lastView$ = '';
    } else {
      this.browserHistoryService.goBack();
    }

    return true;
  }

  onActivate(): void {
    if (this.actualFocus > 2) {
      this.selectChildNodeById(3);
    }else{
      this.selectChildNodeById(1);
    }
  }

  closeTabs() {
    this.isHeaderHidden = false;
    this.backgroundColor = '';
    this.settingsService.lastView$ = '';
    this.tabsService.resetTabsActive();
    setTimeout(() => {
      this.selectChildNodeById(1);
    }, 0);
  }

  selectTab(selectedTab: ITab) {
    this.selectedTab = selectedTab.content_selector;
    this.isHeaderHidden = true;
    this.backgroundColor = 'rgba(0, 0, 0, 0.8)';
  }

  getVideoMetas(): void {
    this.showTrailer = false;

    this.videoDetailService
      .getVideoDetails(this.mediaId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (videoMetas: Video) => {
          this.mediaMetas = videoMetas;
          this.mediaItem = videoMetas;
          this.itemType = ItemTypes.Video;
          this.trailer = this.mediaMetas.trailer || '';
          if(this.trailer){
            this.isTrailer = true;
          }
          this.drmConfig =  undefined;
          this.videoSubtitles = [];
          this.checkSubscription();
          if (this.mediaMetas instanceof Video) {
            this.generatePlayerDetails(this.mediaItem);
            this.galgoPlayerService.updateStatus((status) => {
              status.url = (this.mediaMetas as Video).trailer;
              this.showTrailer = true;
              this.getPlayerEvents();
            });
          }
          this.logger.debug(
            'VideoDetailComponent -> GetVideoMetas(): Success',
            videoMetas
          );
          this.analyticsService.logEvent(GalgoEvent.videoDetail, {
            video: this.mediaMetas,
          });
          setTimeout(() => {
            this.selectChildNodeById(1);
            this.lastVisitedTab();
          }, 200);

        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            'VideoDetailComponent -> GetVideoMetas(): Failed',
            error
          );
          if (error?.error?.error === CustomErrorApi.$4042) {
            this.router.navigate([AppRoutes.home]);
          }
        }
      );
  }

  getCollectionMetas() {
    this.collectionDetailService
      .getCollectionDetails(this.mediaId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (collectionMetas: Collection) => {
          this.mediaMetas = collectionMetas;
          this.itemType = ItemTypes.Collection;
          this.checkSubscription();
          this.logger.debug(
            'CollectionDetailComponent -> GetCollectionMetas(): Success',
            collectionMetas
          );
          this.changeDetector.detectChanges();
          setTimeout(() => {
            this.selectChildNodeById(1);
            this.lastVisitedTab();
          }, 200);
        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            'CollectionDetailComponent -> GetCollectionMetas(): Failed',
            error
          );
          if (error?.error?.error === CustomErrorApi.$4043) {
            this.router.navigate([AppRoutes.home]);
          }
        }
      );
  }


  handleFocusData(focusData, tabContentSelector): void {
    const tab = focusData?.[tabContentSelector];
    if(tab){
      this.returnSelectedTab = this.tabsService.selectTab(TabContentSelector[tabContentSelector]);
      this.isHeaderHidden = true;
      this.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      this.selectTab(this.returnSelectedTab);
      setTimeout(() => {
        this.selectChildNodeById(4);
      }, 200);
    }
  }

  lastVisitedTab(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    //check the last visited tab
    if (focusData?.visitedTab?.similarTab) {
      this.handleFocusData(focusData, 'similar');
    }
    if (focusData?.visitedTab?.chaptersTab) {
      this.handleFocusData(focusData, 'chapters');
    }
  }

  clickEvent(action: string): void {
    switch (action) {
      case ActionList.play:
        if (this.userService.isLoggedIn()){
          this.userService.setNewUserInfo();
        }
        this.checkSubscription();
        if (this.mediaMetas?.id) {
          if (this.blockVideoResult.result) {
            switch(this.blockVideoResult.reason) {
              case SubscriptionResults.noUserAuthenticated:
                this.modalService.open(REGISTER_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction));
                break;
              case SubscriptionResults.noUserSubscriptions:
              case SubscriptionResults.noUserSubscriptionsAndPpv:
              case SubscriptionResults.subscriptionError:
                this.modalService.open(SUBSCRIPTION_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction));
                break;
              case SubscriptionResults.ppvError:
                this.price = this.payPerView.name.value;
                this.showPpvModal = true;
                setTimeout(() => {
                  this.selectChildNodeById(10);
                }, 0);
                this.modalService.closeModal$.subscribe({
                  next: () => {
                    this.showPpvModal = false;
                    this.selectChildNodeById(1);
                  },
                  error:(error) => {
                    this.logger.error('modalService -> closeModal: Error CloseModal$', error);
                    throw error;
                  }
                });
                break;
              default:
                this.modalService.open(GENREIC_ERROR_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction));
                break;
            }
          } else {


            switch (this.containerType) {
              case 'Video':
                this.analyticsService.logEvent(GalgoEvent.videoFromDetail, {
                  video: this.mediaMetas,
                });
                this.router.navigate([AppRoutes.player, this.mediaId]);
                break;

              case 'Series':
                if ((this.mediaMetas as Collection)?.subContainers[0]?.videos[0]?.access === VideoAccessApi.Register
                  && !this.userService.isLoggedIn()){
                  this.modalService.open(REGISTER_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction));
                }else{
                  this.playLastChapter();
                }
                break;

              case 'Collection':

                break;

              case 'Microsite':

                break;

              default:
                this.logger.debug('MediaDetailComponent => Error in clickEvent()');
                this.router.navigate([AppRoutes.home]);
                break;
            }
          }
        }
        break;
      default:
        this.router.navigate([AppRoutes.home]);
        break;
    }
  }

  private playLastChapter() {
    const params = {
      language: this.userService.currentUserLanguage
    };

    this.playingService.meControllerGetMe(params).subscribe({
      next: (response) => {
        const videoId = this.checkIdsInCollection(response);
        this.analyticsService.logEvent(GalgoEvent.videoFromContainer, {
          containerId: this.mediaId,
        });
        this.router.navigate([AppRoutes.player, videoId || (this.mediaMetas as Collection)?.subContainers[0]?.videos[0]?._id,]);
      },
      error: (error) => {
        this.logger.error('PlayingService -> getPlayingVideos: Error', error);
      }
    });
  }

  private checkIdsInCollection(userData: UserFrontApi): string | null {
      const foundIds = userData?.playings
        .map(playing => (this.mediaMetas as Collection)?.subContainers
          .map(subContainer => subContainer?.videos)
          .reduce((acc, videos) => acc.concat(videos), [])
          .find(video => video._id === playing?.id)?._id)
        .filter(id => id !== undefined);

      return foundIds?.length > 0 ? foundIds[foundIds.length - 1] : null;
  }

  private generatePlayerDetails(mediaItem: MediaItem<Video>) {
    this.playerDetails = this.playerDetailsService.generatePlayerDetails(
      mediaItem,
      this.trailer,
      videoPlayerEvents,
      this.videoSubtitles,
      this.adsUrl,
      this.drmConfig
    );
    this.logger.info('VideoDetailsComponent => generatePlayerDetails: details', this.playerDetails);

  }

  private manageModalAction(action: ModalActions): void {
    switch(action) {
      case ModalActions.identify:
        this.router.navigate([AppRoutes.login]);
        break;
      case ModalActions.retry:
        this.checkSubscription();
        this.clickEvent(ActionList.play);
        break;
      case ModalActions.close:
      default:
        this.selectChildNodeById(1);
        break;
    }
  }

  private getPlayerEvents() {
    this.galgoPlayerService
      .select((status) => status.ended)
      .pipe(untilDestroyed(this))
      .subscribe(
        (status: PlayerStatus) => {
          if (status.ended) {
            this.trailerStart = false;
            setTimeout(() => {
              this.showTrailer = false;
            }, 600);
          }
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'VideoDetailComponent -> EndedChanges(): Failed',
            error
          );
        }
      );
    this.galgoPlayerService
      .select((status) => status.playing)
      .pipe(untilDestroyed(this))
      .subscribe(
        (status: PlayerStatus) => {
          if (status.playing) {
            this.trailerStart = true;
          }
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'VideoDetailComponent -> PlayingChanges(): Failed',
            error
          );
        }
      );
  }

  private getMediaIdFromUrl(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe({
      next: (params: Params) => {
        this.isHeaderHidden = false;
        this.backgroundColor = '';
        this.tabsService.resetTabsActive();
        this.mediaId = params.id;

        if(this.containerType === 'Video'){
          this.getVideoMetas();
        } else {
          this.getCollectionMetas();
        }

        this.logger.debug(
          'VideoDetailComponent -> GetVideoIdFromUrl(): Success',
          params);
        },
        error: (error: ErrorResponseApi) => {
          this.logger.error(
            'VideoDetailComponent -> GetVideoIdFromUrl(): Failed',
            error
            );
            this.router.navigate([AppRoutes.home]);
          },
    });
  }

  private checkSubscription() {
    if (this.mediaMetas) {
      this.blockVideoResult = this.checkSubscriptionService.blockMedia(
        this.mediaMetas
      );
      if (
        this.blockVideoResult?.reason ===
        SubscriptionResults.noUserAuthenticated
      ) {
        this.showConfirm = true;
      }
    }
  }
}
