import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridModule } from '@shared/components/grid/grid.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { SimilarRoutingModule } from './similar-routing.module';
import { SimilarComponent } from './similar.component';

@NgModule({
  declarations: [
    SimilarComponent
  ],
  imports: [
    CommonModule,
    GridModule,
    SimilarRoutingModule,
    LoadingSpinnerModule
  ],
  exports: [
    SimilarComponent
  ],
})
export class SimilarModule { }
