export class VttParser {
  static parseVttThumbnails(vtt: string): any[] {
    try {
      vtt = vtt.trim();
      vtt = vtt.replace(/\r\n/g, '\n');
      vtt = vtt.replace(/\r/g, '\n');

      const items = vtt.split('\n\n');
      const header = items.shift();

      if (!header.startsWith('WEBVTT')) {
        throw new Error('VTT must start with "WEBVTT"');
      }

      return items.map(item => {
        const itemParts = item.split('\n');
        const times = itemParts[0].split(' --> ');
        let start = 0;
        let end = 0.01;
        if (times.length === 2) {
          start = this.parseTimestamp(times[0]);
          end = this.parseTimestamp(times[1]);
        }
        return {
          start,
          end,
          src: itemParts[1],
        };
      });
    } catch (error) {
      console.error('Error parsing thumbnails vtt', error);
      return [];
    }
  }

  static parseTimestamp(timestamp: string): number {
    const matches = timestamp.match(/([0-9]+)?:?([0-9]{2}):([0-9]{2}\.[0-9]{2,3})/);
    let seconds = parseFloat(matches[1] || '0') * 60 * 60; // hours
    seconds += parseFloat(matches[2]) * 60; // minutes
    seconds += parseFloat(matches[3]); // seconds
    return seconds;
  }
}
