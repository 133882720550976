import { PriceApi } from '@api/models/price-api';
import { PricingPeriodApi } from '@api/models/pricing-period-api';
import { SubscriptionPeriod } from './subscription-period.model';

export class SubscriptionPlanPrice {
  private _active?: boolean;
  private _currency: string;
  private _description?: string;
  private _fakeValue?: number;
  private _id: string;
  private _period: SubscriptionPeriod | PricingPeriodApi;
  private _trialDays?: number;
  private _value: number;

  get id(): string {
    return this._id;
  }
  get description(): string {
    return this._description;
  }
  get isActive(): boolean {
    return this._active;
  }
  get currency(): string {
    return this._currency;
  }
  get hasDiscount(): boolean {
    return !!this._fakeValue;
  }
  get discount(): number | string {
    return this._fakeValue ? this._fakeValue : '';
  }
  get period(): SubscriptionPeriod | PricingPeriodApi {
    return this._period;
  }
  get hasTrialDays(): boolean {
    return !!this._trialDays;
  }
  get trialDays(): number {
    return this._trialDays;
  }
  get price(): number {
    return this._value;
  }

  get isLastPeriod(): boolean {
    if (this.period instanceof SubscriptionPeriod) {
      return this.period.isLast;
    }
    return this.period?.includes(PricingPeriodApi.Yearly);
  }

  constructor(init?: PriceApi, period?: SubscriptionPeriod) {
    if (init) {
      this._id = init._id;
      this._active = init.active;
      this._currency = init.currency || 'EUR';
      this._description = init.description;
      this._fakeValue = this.formatPrice(init.fakeValue);
      this._period = period || init.period;
      this._trialDays = init.trialDays;
      this._value = this.formatPrice(init.value);
    }
  }

  private formatPrice(value: number) {
    if (value) {
      return value / 100;
    }

    return value;
  }
}
