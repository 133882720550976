<feature-toggle-provider [features]="featureToggleData | async">
  <ty-login-return-button *ngIf="showBackButton" [navigableId]="0"></ty-login-return-button>
  <div *ngIf="!showBackButton" class="spacer"></div>
  <ty-login-form (isVirtualKeyboardVisible)="isVirtualKeyboardVisible($event)"
                 
                 [navigableId]="1"></ty-login-form>
  

  <div *featureToggle="'helpUrl'">
    <p class="text-large">{{'login.problems' | translate}}</p>
    <p class="text-large">{{'login.visit' | translate}}</p>
    <h2>{{helpUrlRoute}}</h2>
  </div>
</feature-toggle-provider>