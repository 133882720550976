export enum CategoryTypeEnum {
  tags = 1,
  topics = 2,
  pegi = 3,
  producer = 4,
  genre = 5,
  subgenre = 6,
  country = 101,
  language = 102,
  originalFormat = 105,
  collectionName = 107,
  rightsOwnership = 108,
  pegiContent = 109,
  color = 110,
  sound = 111,
  platformCollection = 112,
  contentType = 113,
};
