import { Component, Input } from '@angular/core';

@Component({
  selector: 'ty-pegi',
  templateUrl: './pegi.component.html',
  styleUrls: ['./pegi.component.scss']
})
export class PegiComponent {

  @Input() pegi: string;

}
