import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { NextChapterComponent } from './next-chapter.component';

const ANGULAR_MODULES = [ CommonModule ];
const EXTERNAL_MODULES = [ TranslateModule, NavigationModule ];

@NgModule({
  imports: [
    ANGULAR_MODULES,
    EXTERNAL_MODULES
  ],
  declarations: [ NextChapterComponent ],
  exports: [ NextChapterComponent, ]
})
export class NextChapterModule { }
