<div *ngIf="!!seasons?.length">
  <div *ngFor="let season of seasons; let seasonIndex = index">
    <div class="season"
         [ngClass]="{'selected-season': seasonIndex === selectedSeason}"
         appNavigation
         [navigableId]="seasonIndex"
         (focus)="setSelectSeason(seasonIndex)">
      <span [ngClass]="{'selected': seasonIndex === selectedSeason}"
            class="title"
            *ngIf="true">{{'details.season' | translate }}</span>
      <span class="season-number"
            *ngIf="true">{{seasonIndex+1}}</span>
      <span [ngClass]="{'selected': seasonIndex === selectedSeason}"
            class="chapters"
            *ngIf="true">{{getNumberOfChapters(seasonIndex)}}
        {{'details.chapters' | translate
        }}</span>
    </div>
  </div>
</div>