import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
@Component({
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: LoginRedirectComponent }]
})
export class LoginRedirectComponent extends NavigationComponent {
  appRoutes: typeof AppRoutes;

  constructor(
    private router: Router,
    public el: ElementRef
  ) {
    super(el);
    this.appRoutes = AppRoutes;
   }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  goTo(route: string): void {
    this.router.navigate([route]);
  }
}
