import { IDog } from '@app/core/models/dog.interface';

import { DrmApi, NextVideoApi, SubtitleApi, VideoUriApi, VideoUriFrontApi } from '@app/galgo-api/models';
import { IDrmConfig } from '@app/galgo-player/models/drm-config.interface';
import { DrmConfig } from '@app/galgo-player/models/drm-config.model';
import { StreamFormat } from './stream-format.enum';


export class VideoUriFront implements VideoUriFrontApi {

  streamFormatPrivate: StreamFormat;

  /**
   * DRM credentials
   */
  drmMetadata?: DrmApi;

  /**
   * video has been watched to the end
   */
  finished?: boolean;

  /**
   * next video to play info
   */
  next?: NextVideoApi;

  startSecond: number;

  /**
   * video subtitles
   */
  subtitles?: Array<SubtitleApi>;

  /**
   * Streaming and vast URIs
   */
  uris?: VideoUriApi;

  /**
   * Returns tha VAST uri to show ads in player.
   *
   * @param videoURL URIs object from backend API
   * @returns A string with the URI
   */
  get adsUrl(): string {
    return this.uris?.vast;
  }

  /**
   * Returns de logo to use as Digital On-screen Graphic (DOG)
   * known as _mosca_ is spanish.
   *
   * First, try the video URI. If it's not present,
   * try tenant default config.
   */
  get dog(): IDog {
    return this.uris?.dog;
  }

  /**
   * Returns the URL for DRM license acquisition.
   */
  get drmConfig(): IDrmConfig {
    return new DrmConfig(this.drmMetadata);
  }

  /**
   * Returns the URL for DRM license acquisition.
   */
  // get drmConfig(): IDrmConfig {
  //   return new DrmConfig(this.drmMetadata);
  // }

  /**
   * Returns the URL with the .vtt used as storyboard.
   */
  get storyboard(): string {
    return this.uris.storyboard;
  }

  /**
   * Returns stream format of the selected URI
   */
  get streamFormat(): StreamFormat {
    return this.streamFormatPrivate;
  }

  constructor(video?: VideoUriFrontApi) {
    this.finished = video?.finished;
    this.next = video?.next;
    this.startSecond = video?.startSecond || 0;
    this.subtitles = video?.subtitles;
    this.uris = video?.uris;
    this.drmMetadata = video?.drmMetadata;
  }

  /**
   * Returns the URI to use based on the URIs returned
   * by backend API. Priority is HLS, Dash and master format.
   *
   * When an URI is returned, sets up readonly property
   * `streamFormat`.
   *
   * @returns A string with the URI
   */
  getVideoUrl(isWebos: boolean): string {
    if ((this.drmConfig?.widevine || isWebos) && this.uris?.dash?.uri ) {

      this.streamFormatPrivate = StreamFormat.dash;
      return this.uris?.dash?.uri;
    }

    if (this.uris?.hls?.uri && !isWebos) {
      this.streamFormatPrivate = StreamFormat.hls;
      return this.uris?.hls?.uri;
    }
    this.streamFormatPrivate = StreamFormat.original;
    return this.uris?.master;
  }
}

