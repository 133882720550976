import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';

@Component({
  selector: 'ty-onboarding-layout',
  templateUrl: './onboarding-layout.component.html',
  styleUrls: ['./onboarding-layout.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: OnboardingLayoutComponent }],
})
export class OnboardingLayoutComponent extends NavigationComponent {
  constructor(
    public el: ElementRef
  ) {
    super(el);
  }

}
