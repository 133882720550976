<div *ngFor="let item of items; let index = index">
  <ng-container *ngIf="isLivesGrid; else regularThumb">
    <ty-landscape-grid-thumb appNavigation
                             class="co-grid-thumb"
                             [navigableId]="index"
                             [item]="item"
                             (click)="navigateToVideoDetails(item)">
    </ty-landscape-grid-thumb>
  </ng-container>
  <ng-template #regularThumb>
    <ty-grid-thumb appNavigation
                   class="co-grid-thumb"
                   [navigableId]="index"
                   [item]="item"
                   (click)="navigateToVideoDetails(item)">
    </ty-grid-thumb>
    <div class="underline-active-item"></div>
  </ng-template>

</div>