import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomSeekbarComponent } from './custom-seekbar.component';

const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [ ...ANGULAR_MODULES ],
  declarations: [ CustomSeekbarComponent ],
  exports: [ CustomSeekbarComponent ]
})
export class CustomSeekbarModule { }
