import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Container } from '@app/core/models/container.model';
import { MediaItem } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { ErrorResponseApi } from '@app/galgo-api/models';
import { CollectionDetailService } from '@app/pages/protected/media-detail/services/collection-detail.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { Collection } from '@app/shared/models/collection/collection.model';
import { Video } from '@app/shared/models/video/video.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { TabContentSelector } from '../tabs/models/tab-label.enum';

@Component({
  selector: 'ty-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ChaptersComponent }],
})
@UntilDestroy()
export class ChaptersComponent extends NavigationComponent implements OnInit, OnDestroy{
  contentId: string;
  collection: Collection;
  currentUrl: string;
  chaptersTab: boolean;
  seasons: Container[];

  private _selectedSeason: number;
  get selectedSeason(): number {
    return this._selectedSeason;
  }

  get chapters(): MediaItem<Video>[] {
    return this.seasons[this.selectedSeason]?.videos;
  }

  constructor(
    public el: ElementRef,
    public logger: NGXLogger,
    private browsingHistoryService: BrowsingHistoryService,
    private collectionDetailService: CollectionDetailService,
    private featureFlagsService: FeatureFlagsService,
    private route: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
  ) {
    super(el);
    this._selectedSeason = 0;
    this.chaptersTab = false;
    this.currentUrl = '';
    this.seasons = [];
    this.settings.lastView$ = TabContentSelector.details;
    this.setDefaultActiveChildNodeId(1);
  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData?.chapters) {
      this.seasons = focusData.chapters.items;
    } else {
      this.getContentId();
    }
  }

  ngOnDestroy(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData) {
      focusData.chapters = {
        items: this.seasons,
      };
      focusData.visitedTab = {
        chaptersTab: true,
        similarTab: false
      };
    }

  }

  onRightKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() > 0) {
      this.selectPreviousChildNode();
      return true;
    }
    return false;
  }

  cleanUrl(): void {
    const lastIndex = this.currentUrl.lastIndexOf('/');
    if (lastIndex >= 0) {
      this.currentUrl = this.currentUrl.substring(0, lastIndex);
    }
  }

  setSelectSeason(seasonIndex: number) {
    this._selectedSeason = seasonIndex;
  }

  private getContentId() {
    this.route.params.subscribe(
      (params: Params) => {
        this.contentId = params.id;
        this.getCollectionDetails();
        this.logger.debug(
          'ChaptersComponent -> GetContentId(): Success',
          params
        );
      },
      (error: ErrorResponseApi) => {
        this.logger.error('ChaptersComponent -> GetContentId(): Failed', error);
        this.router.navigate([AppRoutes.forbidden]);
      }
    );
  }

  private getCollectionDetails() {
    this.collectionDetailService
      .getCollectionDetails(this.contentId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: Collection) => {
          this.collection = response;
          this.seasons = this.collection.getActiveSeasons();
          this.logger.debug(
            'ChaptersComponent -> GetCollectionDetails: Success'
          );
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'ChaptersComponent -> GetCollectionDetails: Failed',
            this.contentId,
            error
          );
        }
      );
  }
}
