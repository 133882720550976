import { Injectable } from '@angular/core';
import { Container } from '@app/core/models/container.model';
import { ContainerService } from '@app/core/services/container.service';
import { ContainerSimpleApi } from '@app/galgo-api/models';
import { MeApiService } from '@app/galgo-api/services';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeeMoreService {

  constructor(
    private containerService: ContainerService,
    private meApiService: MeApiService,
  ) {}

  getContainerById(containerId: string): Observable<Container> {
    const params = {
      id: containerId,
      //1000, to receive all items
      size: 1000
    };
    return this.containerService.getContainerById(params);
  }

  getContainerVideoPlaying(language: string): Observable<ContainerSimpleApi> {
    const params = {
      language
    };
    return this.meApiService.meControllerGetPlayings(params);
  }

}
