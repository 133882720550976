/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment } from '@environments/environment';
import { ContentLanguageInterceptor } from './interceptors/content-language.interceptor';
import { HollowInterceptor } from './interceptors/hollow.interceptor';
import { ImageFormatInterceptor } from './interceptors/image-format.interceptor';
import { MockRequestInterceptor } from './interceptors/mock-request.interceptor';

export function throwIfAlreadyLoaded(
  parentModule: any,
  moduleName: string
): void {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: environment.mock
            ? MockRequestInterceptor
            : HollowInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ContentLanguageInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ImageFormatInterceptor,
          multi: true,
        },
      ]
    };
  }
}
