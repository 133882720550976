import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { LegalDisplayComponent } from './legal-display.component';




@NgModule({
  declarations: [LegalDisplayComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MarkdownModule,
    NavigationModule
  ],
  exports: [ LegalDisplayComponent ]
})
export class LegalDisplayModule { }
