import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LabelsModule } from '@shared/components/labels/labels.module';
import { MetadataModule } from '@shared/components/metadata/metadata.module';
import { DetailComponent } from './detail.component';

const ANGULAR_MODULES = [ CommonModule ];
const SHARED_MODULES = [ LabelsModule, MetadataModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...SHARED_MODULES
  ],
  declarations: [ DetailComponent ],
  exports: [ DetailComponent ]
})
export class DetailModule { }
