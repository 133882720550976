import { PlayerName } from '@app/galgo-player/config/player.enum';
import { ITheoCredentials } from '@app/galgo-player/drivers/theo';
import { ICustomerData } from '@core/models/customer-data.interface';
import { ICustomerFlags } from '@core/models/customer-flags.interface';
import { ICustomerLegalData } from '@core/models/customer-legal-data.interface';

export class AppConfig implements ICustomerData {
  accessKey: string;
  address: string;
  anagramFile: string;
  copyrightMessage: string;
  webBaseUrl: string;
  customerProductName: string;
  defaultLanguage: string;
  email: string;
  enterpriseName: string;
  features: ICustomerFlags;
  launchYear: number;
  legal: ICustomerLegalData;
  logoFile: string;
  phone: string;
  playerCredentials: ITheoCredentials;
  productTopic: string;
  website: string;
  defaultBanner: string;
  defaultPortrait: string;
  defaultLandscape: string;
  playerName: PlayerName;

}
