/* tslint:disable */
/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AuthApiService } from './services/auth-api.service';
import { CatalogsApiService } from './services/catalogs-api.service';
import { CategoryApiService } from './services/category-api.service';
import { CompanyDataApiService } from './services/company-data-api.service';
import { ContactusApiService } from './services/contactus-api.service';
import { ContainerApiService } from './services/container-api.service';
import { CountriesApiService } from './services/countries-api.service';
import { LegalConditionsApiService } from './services/legal-conditions-api.service';
import { MeApiService } from './services/me-api.service';
import { OrderApiService } from './services/order-api.service';
import { PersonApiService } from './services/person-api.service';
import { PreorderApiService } from './services/preorder-api.service';
import { PurchaseApiService } from './services/purchase-api.service';
import { SearchApiService } from './services/search-api.service';
import { SettingsApiService } from './services/settings-api.service';
import { SmartTvCodeLoginApiService } from './services/smart-tv-code-login-api.service';
import { TelemetryApiService } from './services/telemetry-api.service';
import { UploaderApiService } from './services/uploader-api.service';
import { UserCompanyInfoApiService } from './services/user-company-info-api.service';
import { VideoApiService } from './services/video-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ContainerApiService,
    MeApiService,
    LegalConditionsApiService,
    AuthApiService,
    PurchaseApiService,
    CompanyDataApiService,
    TelemetryApiService,
    SearchApiService,
    VideoApiService,
    ContactusApiService,
    SmartTvCodeLoginApiService,
    SettingsApiService,
    PreorderApiService,
    OrderApiService,
    UploaderApiService,
    UserCompanyInfoApiService,
    CategoryApiService,
    PersonApiService,
    CountriesApiService,
    CatalogsApiService,
    ApiConfiguration
  ],
})
export class ApiModule {
  //NO REMOVE needed for catalog selector
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    const defaultConfig = new ApiConfiguration();
    if(!params.config){
      defaultConfig.update(params);
    }
    return {
      ngModule: ApiModule,
      providers: [
        params.config ||
        {
          provide: ApiConfiguration,
          useValue: defaultConfig
        }
      ]
    }
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
