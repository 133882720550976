import { Component, Input } from '@angular/core';
import { IconList } from '@core/models/icon-list.enum';

@Component({
  selector: 'ty-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss']
})
export class LabelsComponent {
  @Input() labels: string[];

  get labelIcon(): string {
    return IconList.labelIcon;
  }

}
