import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Container } from '@app/core/models/container.model';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';


@Component({
  selector: 'ty-seasons-selector',
  templateUrl: './seasons-selector.component.html',
  styleUrls: ['./seasons-selector.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: SeasonsSelectorComponent }]
})
export class SeasonsSelectorComponent extends NavigationComponent implements OnInit, OnDestroy{

  @Input() seasons: Container[];
  @Input() selectedSeason: number;
  @Output() selectSeason?: EventEmitter<number>;
  currentUrl: string;

  constructor(
    public el: ElementRef,
    private browsingHistoryService: BrowsingHistoryService,
    private featureFlagsService: FeatureFlagsService
  ) {
    super(el);
    this.currentUrl = '';
    this.selectSeason = new EventEmitter<number>();
    this.seasons = undefined;
    this.selectedSeason = undefined;
  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData?.season) {
      this.setSelectSeason(focusData.season.firstSelectedChildNodeId);
    }

  }

  ngOnDestroy(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    // If there is focus data for the current URL, it saves the state of the component
    // to the focus data
    if (focusData) {
      focusData.season = {
        firstSelectedChildNodeId: this.selectedSeason,
      };
      this.setSelectSeason(focusData.season.firstSelectedChildNodeId);
    }
  }

  cleanUrl(): void {
    const lastIndex = this.currentUrl.lastIndexOf('/');
    if (lastIndex >= 0) {
      this.currentUrl = this.currentUrl.substring(0, lastIndex);
    }
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onUpKey(): boolean {
    if (this.getSelectedChildNodeId() > 0) {
      this.selectPreviousChildNode();
      return true;
    } else {
      return false;
    }
  }


  getNumberOfChapters(seasonIndex: number): number {
    return this.seasons[seasonIndex]?.videos?.length;
  }

  setSelectSeason(season: number) {
    this.selectSeason.emit(season);
  }
}
