<button id="{{this.buttonsPlayerSelector}}{{action}}"
        class="button-icon"
        appNavigation
        [navigableId]="0"
        (click)="buttonPlayerEvent()"
        (focus)="changeIcon()"
        (blur)="iconOut()">
  <img src="./assets/customer/icons/{{focus ? icon?.filled : icon?.unfilled}}"
       alt="{{iconName}}"
       class="icon" />
</button>