import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingSpinnerModule } from '@app/shared/components/loading-spinner/loading-spinner.module';
import { LegalDisplayModule } from '@shared/components/legal-display/legal-display.module';
import { PrivacyPolicyRoutingModule } from './privacy-policy-routing.module';
import { PrivacyPolicyComponent } from './privacy-policy.component';



@NgModule({
  declarations: [
    PrivacyPolicyComponent
  ],
  imports: [
    CommonModule,
    LegalDisplayModule,
    PrivacyPolicyRoutingModule,
    LoadingSpinnerModule
  ]
})
export class PrivacyPolicyModule { }
