import { Injectable } from '@angular/core';

import { FavoritesFrontApi } from '@api/models';
import { MeApiService } from '@api/services/me-api.service';
import { ItemTypes } from '@app/shared/models/item-types.enum';
import { Container, IContainer, ISimpleContainer } from '@core/models/container.model';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContainerService } from './container.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class MySpaceService {
  favouriteItems: MediaItem<MediaItemTypes>[];


  constructor(
    private containerService: ContainerService,
    private meService: MeApiService,
    private languageService: LanguageService
  ) { }

  getFavorites(): Observable<MediaItem<MediaItemTypes>[]> {
    const favoritesParams = {
      language: this.languageService.currentLang
    };
    return this.meService.meControllerGetFavorites(favoritesParams).pipe(
        map((response: FavoritesFrontApi) => {
          const simpleContainer: ISimpleContainer = {
            subcontainers: response.containers,
            videos: response.videos,
          };
          return this.containerService.getMediaItemsFromContainer(new Container(simpleContainer as IContainer));
        })
      );
  }

  setMySpaceState(favouriteItems: MediaItem<MediaItemTypes>[], id: string): boolean {
    return favouriteItems?.some(
      (favouriteItem: MediaItem<MediaItemTypes>) =>
        favouriteItem?.id === id
    );
  }

  /**
   * Add favorite video by id
   *
   * @param id
   */
   sendFavouriteMediaItem(id: string, mediaType: ItemTypes): Observable<void> {
     return this.meService.meControllerAddFavoriteGeneric({
      elementId: id,
      favoriteType: mediaType.toLowerCase() as 'video' | 'container'
    });
  }

  /**
   * Remove favorite video by id
   *
   * @param id
   */

  removeFavouriteMediaItem(id: string, mediaType: ItemTypes): Observable<void> {
    return this.meService.meControllerRemoveFavoriteGeneric({
      elementId: id,
      favoriteType: mediaType.toLowerCase() as 'video' | 'container'
    });
  }
}
