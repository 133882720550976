import { IEPGConfig } from '@app/core/models/epg-config.interface';

export const epgDefaultConf: IEPGConfig = {
  hoursToDisplay: 24,
  interval: {
    scale: 'hour',
    step: 1,
  },
  limits: {
    start: 7,
    end: 2
  }
};
