import { Injectable } from '@angular/core';
import { IFirebaseConfig } from '@app/analytics/models/firebase-config.interface';
import { IEnvironment } from '@core/models/environment.interface';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {

  get addPlayingInterval(): number {
    return environment?.addPlayingInterval;
  }

  get apiUrl() {
    return environment?.apiUrl;
  }

  get beatInterval() {
    return environment?.beatInterval;
  }

  get categoriesMaxNum() {
    return environment?.categoriesMaxNum;
  }

  get defaultLanguage() {
    return environment?.defaultLanguage;
  }

  get features() {
    return environment?.features;
  }

  get firebase(): IFirebaseConfig {
    return environment?.firebase;
  }

  get mock() {
    return environment?.mock;
  }

  get production() {
    return environment?.production;
  }

  get tokenType() {
    return environment?.tokenType;
  }

  get version() {
    return environment?.version;
  }
}
