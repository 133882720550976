import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { LayoutModule } from '@app/layout/layout.module';
import { CustomRouterModule } from '@app/shared/components/router/custom-router.module';

import { MainLayoutComponent } from './main-layout/main-layout.component';
import { PlayerModule } from './player/player.module';
import { ProtectedPagesRoutingModule } from './protected-pages-routing.module';

@NgModule({
  declarations: [
    MainLayoutComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    ProtectedPagesRoutingModule,
    PlayerModule,
    CustomRouterModule,
    NavigationModule
  ]
})
export class ProtectedPagesModule { }
