import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { RestrictedAccessRoutingModule } from './restricted-access-routing.module';
import { RestrictedAccessComponent } from './restricted-access/restricted-access.component';


@NgModule({
  declarations: [
    RestrictedAccessComponent
  ],
  imports: [
    RestrictedAccessRoutingModule,
    CommonModule,
    RestrictedAccessRoutingModule,
    TranslateModule
  ]
})
export class RestrictedAccessModule { }
