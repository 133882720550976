import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DateYearPipe,
  SafeHtmlPipe,
  TimeDurationPipe
} from '@shared/pipes';


@NgModule({
  declarations: [
    DateYearPipe,
    SafeHtmlPipe,
    TimeDurationPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DateYearPipe,
    SafeHtmlPipe,
    TimeDurationPipe
  ]
})
export class PipesModule { }
