<div id="selector">
  <div #chapterWrapper
       class="selector-wrapper">
    <div *ngFor="let chapter of chapters; let chapterNumber = index;">
      <div class="chapter"
           appNavigation
           [navigableId]="chapterNumber"
           (click)="playChapter(chapter)">
        <div class="thumb">
          <img #chapterImage
               [src]="getThumbnail(chapter)"
               (error)="setDefaultLandscape(chapterNumber)"
               [alt]="chapter?.title">
          <div class="underline-active-item"></div>
        </div>
        <div class="info">
          <span class="number-of-chapter">{{'details.chapter' | translate | uppercase}}
            {{chapterNumber+1}}</span>
          <span class="title">{{chapter?.title}}</span>
          <span class="duration">{{duration(chapter)}}</span>
          <span class="description">{{chapter?.shortDescription}}</span>
        </div>
        <img src="./assets/customer/icons/{{playIcon}}"
             alt="Icono play"
             class="icon" />
      </div>
    </div>
  </div>
</div>