import { Component, Input } from '@angular/core';

@Component({
  selector: 'ty-title-info',
  templateUrl: './title-info.component.html',
  styleUrls: ['./title-info.component.scss']
})
export class TitleInfoComponent {

  @Input() label: string;
  @Input() info: string[] | number;

  get infoNumber(): number {
    return this.info as number;
  }

  get isNumber(): boolean {
    return typeof(this.info) === 'number';
  }

  get infoArray(): string[] {
    return this.info as string[];
  }

}
