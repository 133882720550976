import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CompanyDataApiService } from '@api/services';
import { APP_CONFIG, APP_CONFIG_PATH } from '@app/app.config';

import { SsoApi } from '@app/galgo-api/models/sso-api';
import { PlayerName } from '@app/galgo-player/config/player.enum';
import { ITheoCredentials } from '@app/galgo-player/drivers/theo';
import { AppConfig } from '@app/models/app.model';
import { ICustomerData } from '@core/models/customer-data.interface';
import { ICustomerFlags } from '@core/models/customer-flags.interface';
import { ICustomerLegalData } from '@core/models/customer-legal-data.interface';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, shareReplay } from 'rxjs/operators';
import { ExtendedCompanyDataApi } from '../models/extended-comapny-data.interface';
import { LanguageService } from './language.service';


/**
 * Get customization data for current customer.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomerDataService implements ICustomerData {

  _tenantConfig$: BehaviorSubject<ICustomerData>;
  _tenantAssetsPath: string;
  _cache$: Observable<ExtendedCompanyDataApi>;
  _loaded: boolean;
  CACHE_SIZE: number;
  companyData: ExtendedCompanyDataApi;


  constructor(
    private httpBackend: HttpBackend,
    private logger: NGXLogger,
    private companyDataService: CompanyDataApiService,
    private languageService: LanguageService,
    @Inject(APP_CONFIG) private config: AppConfig,

  ) {
    this.CACHE_SIZE = 1;
    this._tenantAssetsPath = './assets/customer';
    this._tenantConfig$ = new BehaviorSubject<ICustomerData>(this.config);
    this.getCompanyData();
  }

  get tenantAssetsPath(): string {
    return this._tenantAssetsPath;
  }

  get tenantConfig(): ICustomerData {
    return this._tenantConfig$.value;
  }

  get address(): string {
    return this.companyData?.physicalAddress || this.tenantConfig.address;
  }

  get accessKey(): string {
    return this.tenantConfig.accessKey;
  }

  get anagramFile(): string {
    return `${this._tenantAssetsPath}/${this.tenantConfig.anagramFile}`;
  }

  get defaultBanner(): string {
    const banner = `${this._tenantAssetsPath}/${this.tenantConfig.defaultBanner}`;
    return banner ?? '';
  }

  get defaultPortrait(): string {
    const portrait = `${this._tenantAssetsPath}/${this.tenantConfig.defaultPortrait}`;
    return portrait ?? '';
  }

  get defaultLandscape(): string {
    const landscape = `${this._tenantAssetsPath}/${this.tenantConfig.defaultLandscape}`;
    return landscape ?? '';
  }

  get copyrightMessage(): string {
    return this.companyData?.copyright
      || this.tenantConfig.copyrightMessage
      || this.languageService.instant('customer.footer.reserved');
  }

  get webBaseUrl(): string {
    return this.tenantConfig.webBaseUrl;
  }

  get customerProductName(): string {
    return this.tenantConfig.customerProductName;
  }

  get defaultLanguage(): string {
    return this.config.defaultLanguage;
  }

  get email(): string {
    return this.companyData?.contactEmail || this.tenantConfig.email;
  }

  get emailLoginEnabled(): boolean {
    return this.companyData?.emailEnabled;
  }

  get enterpriseName(): string {
    return this.tenantConfig.enterpriseName;
  }

  get features(): ICustomerFlags {
    return this.tenantConfig.features;
  }

  get launchYear(): number {
    return this.tenantConfig.launchYear;
  }

  get legal(): ICustomerLegalData {
    return this.tenantConfig.legal;
  }

  get logoFile(): string {
    return `${this._tenantAssetsPath}/${this.tenantConfig.logoFile}`;
  }

  get phone(): string {
    return this.companyData?.contactTelephoneNumber || this.tenantConfig.phone;
  }

  get playerCredentials(): ITheoCredentials {
    return this.tenantConfig?.playerCredentials;
  }

  get productTopic(): string {
    return this.tenantConfig.productTopic;
  }

  get sso(): SsoApi[] {
    return this.companyData?.sso;
  }

  get website(): string {
    return this.companyData?.urls?.officialWebsite || this.tenantConfig.website;
  }

  get playerName(): PlayerName {
    return this.tenantConfig?.playerName;
  }

  /**
   * Returns customer data needed for client customization.
   *
   * Today is only a local JSON file, but it will be a HTTP call
   * to backend API... some day...today is this day
   *
   * @returns All customer data as JSON object
   */
  getCustomerData(forceReload = false): Observable<ICustomerData> {
    if (forceReload) {
      this.logger.trace('CustomerDataService -> getCustomerData: loading tenant config');
      const client = new HttpClient(this.httpBackend);
      client.get<ICustomerData>(`${APP_CONFIG_PATH}`).pipe(first()).subscribe({
        next: (tenantConfig: ICustomerData) => {
          this._tenantConfig$.next(tenantConfig);
        },
        error: (error: Error) => {
          this.logger.error('CustomerDataService -> getCustomerData: Error loading tenant config', error);
          throw error;
        }
      });
    }
    return this._tenantConfig$;
  }

  getCompanyData(forceReload = false): void {
    if (!this._cache$ || !this._loaded || forceReload) {
      this._cache$ = this.companyDataService.companyDataControllerGetConfiguration().pipe(
        shareReplay({ bufferSize: this.CACHE_SIZE, refCount: true })) as Observable<ExtendedCompanyDataApi>;
      this._loaded = true;
    }
    this._cache$.subscribe({
      next: (companyData: ExtendedCompanyDataApi) => {
        this.companyData = companyData;
        this.logger.debug('CustomerDataService -> getCompanyData: company data loaded', companyData);
      },
      error: (error: HttpErrorResponse) => {
        this.logger.error('CustomerDataService -> getCompanyData: Error loading tenant config', error);
        throw error;
      }
    });
  }

}
