<div id="media-thumb"
     (mouseenter)="mouseEnter()"
     (mouseleave)="mouseLeave()">
     <img #myImage
          class="thumb"
          [src]="thumbnail"
          (error)="setDefaultLandscape()"
          [alt]="title">
     <div class="overlay"
          *ngIf="!!seeMore"></div>
     <div class="info">
          <div class="metadata">
               <ty-tag *ngIf="isLive"
                       [content]="liveTag"
                       [forceOpen]="focused">
               </ty-tag>
               <ty-tag *ngIf="subscription"
                       [content]="suscriptionTag"
                       [forceOpen]="focused">
               </ty-tag>
               <ty-tag *ngIf="ppv"
                       [content]="ppvTag"
                       [forceOpen]="focused"></ty-tag>

          </div>
     </div>
</div>