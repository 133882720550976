import { IModalData } from '@app/shared/models/modal-data.interface';
import { ModalActions } from './modal-actions.enum';

export const GENREIC_ERROR_MODAL_DATA: IModalData = {
  title: 'modal.generic-error.title',
  content: 'modal.generic-error.content',
  icon: 'warning-icon',
  buttons: [
    {
      text: 'modal.generic-error.retry',
      action: ModalActions.retry
    },
    {
      text: 'modal.generic-error.cancel',
      action: ModalActions.close
    }
  ]
};
