/* tslint:disable */
/* eslint-disable */

/**
 * Locations in Spain
 */
export enum AutonomousCommunitySpainApi {
  Andalucia = 'ANDALUC\xCDA',
  Cataluna = 'CATALU\xD1A',
  ComunidadDeMadrid = 'COMUNIDAD DE MADRID',
  ComunidadValenciana = 'COMUNIDAD VALENCIANA',
  Galicia = 'GALICIA',
  CastillaYLeon = 'CASTILLA Y LE\xD3N',
  PaisVasco = 'PA\xCDS VASCO',
  Canarias = 'CANARIAS',
  CastillaLaMancha = 'CASTILLA - LA MANCHA',
  RegionDeMurcia = 'REGI\xD3N DE MURCIA',
  Aragon = 'ARAG\xD3N',
  IslasBaleares = 'ISLAS BALEARES',
  Extremadura = 'EXTREMADURA',
  PrincipadoDeAsturias = 'PRINCIPADO DE ASTURIAS',
  ComunidadForalDeNavarra = 'COMUNIDAD FORAL DE NAVARRA',
  Cantabria = 'CANTABRIA',
  LaRioja = 'LA RIOJA',
  Melilla = 'MELILLA',
  Ceuta = 'CEUTA'
}
