import { Injectable } from '@angular/core';
import { TabContentSelector } from '@shared/components/tabs/models/tab-label.enum';
import { ITab } from '@shared/components/tabs/models/tabs.interface';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  tabSelected: ITab;
  private _tabs: ITab[];
  constructor() {
    this._tabs = [];
  }

  get tabs(): ITab[] {
    return this._tabs;
  }

  setTabsConfig(config: ITab[]) {
    this._tabs = config;
  }

  resetTabsConfig() {
    this._tabs = [];
  }

  selectTab(selectedTabSelector: TabContentSelector): ITab {
    let selectedTab: ITab;
    this._tabs.forEach((tab: ITab) => {
      if (tab.content_selector === selectedTabSelector) {
        tab.active = true;
        selectedTab = tab;
        this.tabSelected = tab;
      } else {
        tab.active = false;
      }
    });
    return selectedTab;
  }

  resetTabsActive() {
    this._tabs.forEach((tab: ITab) => {
      tab.active = false;
    });
  }

  getSelectedTab(): ITab {
    return this.tabSelected;
  }
}
