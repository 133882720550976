import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ErrorResponseApi, TagResponseApi } from '@api/models';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { EnvironmentService } from '@app/core/services/environment.service';
import { CollectionDetailService } from '@app/pages/protected/media-detail/services/collection-detail.service';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { Collection } from '@app/shared/models/collection/collection.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Video } from '@shared/models/video/video.model';
import { NGXLogger } from 'ngx-logger';
import { TabContentSelector } from '../tabs/models/tab-label.enum';
import { RouteItemType } from './config/routes-item-type.enum';

@Component({
  selector: 'ty-more-information',
  templateUrl: './more-information.component.html',
  styleUrls: ['./more-information.component.scss']
})

@UntilDestroy()
export class MoreInformationComponent implements OnInit {

  contentId: string;
  mediaItem: MediaItem<MediaItemTypes>;

  constructor(
    private collectionDetailService: CollectionDetailService,
    private environment: EnvironmentService,
    private route: ActivatedRoute,
    private router: Router,
    private videoDetailService: VideoDetailService,
    public logger: NGXLogger,
    private settings: SettingsService
  ) {
    this.contentId = '';
  }

  ngOnInit(): void {
    this.getContentId();
    this.settings.lastView$ = TabContentSelector.moreInformation;
  }

  get dateLabel(): string {
    return 'details.date';
  }

  get description(): string {
    return this.mediaItem?.description;
  }

  get labels(): string[] {
    const labels: string[] = [];
    this.mediaItem?.layout?.label?.forEach((label: TagResponseApi) => {
      labels.push(label.value);
    });
    return labels;
  }

  get labelsLabel(): string {
    return 'details.labels';
  }

  get pegi(): string {
    return this.mediaItem?.layout?.pegi;
  }

  get pegiLabel(): string {
    return 'details.pegi';
  }

  get producer(): string {
    return this.mediaItem?.analytics?.producerName;
  }

  get producerLabel(): string {
    return 'details.producer';
  }

  get date(): number {
    return this.mediaItem?.date;
  }

  get shortDescription(): string {
    return this.mediaItem?.shortDescription;
  }

  get showLabels(): boolean {
    return !!this.labels?.length;
  }

  get topic(): string {
    return this.mediaItem?.layout?.topic.toString();
  }

  private getContentId() {
    this.route.params.subscribe(
      (params: Params) => {
        this.contentId = params.id;
        // TODO: refactor. Same function in SimilarComponent
        switch(this.router.url.split('/')[2]) {
          case RouteItemType.Series:
            this.getCollectionDetails();
            break;
          case RouteItemType.Collections:
             this.getCollectionDetails();
             break;
          case RouteItemType.Video:
            this.getVideoDetails();
            break;
        }
        this.logger.debug('MoreInformationComponent -> GetContentId(): Success', params);
      },
      (error: ErrorResponseApi) => {
        this.logger.error('MoreInformationComponent -> GetContentId(): Failed', error);
        this.router.navigate([AppRoutes.forbidden]);
      }
    );
  }

  private getCollectionDetails() {
    this.collectionDetailService
    .getCollectionDetails(this.contentId)
    .pipe(untilDestroyed(this))
    .subscribe(
      (response: Collection) => {
        this.logger.debug('MoreInformationComponent -> GetDetails: Success');
        this.mediaItem = response;
      },
      (error: HttpErrorResponse) => {
        this.logger.error(
          'MoreInformationComponent -> GetDetails: Failed',
          this.contentId,
          error
        );
      }
    );
  }

  private getVideoDetails() {
    this.videoDetailService
    .getVideoDetails(this.contentId)
    .pipe(untilDestroyed(this))
    .subscribe(
      (response: Video) => {
        this.logger.debug('MoreInformationComponent -> GetDetails: Success');
        this.mediaItem = response;
      },
      (error: HttpErrorResponse) => {
        this.logger.error(
          'MoreInformationComponent -> GetDetails: Failed',
          this.contentId,
          error
        );
      }
    );
  }

}
