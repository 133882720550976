/* tslint:disable */
/* eslint-disable */

/**
 * Document type
 */
export enum OrderDocTypeApi {
  AccreditationDocuments = 'ACCREDITATION_DOCUMENTS',
  ProofOfPayment = 'PROOF_OF_PAYMENT',
  UserSignedVideoLicense = 'USER_SIGNED_VIDEO_LICENSE',
  CcrSignedVideoLicense = 'CCR_SIGNED_VIDEO_LICENSE',
  UserNotSignedVideoLicense = 'USER_NOT_SIGNED_VIDEO_LICENSE',
  InvoiceClips = 'INVOICE_CLIPS',
  Other = 'OTHER'
}
