import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { ButtonModule } from '@app/shared/components/button/button.module';
import { ModalModule } from '@app/shared/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';



@NgModule({
  declarations: [
    SettingsComponent,
    LanguageSelectorComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SettingsRoutingModule,
    NavigationModule,
    ModalModule,
    ButtonModule
  ]
})
export class SettingsModule { }
