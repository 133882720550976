import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';

@Component({
  selector: 'ty-legal-display',
  templateUrl: './legal-display.component.html',
  styleUrls: ['./legal-display.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: LegalDisplayComponent },
  ],
})
export class LegalDisplayComponent
  extends NavigationComponent
  implements OnInit
{
  @Input() text: string;

  title: string;
  topMarkdown = true;

  constructor(
    private router: Router,
    private browsingHistoryService: BrowsingHistoryService,
    public el: ElementRef
  ) {
    super(el);
  }

  ngOnInit(): void {
    if (this.router.url.includes('legal')) {
      this.title = 'legal.terms';
    } else {
      this.title = 'legal.privacy';
    }
  }

  onEnterKey(): boolean {
    (
      this.getChildNodeByPosition(this.getSelectedChildNodeId()).el
        .nativeElement as HTMLElement
    ).click();
    return true;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onUpKey(): boolean {
    const d = document.getElementById('markdown');
    if (d.scrollTop === 0) {
      this.selectPreviousChildNode();
    }
    return true;
  }

  onLeftKey(): boolean {
    this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }

  close() {
    this.browsingHistoryService.goBack();
  }
}
