/* tslint:disable */
/* eslint-disable */

/**
 * Territories (countries, continent, around world)
 */
export enum TerritoriesApi {
  Country = 'COUNTRY',
  Continent = 'CONTINENT',
  World = 'WORLD'
}
