import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
const routes: Routes = [
  {
    path: '',
    // redirectTo: 'chapters',
    pathMatch: 'prefix',
  },
  {
    path: 'chapters',
    loadChildren: () =>
      import('@shared/components/chapters/chapters.module').then(
        (m) => m.ChaptersModule
      ),
  },
  {
    path: 'similar',
    loadChildren: () =>
      import('@shared/components/similar/similar.module').then(
        (m) => m.SimilarModule
      ),
  },
  {
    path: 'more-information',
    loadChildren: () =>
      import(
        '@shared/components/more-information/more-information.module'
      ).then((m) => m.MoreInformationModule),
  },
  {
    path: 'details',
    loadChildren: () =>
      import(
        '@shared/components/details-tabs/details-tabs.module'
      ).then((m) => m.DetailsTabsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TabsRoutingModule {}
