import { Component, ElementRef } from '@angular/core';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { PlatformService } from '@app/core/navigation/services/platform.service';

import { CustomerDataService } from '@app/core/services/customer-data.service';

@Component({
  selector: 'ty-exit-app',
  templateUrl: './exit-app.component.html',
  styleUrls: ['./exit-app.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ExitAppComponent }]
})
export class ExitAppComponent extends NavigationComponent {
  appRoutes: typeof AppRoutes;
  brandName: string;
  constructor(
    private platformService: PlatformService,
    private customerDataService: CustomerDataService,
    private browsingHistoryService: BrowsingHistoryService,
    public el: ElementRef
  ) {
    super(el);
    this.brandName = this.customerDataService.enterpriseName;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onUpKey(): boolean {
    this.selectPreviousChildNode();
    return true;
  }

  onBackKey(): boolean {
    this.navigateBack();
    return true;
  }

  exitApp(): void {
    this.platformService.exit();
  }

  navigateBack(): void {
    this.browsingHistoryService.goBack();
  }
}
