import { Component, ElementRef, OnInit } from '@angular/core';
import { ErrorResponseApi } from '@api/models';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { PlayerStatus } from '@app/shared/models/player-status.model';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { CustomerDataService } from '@core/services/customer-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';

@UntilDestroy()
@Component({
  selector: 'ty-header-player',
  templateUrl: './header-player.component.html',
  styleUrls: ['./header-player.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: HeaderPlayerComponent }],
})
export class HeaderPlayerComponent extends NavigationComponent implements OnInit {

  title: string;
  showBackButton: boolean;
  isLive: boolean;


  get appLogo(): string {
    return this.customerDataService.anagramFile;
  }

  constructor(
    private customerDataService: CustomerDataService,
    private featureFlagsService: FeatureFlagsService,
    private galgoPlayerService: GalgoPlayerService,
    public logger: NGXLogger,
    public el: ElementRef
  ) {
    super(el);
    this.title = '';
    this.showBackButton = this.featureFlagsService?.currentFeaturesValue?.showBackButton;
    this.isLive = this.galgoPlayerService.status.metas?.live ?? false;
    }

  ngOnInit(): void {
    this.setTitle();
  }

  private setTitle() {
    this.galgoPlayerService.select(status => status.title).pipe(untilDestroyed(this)).subscribe(
      (status: PlayerStatus) => {
        this.title = status?.title;
      },
      (error: ErrorResponseApi) => {
        this.logger.error('PlayerComponent -> OnStateChanges(): Failed', error);
      }
    );
  }

}
