<div id="login-form-content">
  <form [formGroup]="loginForm"
        (click)="resetForm()">
    <h1>{{'login.title' | translate}}</h1>
    <div id="login-form">
      <div class="email">
        <label for="email"
               class="text-large">{{'login.user' | translate}}</label>
        <input #email
               id="email"
               type="text"
               formControlName="email"
               [ngClass]="{'login-input-error': (errorStatus || invalidEmail) }"
               (input)="checkForm()"
               appNavigation
               [navigableId]="0"
               (click)="clickEmail()">
      </div>
      <div class="login-error">
        <div *ngIf="invalidEmail"><i class="gi-sm gi-cross"></i>
          {{'login.invalid-email' | translate}}
        </div>

      </div>
      <div class="password">
        <label for="password"
               class="text-large">{{'login.password' | translate}}</label>
        <input #password
               id="password"
               type="password"
               [type]="hidePassword ? 'password' : 'text'"
               formControlName="password"
               (input)="checkForm()"
               [ngClass]="{'login-input-error': errorStatus}"
               appNavigation
               [navigableId]="1"
               (click)="clickPassword()">
      </div>

      <div class="text-large"
           id="show-pass"
           appNavigation
           [navigableId]="2"
           (click)="hidePassword = !hidePassword">
        <i *ngIf="hidePassword"
           class="gi-lg gi-visibility"></i>
        <i *ngIf="!hidePassword"
           class="gi-lg gi-visibility-off"></i>
        <span *ngIf="hidePassword">{{'login.show-password' | translate}}</span>
        <span *ngIf="!hidePassword">{{'login.hide-password' | translate}}</span>
      </div>

      <div class="text-large invalid-credentials-wrapper">
        <span class="invalid-credentials"
              *ngIf="errorStatus"><i
             class="gi-sm gi-cross"></i>{{'login.invalid-credentials' | translate}}</span>
      </div>

      <div class="primary-button"
           appNavigation
           [navigableId]="3"
           (click)="sendForm()">
        <button id="login-button"
                class="button-large">{{ 'landing.button'
          | translate}}</button>
      </div>
    </div>
  </form>
</div>