import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogService } from '@app/auth/services/catalog/catalog.service';
import { UserService } from '@app/auth/services/user/user.service';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { CatalogResponseApi } from '@app/galgo-api/models';
import { CatalogsApiService } from '@app/galgo-api/services';
import { CacheService } from '@app/shared/services/cache.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'ty-catalog-selector',
  templateUrl: './catalog-selector.component.html',
  styleUrls: ['./catalog-selector.component.scss']
})
export class CatalogSelectorComponent extends NavigationComponent implements OnInit {

  logoUrl: string;
  customerProductName: string;
  catalogs: CatalogResponseApi[];

  constructor(
    public logger: NGXLogger,
    public el: ElementRef,
    private browsingHistoryService: BrowsingHistoryService,
    private catalogApiService: CatalogsApiService,
    private customerDataService: CustomerDataService,
    private catalogService: CatalogService,
    private router: Router,
    private cacheService: CacheService,
    private userService: UserService
  ) {
    super(el);
   }

  ngOnInit(): void {
    this.logoUrl = this.customerDataService.logoFile;
    this.customerProductName = this.customerDataService?.customerProductName;
    this.cacheService.clearCache();
    this.browsingHistoryService.removeAllFocusData();
    this.getCatalogs();
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() > 0) {
      this.selectPreviousChildNode();
      return true;
    }
    return false;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeId() < this.getChildNodes().length - 1) {
      this.selectNextChildNode();
      return true;
    }
    return false;
  }

  onBackKey(): boolean {
    this.router.navigate([AppRoutes.exitApp]);
    return false;
  }

  selectCatalog(catalogId: string): void {
    const selectedCatalog = this.catalogs.find((catalog) => catalog._id === catalogId);
    this.catalogService.setCatalogSelectedId(catalogId);
    this.catalogService.setCatalog(selectedCatalog);
    this.catalogService.setFrontUrlCatalog(selectedCatalog);
    if (this.userService.isLoggedIn()){
      this.userService.setNewUserInfo();
    }
    this.router.navigate([AppRoutes.onboarding]);
  }

  getCatalogs() {
    if(!!this.catalogs){
      return;
    } else{
      this.catalogApiService.catalogControllerGetCatalogs({ showAll: true }).subscribe({
        next: (response: CatalogResponseApi[] ) => {
          this.catalogs = response.filter(catalog => catalog.isPublic !== false);
        },
        error: (error: any) => {
          this.logger.error('CatalogSelectorComponent -> GetCatalogs(): Failed', error);
        },
        complete: () => {},
      });

    }
  }

}


