import { Component, Input } from '@angular/core';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { ImageResolutionApi, ImageSizeApi } from '@app/galgo-api/models';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { IDetailConfig } from '@shared/components/detail/detail-config';
import { homeDetailConfig } from './home-detail.config';

@Component({
  selector: 'ty-home-detail',
  templateUrl: './home-detail.component.html',
  styleUrls: ['./home-detail.component.scss']
})
export class HomeDetailComponent {

  get selectedContent(): MediaItem<MediaItemTypes> {
    return this._selectedContent;
  }

  @Input() set selectedContent(value: MediaItem<MediaItemTypes>) {
      this._selectedContent = value;
  }

  homeDetailConfig: IDetailConfig;

  private _selectedContent: MediaItem<MediaItemTypes>;

  get thumbnail(): string {
    const landscapes = this.selectedContent?.thumbnail?.landscapes;
    if (landscapes && landscapes.length > 0) {
      return landscapes?.find((value: ImageResolutionApi) => value.size === ImageSizeApi.Original)?.url;
    }
    return this.selectedContent?.thumbnail?.landscape || this.customerDataService.defaultLandscape;
  }
  constructor(private customerDataService: CustomerDataService){
    this.homeDetailConfig = homeDetailConfig;
  }
}
