import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { IconList } from '@core/models/icon-list.enum';

@Component({
  selector: 'ty-login-return-button',
  templateUrl: './login-return-button.component.html',
  styleUrls: ['./login-return-button.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: LoginReturnButtonComponent }],
})
export class LoginReturnButtonComponent extends NavigationComponent {

  constructor(
    private router: Router,
    public el: ElementRef,

  ) {
    super(el);
    this.setDefaultActiveChildNodeId(0);

  }

  get returnIconUrl(): string {
    return `./assets/icons/${IconList.returnIcon}#icon`;
  }



  return(): void {
    const currentRoute = this.router.url;
    if (currentRoute === AppRoutes.login) {
      this.router.navigate([AppRoutes.onboarding]);
    } else {
      this.router.navigate([AppRoutes.login]);
    }
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

}
