

import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { IEPGConfig } from '@app/core/models/epg-config.interface';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { SideMenuService } from '@app/core/navigation/services/sidemenu.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { LanguageService } from '@app/core/services/language.service';
import { ImageResolutionApi, ImageSizeApi } from '@app/galgo-api/models';
import { EpgApi } from '@app/galgo-api/models/epg-api';
import { EpgEventApi } from '@app/galgo-api/models/epg-event-api';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DataSet, TimelineOptions, VisTimelineService } from 'ngx-vis';
import { Subject } from 'rxjs';
import { epgDefaultConf } from './conf/epg-default.conf';
import { MenuItem } from './models/menu-item';
import { SelectEvent } from './models/select-event';
import { TimelineEvent } from './models/timeline-event';
import { RangeChangedEvent } from './models/timeline-events';
import { EPGService } from './services/epg.service';

@Component({
  selector: 'ty-galgo-epg',
  templateUrl: './epg.component.html',
  styleUrls: ['./epg.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: EpgComponent }]
})
export class EpgComponent extends NavigationComponent implements OnInit, OnDestroy {
  activeDay: MenuItem;
  daysMenuItems: MenuItem[];
  focusedItemId: string | number;
  lastSelectedItem: string | number;
  timelineItems: DataSet<any> = new DataSet();
  timelineGroups: DataSet<any> = new DataSet();
  timelineName = 'timelineId1';
  timelineOptions: TimelineOptions;
  title: Subject<string> = new Subject<string>();
  backgroundUrl: Subject<string> = new Subject<string>();
  originalItems: EpgApi[] = [];

  private epgConf: IEPGConfig;



  get defaultThumbnail(): string {
    return this.customerDataService.defaultLandscape;
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private customerDataService: CustomerDataService,
    private epgService: EPGService,
    private languageService: LanguageService,
    private renderer: Renderer2,
    private router: Router,
    private sideMenuService: SideMenuService,
    private timelineService: VisTimelineService,
    private translate: TranslateService,
    public el: ElementRef
  ) {
    super(el);
  }

  ngOnInit(): void {
    this.buildEpgConf();
    this.getEpg();
    this.buildDaysMenu();
    this.buildTimelineOptions();
  }

  ngOnDestroy(): void {
    this.timelineService.off(this.timelineName, 'click');
  }

  onUpKey(): boolean {
    this.selectPreviousChildNode();
    return true;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onBackKey(): boolean {
    this.deactivate();
    this.sideMenuService.selectMenuItem('live');
    return true;
  }

  onLeftKey(): boolean {
    this.selectAdjacentTimelineItem(-1); //Select previous item
    this.setSelectedTitleAndBackground();
    return true;
  }

  onRightKey(): boolean {
    this.selectAdjacentTimelineItem(1); //Select next item
    this.setSelectedTitleAndBackground();
    return true;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  timelineInitialized(): void {
    this.timelineService.on(this.timelineName, 'rangechanged');
    this.timelineService.on(this.timelineName, 'currentTimeTick');
    this.timelineService.on(this.timelineName, 'select');
    this.listenTimelineEvents();

    this.buildCurrentTimeLabel();
  }

  /**
   * It changes the window at the timeline
   *
   * @param item MenuItem with the timestamp
   */
  changeDay(item: MenuItem): void {
    if (item.id) {
      let day = new Date(+item.id);
      const now = new Date();
      const isToday = now.getDate() === day.getDate();
      if (isToday) {
        day = now;
      } else {
        day.setHours(12, 0, 0, 0);
      }
      const {start, end} = this.buildWindow(day);
      this.timelineService.setWindow(this.timelineName, start, end, {zoom: false});
    }
  }

  goToProgram() {
      const selectedItem = this.timelineService.getSelection(this.timelineName)[0];
      if (selectedItem) {
        this.navigateToChannel(selectedItem);
      }
  }

  private navigateToChannel(itemId: string | number): void {
    const channel = this.originalItems.find(item => item.events.some(event => event.id === itemId));
      if (channel) {
        this.router.navigate(['pages', 'player', channel.videoId]);
      }
  }

  private buildDaysMenu() {
    const menuItems: MenuItem[] = [];
    //Total menu days, including, past, today and future
    const totalDays = this.epgConf.limits.start + this.epgConf.limits.end + 1;
    const firstDay = new Date();
    firstDay.setDate(firstDay.getDate() - this.epgConf.limits.start);
    moment.locale(this.languageService.currentLang);
    for (let i = 0; i < totalDays; i++) {
      const isToday = new Date().getDate() === firstDay.getDate();
      const label: string = isToday ? this.translate.instant('common.today').toUpperCase() : moment(firstDay).format('ddd D');
      menuItems.push({
        label: label.charAt(0).toUpperCase() + label.slice(1),
        id: firstDay.getTime().toString(),

      });
      if (isToday) {
        this.activeDay = menuItems[menuItems.length - 1];
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    this.daysMenuItems = menuItems;
  }

  private setSelectedTitleAndBackground() {
    const selectedItem = this.timelineService.getSelection(this.timelineName)[0];
    this.setTitleAndBackground(selectedItem);
  }

  private selectAdjacentTimelineItem(direction: 1 | -1): void {
    const selected = this.timelineService.getSelection(this.timelineName)[0];
    if (selected) {
      const items = this.timelineItems.get();
      const foundIndex = items.findIndex(item => item.id === selected);
      if (items[foundIndex + direction]) {
        this.timelineService.setSelectionToId(this.timelineName, items[foundIndex + direction].id);
        this.timelineService.focusOnId(this.timelineName, items[foundIndex + direction].id, {animation: true, zoom: false});
      }
    }
  }

  private updateCurrentTimeLabel(): void {
    const now = new Date();
    const timeLabels = document.querySelectorAll('.vis-current-time__label');
    timeLabels.forEach(label => {
      label.innerHTML = moment(now).format('HH:mm');
    });
  }

  private buildCurrentTimeLabel(): void {
    const now = new Date();
    const el: HTMLSpanElement = this.renderer.createElement('span');
    el.innerHTML = moment(now).format('HH:mm');
    el.classList.add('vis-current-time__label');
    const elCopy = el.cloneNode(true);
    const currentTimeBar = document.querySelector('.vis-current-time');
    this.renderer.appendChild(currentTimeBar, el);
    this.renderer.appendChild(currentTimeBar, elCopy);
  }

  private buildEpgConf(): void {
    const { epg } = this.customerDataService;
    this.epgConf = {...epgDefaultConf, ...epg};
  }

  private getEpg(): void {
    this.epgService.getEPG().subscribe({
      next: epg => {
        this.originalItems = epg;
        epg.forEach((channel, index) => {
          this.timelineGroups.add({
            id: channel._id,
            content:  channel.logo.landscapes[0].url ? `<img src=${channel.logo.landscapes[0].url} alt="${channel.name}" />` : channel.name,
          });
          this.addChannelEvents(channel);
          if(index === 0) {
            const item = channel.events.find(event => new Date(event.startDate) <= new Date() && new Date(event.endDate) > new Date());
            if (item) {
              this.timelineService.setSelectionToId(this.timelineName, item.id);
              this.setSelectedTitleAndBackground();
            }
          }
        });
      }
    });
  }

  private listenTimelineEvents() {
    this.timelineService.rangechanged.subscribe({
      next: ([, ev]: [string, RangeChangedEvent]) => {
        this.activeDay = this.daysMenuItems.find(item => {
          const day = new Date(+item.id);
          return day.getDate() === ev.start.getDate();
        });
      }
    });

    this.timelineService.currentTimeTick.subscribe({
      next: () => {
        this.updateCurrentTimeLabel();
      }
    });

    this.timelineService.select.subscribe({
      next: ([, ev]: [string, SelectEvent]) => {
        if (ev.items[0] === this.lastSelectedItem) {
          this.navigateToChannel(ev.items[0]);
          return;
        }
        this.lastSelectedItem = ev.items[0];
        this.setTitleAndBackground(ev.items[0]);
      }
    });
  }

  private setTitleAndBackground(itemId: string | number): void {
    const events = this.originalItems.reduce((prev, next) => prev.concat(next.events), []); //Flatten events to an array
    const foundItem: EpgEventApi = events.find(event => event.id === itemId);
    if(foundItem) {
      this.title.next(foundItem.title);
      this.backgroundUrl.next(this.getThumbnail(foundItem.thumbnail?.landscapes));
      this.cdRef.detectChanges();
    }
  }

  private addChannelEvents(channel: EpgApi) {
    const formattedEvents: TimelineEvent[] = channel.events.map(event => {
      const formattedStartDate = new Date(event.startDate);
      const formattedEndDate = new Date(event.endDate);
      return {
        group: channel._id,
        id: event.id,
        itemTitle: event.title,
        start: formattedStartDate,
        end: formattedEndDate,
        description: event.subtitle
      };
    });
    this.timelineItems.add(formattedEvents);
  }

  private buildTimelineOptions() {
    const now = new Date();
    const {start, end} = this.buildWindow(now);
    const startLimit = new Date(now);
    startLimit.setHours(0, 0, 0, 0);
    startLimit.setDate(startLimit.getDate() - this.epgConf.limits.start);
    const endLimit = new Date();
    endLimit.setHours(0, 0, 0, 0);
    endLimit.setDate(endLimit.getDate() + this.epgConf.limits.end + 1);

    this.timelineOptions = {
      autoResize: true,
      end,
      groupHeightMode: 'fixed',
      min: startLimit,
      max: endLimit,
      minHeight: '180px',
      orientation: {
        axis: 'top',
        item: 'top'
      },
      selectable: true,
      showCurrentTime: true,
      showMajorLabels: false,
      stack: false,
      start,
      template: this.buildItemTemplate,
      timeAxis: {
        scale: this.epgConf.interval.scale,
        step: this.epgConf.interval.step
      },
      tooltip: {
        delay: 150,
        followMouse: true,
        overflowMethod: 'cap',
      },
      type: 'range',
      zoomable: false,
      zoomMin: 20000000
    };
  }

  private buildWindow(center: Date): {start: Date; end: Date} {
    const start = new Date(center);
    start.setHours(center.getHours() - (this.epgConf.hoursToDisplay / 2));
    const end = new Date(center);
    end.setHours(center.getHours() + (this.epgConf.hoursToDisplay / 2));
    return {start, end};
  }

  private buildItemTemplate(item, element, data): string {
    const formattedStart = moment(item.start).format('HH:mm');
    const formattedEnd = moment(item.end).format('HH:mm');
    const isCurrent = item.start <= new Date() && item.end > new Date();
    data.className = isCurrent ? 'vis-current-event' : '';
    return `
      <span class="item-property item-property__title">${item.itemTitle}</span>
      <span class="item-property item-property__date">${formattedStart} - ${formattedEnd}</span>
    `;
  }

  private getThumbnail(images: Array<ImageResolutionApi>): string {
    const preferenceOrder = [ImageSizeApi.High, ImageSizeApi.Original];

    if (images?.length > 0) {
      const found = preferenceOrder.map(preference => images.find(value => value.size === preference)).find(url => url);
      return found?.url ?? this.defaultThumbnail;
    }
    return this.defaultThumbnail;
  }
}
