import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { ButtonPlayerComponent } from './button-player.component';

const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [ ...ANGULAR_MODULES, NavigationModule ],
  declarations: [ ButtonPlayerComponent ],
  exports: [ ButtonPlayerComponent ]
})
export class ButtonPlayerModule { }
