import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '@app/auth/services/user/user.service';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { FeatureFlagsService } from '@core/services/feature-flags.service';


@Injectable({ providedIn: 'root' })
export class LoginRedirectGuard implements CanActivate {
  constructor(
    private featureFlagsService: FeatureFlagsService,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.userService.isLoggedIn()) {
      return true;
    } else {
      const exploreModeFlag = this.featureFlagsService.currentFeaturesValue;
      if (exploreModeFlag?.exploreMode) {
        this.router.navigate([AppRoutes.loginRedirect]);
        return true;
      } else {
        this.router.navigate([AppRoutes.login], {
          queryParams: {
            returnUrl: state.url,
          },
        });
        return false;
      }
    }
  }
}
