import { Injectable, Injector } from '@angular/core';
import { Analytics, logEvent as analyticsLogEvent, getAnalytics, setUserId } from '@angular/fire/analytics';
import { analyticsEventsConfig } from '@app/analytics/config/analytics.config';
import { IAnalyticApiParams, IAnalyticParams } from '@app/analytics/models/analytics.interface';
import { GalgoEvent, IGalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { CatalogService } from '@app/auth/services/catalog/catalog.service';
import { UserService } from '@app/auth/services/user/user.service';
import { PlatformService } from '@app/core/navigation/services/platform.service';
import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { NGXLogger } from 'ngx-logger';
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _analyticEvents: IGalgoEvent[];
  private _userService: UserService;
  private _featuredFlagService: FeatureFlagsService;
  private _userId: string;
  private _platform: string;
  private _appVersion: string;
  private _catalogId: string;

  set appVersion(value: string) {
    this._appVersion = value;
  }

  constructor(
    private analytics: Analytics,
    private injector: Injector,
    private logger: NGXLogger,
    private platformService: PlatformService,
    private catalogService: CatalogService
  ) {
    this._analyticEvents = analyticsEventsConfig;
    this._platform = this.platformService?.platform;
    this._userId = '';
  }

  /**
   * Log an event by sending it into Firebase, it sorts the different kind of events by event type
   *
   * @param event {@link GalgoEvent}
   * @param [args] {@link AnalyticParams}
   * @memberof AnalyticsService
   */
  logEvent(event: GalgoEvent, args?: IAnalyticParams): void {
    this._userService = this.injector.get<UserService>(UserService);
    this._featuredFlagService =
    this.injector.get<FeatureFlagsService>(FeatureFlagsService);
    this._userId = this.getUserId(args);
    if (this._featuredFlagService.currentFeaturesValue.haveCatalogs && this.catalogService.getCatalogSelectedId()) {
      this._catalogId = this.catalogService.getCatalogSelectedId();
    }

    const params = this.createParams(args);
    this.sendAnalyticsLogEvent(this.analytics, event as string, params);
  }

  /**
   * Set user id to Firebase events
   *
   * @param userId {@link string}
   * @memberof AnalyticsService
   */
  setUserId(userId?: string): void {
    const analytics = getAnalytics();

    if (userId) {
      setUserId(analytics, userId);
    } else {
      setUserId(analytics, '');
    }
  }

  /**
   * Checks if the tenant is public. If it is the user id will be 'guest'
   */
  private getUserId(args: any): string {
    return this._featuredFlagService.currentFeaturesValue?.exploreMode &&
      !this._userService.isLoggedIn()
      ? 'guest'
      : this._userService?.currentUser?.id || args?.userId;
  }

  /**
   * Create param args to send to API
   */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private createParams(args: any): IAnalyticApiParams {

    const params: IAnalyticApiParams = {
      app_info_app_version_smarttv: this._appVersion,
      catalog_id: this._catalogId,
      container_id: args?.containerId,
      fromSecond: args?.fromSecond,
      original_video_id: args?.original_video_id,
      price: args?.price,
      product_id: args?.productId,
      text: args?.textToSearch,
      user_id: this._userId,
      video_id: args?.video?.id,
      title: args?.video?.title,
      live: args?.video?.live,
      tags: args?.video?.categories,
      chapter: args?.chapter,
      season: args?.season,
      show: args?.show,
    };
    return this.removeNull(params);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private removeNull<T>(obj: T | any) {
    this.logger.trace('AnalyticsService => removeNull: object before', obj);
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeNull(obj[key]);
      } else if (obj[key] == null) {
        /* use == to check null & undefined */
        delete obj[key];
      }
    });
    this.logger.trace('AnalyticsService => removeNull: object after', obj);
    return obj;
  }

  private sendAnalyticsLogEvent(analytics: Analytics, event: string, params: IAnalyticApiParams): void {
    try{
      analyticsLogEvent(analytics, event, params);
    } catch (error) {
      this.logger.error(`Error sending analytics event ${event} with params ${JSON.stringify(params)}`, error);
    }
  }
}
