import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDuration',
})
export class TimeDurationPipe implements PipeTransform {
  times = {
    h: 3600,
    min: 60,
    seg: 1,
  };

  transform(seconds) {
    let formattedTimeString = '';
    let letter = '';
    for (const key in this.times) {
      if (Math.floor(seconds / this.times[key]) > 0) {
        if (key === 'seg') {
          if (formattedTimeString !== '') {
            // If have a hour or minutes for the moment not display the seconds
            letter = '';
          } else {
            letter = '< 1min';
            formattedTimeString += letter + ' ';
          }
        } else {
          letter = '';
          formattedTimeString +=
            Math.floor(seconds / this.times[key]).toString() +
            key.toString() +
            letter +
            ' ';
        }
        seconds = seconds - this.times[key] * Math.floor(seconds / this.times[key]);
      }
    }
    return formattedTimeString;
  }
}
