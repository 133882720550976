import { Injectable } from '@angular/core';
import { ITimerState, TimerEventType } from '@app/galgo-player/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  NEVER,
  Observable,
  interval,
  timer,
} from 'rxjs';
import { map, scan, startWith, switchMap, tap, } from 'rxjs/operators';


@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class PlayerEventTimerService {
  /**
   * Timer to start a countdown that will return the state on every interval.
   *
   * @property pause - Stops or resume the countdown
   * @property timeInterval - Time (in miliseconds) between value emissions
   * @property limit - Ttime limit after the countdown resets. If there is no limit, the countdown won't be reset
   * @property value - Countdown value
   * @property intervalDelay - If the countdown is a timer, it's the delay between timeInverval (0 for instant)
   * @property increase - Number that increases value in every emission
   *
   * @function scan Reduces the state to accumulate the last one with the new one
   * @function switchMap Returns new Observable (timer or interval) if the pause isn't active, if not, it doesn't emit anything.
   * @function Tap It resets the value if a limit exists if not, it increases the value
   * @function Map Map the value emited to a {@link ITimerState} Object
   * @function Scan reduces the state to accumulate the last one with the new one
   * @param type {@link TimerEventType}
   * @param events$ {@link Observable<ITimerState>}
   * @param initialState {@link ITimerState}
   * @memberof PlayerEventTimerService
   */
  setTimer(
    type: TimerEventType,
    events$: Observable<ITimerState>,
    initialState: ITimerState
  ): Observable<ITimerState> {
    return events$.pipe(
      startWith({
        pause: initialState.pause,
        timeInterval: initialState.timeInterval,
        limit: initialState.limit,
        value: initialState.value,
        intervalDelay: initialState.intervalDelay,
        increase: initialState.increase,
      }),
      scan((state: ITimerState, current): ITimerState => ({ ...state, ...current }), {} as ITimerState ),
      switchMap((state: ITimerState) => {
        if (state.pause) {
          return NEVER;
        }
        return (
          type === TimerEventType.interval
            ? interval(state.timeInterval)
            : timer(state.intervalDelay, state.timeInterval)
        ).pipe(
          tap(() => {
            if (state?.limit && state.value >= state.limit) {
              state.value = 0;
              return;
            }
            state.value += state.increase;
          }),
          map(() => state)
        );
      }),
      map((state) => state),
      untilDestroyed(this)
    );
  }
}
