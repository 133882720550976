import { Injectable } from '@angular/core';
import { ContainerApiService } from '@api/services';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { ContainerService } from '@app/core/services/container.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { ContainerApi } from '@app/galgo-api/models';
import { CacheService } from '@app/shared/services/cache.service';
import { Container, IContainer } from '@core/models/container.model';
import { ContainerByIdParams } from '@pages/protected/home/services/container-by-id-params.interface';
import { Collection } from '@shared/models/collection/collection.model';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CollectionDetailService {

  constructor(
    private containerApiService: ContainerApiService,
    private containerService: ContainerService,
    private featureFlagsService: FeatureFlagsService,
    private cacheService: CacheService
  ) { }

  getCollectionDetails(containerId: string): Observable<Collection> {
    const cacheKey = this.cacheService.generateCacheKey(`getDetailSheet:${containerId}`);
    return this.cacheService.getFromCache<Collection>(cacheKey).pipe(
      switchMap((cachedCollection: Collection | null) => {
        if (cachedCollection) {
          return of(cachedCollection);
        } else {
          const collectionParams: ContainerByIdParams = {
            id: containerId
          };
          return this.containerApiService.containerControllerFindById(collectionParams).pipe(
            map((response: IContainer) => {
              const collection = new Collection(response);
              this.cacheService.addToCache(cacheKey, collection);
              return collection;
            })
          );
        }
      })
    );
  }

  getSimilarContent(videoId: string): Observable<MediaItem<MediaItemTypes>[]> {
    const params = {
      id: videoId
    };
    return this.containerApiService.containerControllerFindRelatedContainer(params)
      .pipe(
        map((response: ContainerApi) => {
          const relatedContainer: IContainer = response as IContainer;

          if (!this.featureFlagsService.currentFeaturesValue.enableCollections) {
            relatedContainer.subcontainers = this.filterCollections(relatedContainer.subcontainers);
          }
          return this.containerService.getMediaItemsFromContainer(new Container(relatedContainer));
        }
        )
      );
  }

  private filterCollections(container: IContainer[]): IContainer[] {
    container.forEach((slider: IContainer) => {
      slider.subcontainers = [];
    });
    return container;
  }
}
