import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogSelectorComponent } from './components/catalog-selector/catalog-selector/catalog-selector.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginFormPageComponent } from './components/login-form-page/login-form-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { OnboardingLayoutComponent } from './components/onboarding-layout/onboarding-layout.component';
import { SignInPageComponent } from './components/sign-in-page/sign-in-page.component';
import { SkipCatalogGuard } from './guards/skip-catalog.guard';
import { SkipLandingGuard } from './guards/skip-landing.guard';


const routes: Routes = [
  {
    path: '',
    component: OnboardingLayoutComponent,
    children: [
      {
          path: '',
          redirectTo: 'catalog-selector',
          pathMatch: 'full',
      },
      {
          path: 'catalog-selector',
          canActivate: [SkipCatalogGuard],
          component: CatalogSelectorComponent

      },
      {
        path: 'onboarding',
        canActivate: [SkipLandingGuard],
        component: LandingPageComponent
      },
      {
        path: 'login',
        component: LoginPageComponent
      },
      {
        path: 'form',
        component: LoginFormPageComponent
      },
      {
        path: 'sign-in-page',
        component: SignInPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
