/* tslint:disable */
/* eslint-disable */

/**
 * Social platform that user uses to sign up
 */
export enum SocialMediaTypeApi {
  Instagram = 'instagram',
  Youtube = 'youtube',
  Twitter = 'twitter',
  Twitch = 'twitch',
  Google = 'google',
  Facebook = 'facebook',
  Tiktok = 'tiktok',
  Linkedin = 'linkedin',
  Apple = 'apple',
  Imdb = 'imdb',
  Keycloak = 'keycloak',
  Other = 'other'
}
