import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { ignoreURLConfig } from '@app/core/navigation/config/app-navigation-url.config';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { Arrays } from '@app/shared/utils/arrays';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { NGXLogger } from 'ngx-logger';
import { VirtualScrollItem } from '../virtual-scroll/virtual-scroll-item.interface';
import { VirtualScrollComponent } from '../virtual-scroll/virtual-scroll.component';

@Component({
  selector: 'ty-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: GridComponent }]
})

export class GridComponent extends NavigationComponent implements OnInit, OnDestroy{
  @ViewChild('gridElement') gridElement!: ElementRef;
  @ViewChild('virtualScroll') virtualScrollComponent!: VirtualScrollComponent;


  @Input() galgoEvent: GalgoEvent;
  @Input() setInitFocus: boolean;
  @Input() videoId: string;
  @Input() firstSelectedChildNodeId: number;
  @Input() rowItems: number;

  @Input() set items(value: MediaItem<MediaItemTypes>[]) {
    this._items = value;
  }

  get items(): MediaItem<MediaItemTypes>[] {
    //filtro directos
    if(this.featureFlagsService.currentFeaturesValue.filterLives){
      this._items = this._items.filter((element) => element.analytics.live !== true);
    }
    return this._items;
  }

  @Output() scrollEnd: EventEmitter<void>;

  virtualScrollItems: VirtualScrollItem[];
  isScrolledEnd: boolean;
  scrollTop: number;
  rows: any[][] = [];
  firstLoad: boolean;
  currentUrl: string;
  firstSelectedChildNodeIdRow: number;



  private _items: MediaItem<MediaItemTypes>[];

  constructor(
    private router: Router,
    public el: ElementRef,
    private changeDetector: ChangeDetectorRef,
    public logger: NGXLogger,
    private featureFlagsService: FeatureFlagsService,
    private browsingHistoryService: BrowsingHistoryService,

  ) {
    super(el);
    this.scrollEnd = new EventEmitter<void>();
    this._items = [];
    this.firstSelectedChildNodeId = 0;
    this.rows = [];
    this.rowItems = 6;
    this.isScrolledEnd = false;
    this.scrollTop = 0;
  }
  /**
   * This method is called when the component is initialized.
   * It sets up the initial state of the component.
   */
  ngOnInit() {
    this.firstLoad = true;
    this.currentUrl = this.browsingHistoryService.currentURL;

    // Checks if the current URL is in the list of ignored URLs
    const isIgnoredUrl = ignoreURLConfig.some(config => {
      if (!config.matchExact) {
        return this.currentUrl.endsWith(config.url);
      }
      return false;
    });

    // If the current URL is ignored, it removes the last part of the URL
    if (isIgnoredUrl) {
      const lastSlashIndex = this.currentUrl.lastIndexOf('/');
      if (lastSlashIndex !== -1) {
          this.currentUrl = this.currentUrl.substring(0, lastSlashIndex);
      }
    }

    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    // If there is focus data for the current URL, it sets the state of the component
    // from the focus data
    if (focusData?.grid) {
      this.virtualScrollItems = focusData.grid.virtualScrollItems;
      this.firstSelectedChildNodeId = focusData.grid.firstSelectedChildNodeId;
      this.firstSelectedChildNodeIdRow = focusData.grid.firstSelectedChildNodeIdRow;
      this.selectChildNodeById(this.firstSelectedChildNodeId);
      this.changeDetector.detectChanges();
    } else {
      this.processItems();
    }
  }

  /**
   * This method is called when the component is destroyed.
   * It saves the state of the component to the browsing history service.
   */
  ngOnDestroy(): void {
    // Gets the focus data for the current URL from the browsing history service
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    // If there is focus data for the current URL, it saves the state of the component
    // to the focus data
    if (focusData) {
      focusData.grid = {
        virtualScrollItems: this.virtualScrollItems,
        firstSelectedChildNodeId: this.getSelectedChildNodeId(),
        firstSelectedChildNodeIdRow: (
          this.getSelectedChildNode() as NavigationComponent
        )?.getSelectedChildNodeId(),
      };
    }
  }

  onFirstChildNodes(): void {
    (this.getSelectedChildNode() as NavigationComponent)?.selectChildNodeById(
      this.firstSelectedChildNodeIdRow
    );
    this.updateScroll();
  }

  processItems(): void {
    this.rows = Arrays.chunk(this.items, this.rowItems);
    this.virtualScrollItems =
      this.rows?.map((item: any, index: number) => ({
        id: index,
        width: 0,
        height: 26.8,
        data: item,
        lastActiveIndex: -1,
      })) || [];
    this.changeDetector.detectChanges();
  }

  onChangeSelectedChildNode(): void {
    this.updateScroll();
  }

  onUpKey(): boolean {
    if (this.getSelectedChildNodeId() > 0) {
      const actualId = this.getSelectedChildComponent()?.getSelectedChildNodeId();
      if (this.selectPreviousChildNode()) {
       this.getSelectedChildComponent().selectChildNodeById(actualId);
       this.virtualScrollComponent?.scrollToPreviousElement();
       this.changeDetector.detectChanges();
      }
      return true;
    }
    return false;
  }

  onDownKey(): boolean {
    if (this.getSelectedChildNodeId() < this.virtualScrollItems.length - 1) {
      const actualId = this.getSelectedChildComponent()?.getSelectedChildNodeId();
      if (this.selectNextChildNode()) {
        this.getSelectedChildComponent().selectChildNodeById(actualId);
        this.virtualScrollComponent?.scrollToNextElement();
        this.changeDetector.detectChanges();
      }
      this.checkScrollEnd();
      return true;
    }
    return false;
  }

  checkScrollEnd(): void {
    if (
      !this.isScrolledEnd &&
      this.getSelectedChildNodeId() === this.virtualScrollItems.length - 3
    ) {
      this.isScrolledEnd = true;
      this.scrollEnd.next();
    }
  }

  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeIndex());
    if (focused) {
      const scrollTop = focused.getOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.gridElement.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

  trackByFn(index: number, item: any): number {
    return item.id;
  }
}


