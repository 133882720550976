/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompanyInfoApi } from '../models/company-info-api';
import { PaginatedResultApi } from '../models/paginated-result-api';
import { UserCompanyInfoApi } from '../models/user-company-info-api';
import { UserCompanyInfoFilterApi } from '../models/user-company-info-filter-api';
import { VideoFrontApi } from '../models/video-front-api';

@Injectable({
  providedIn: 'root',
})
export class UserCompanyInfoApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userCompanyInfoControllerGetCompanyVideos
   */
  static readonly UserCompanyInfoControllerGetCompanyVideosPath = '/user-company-info/{id}/catalogue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCompanyInfoControllerGetCompanyVideos()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCompanyInfoControllerGetCompanyVideos$Response(params: {

    /**
     * The company ID
     */
    id: string;
    language?: string;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;
  }): Observable<StrictHttpResponse<PaginatedResultApi & {
'data'?: Array<VideoFrontApi>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserCompanyInfoApiService.UserCompanyInfoControllerGetCompanyVideosPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('language', params.language, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedResultApi & {
        'data'?: Array<VideoFrontApi>;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCompanyInfoControllerGetCompanyVideos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCompanyInfoControllerGetCompanyVideos(params: {

    /**
     * The company ID
     */
    id: string;
    language?: string;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;
  }): Observable<PaginatedResultApi & {
'data'?: Array<VideoFrontApi>;
}> {

    return this.userCompanyInfoControllerGetCompanyVideos$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedResultApi & {
'data'?: Array<VideoFrontApi>;
}>) => r.body as PaginatedResultApi & {
'data'?: Array<VideoFrontApi>;
})
    );
  }

  /**
   * Path part for operation userCompanyInfoControllerFindById
   */
  static readonly UserCompanyInfoControllerFindByIdPath = '/user-company-info/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCompanyInfoControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCompanyInfoControllerFindById$Response(params: {

    /**
     * The company ID
     */
    id: string;
  }): Observable<StrictHttpResponse<UserCompanyInfoApi>> {

    const rb = new RequestBuilder(this.rootUrl, UserCompanyInfoApiService.UserCompanyInfoControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserCompanyInfoApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCompanyInfoControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCompanyInfoControllerFindById(params: {

    /**
     * The company ID
     */
    id: string;
  }): Observable<UserCompanyInfoApi> {

    return this.userCompanyInfoControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<UserCompanyInfoApi>) => r.body as UserCompanyInfoApi)
    );
  }

  /**
   * Path part for operation userCompanyInfoControllerUpdate
   */
  static readonly UserCompanyInfoControllerUpdatePath = '/user-company-info/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCompanyInfoControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCompanyInfoControllerUpdate$Response(params: {

    /**
     * The company ID
     */
    id: string;
    body: UserCompanyInfoApi
  }): Observable<StrictHttpResponse<UserCompanyInfoApi>> {

    const rb = new RequestBuilder(this.rootUrl, UserCompanyInfoApiService.UserCompanyInfoControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserCompanyInfoApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCompanyInfoControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCompanyInfoControllerUpdate(params: {

    /**
     * The company ID
     */
    id: string;
    body: UserCompanyInfoApi
  }): Observable<UserCompanyInfoApi> {

    return this.userCompanyInfoControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<UserCompanyInfoApi>) => r.body as UserCompanyInfoApi)
    );
  }

  /**
   * Path part for operation userCompanyInfoControllerFindFilteredCompanies
   */
  static readonly UserCompanyInfoControllerFindFilteredCompaniesPath = '/user-company-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCompanyInfoControllerFindFilteredCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCompanyInfoControllerFindFilteredCompanies$Response(params?: {
    page?: number;
    limit?: number;

    /**
     * The company activity
     */
    activity?: Array<string>;

    /**
     * The company country
     */
    country?: string;

    /**
     * The company content
     */
    content?: 'content_viewer' | 'content_owner';

    /**
     * The company name
     */
    companyName?: string;
  }): Observable<StrictHttpResponse<PaginatedResultApi & {
'data'?: Array<CompanyInfoApi>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserCompanyInfoApiService.UserCompanyInfoControllerFindFilteredCompaniesPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('activity', params.activity, {});
      rb.query('country', params.country, {});
      rb.query('content', params.content, {});
      rb.query('companyName', params.companyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedResultApi & {
        'data'?: Array<CompanyInfoApi>;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCompanyInfoControllerFindFilteredCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCompanyInfoControllerFindFilteredCompanies(params?: {
    page?: number;
    limit?: number;

    /**
     * The company activity
     */
    activity?: Array<string>;

    /**
     * The company country
     */
    country?: string;

    /**
     * The company content
     */
    content?: 'content_viewer' | 'content_owner';

    /**
     * The company name
     */
    companyName?: string;
  }): Observable<PaginatedResultApi & {
'data'?: Array<CompanyInfoApi>;
}> {

    return this.userCompanyInfoControllerFindFilteredCompanies$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedResultApi & {
'data'?: Array<CompanyInfoApi>;
}>) => r.body as PaginatedResultApi & {
'data'?: Array<CompanyInfoApi>;
})
    );
  }

  /**
   * Path part for operation userCompanyInfoFiltersControllerGetFilters
   */
  static readonly UserCompanyInfoFiltersControllerGetFiltersPath = '/user-company-info-filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCompanyInfoFiltersControllerGetFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCompanyInfoFiltersControllerGetFilters$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<Array<UserCompanyInfoFilterApi>>> {

    const rb = new RequestBuilder(this.rootUrl, UserCompanyInfoApiService.UserCompanyInfoFiltersControllerGetFiltersPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserCompanyInfoFilterApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCompanyInfoFiltersControllerGetFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCompanyInfoFiltersControllerGetFilters(params?: {
    language?: string;
  }): Observable<Array<UserCompanyInfoFilterApi>> {

    return this.userCompanyInfoFiltersControllerGetFilters$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserCompanyInfoFilterApi>>) => r.body as Array<UserCompanyInfoFilterApi>)
    );
  }

}
