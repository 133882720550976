import { Component } from '@angular/core';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { LanguageService } from '@app/core/services/language.service';
import { Driver, IDriverCreateParams, ITrack, PlayerDetailType } from '@app/galgo-player/models';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';

declare let shaka: any;


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-shaka-driver',
  template: '',
})


export class ShakaDriverComponent extends Driver {
  activeLanguage: string;
  video: HTMLVideoElement | HTMLAudioElement;

  constructor(
    private logger: NGXLogger,
    private customerDataService: CustomerDataService,
    private languageService: LanguageService,
    private galgoPlayerService: GalgoPlayerService) {
    super();
  }

  get audioTracks() {
    const audioLanguages = this.player.getAudioLanguages();
    const languageIdMap = {};
    audioLanguages.forEach((language, index) => {
      languageIdMap[language] = index;
    });

    const mappedAudioTracks = audioLanguages.map(language => ({
      enabled: language === this.activeLanguage,
      id: languageIdMap[language],
      kind: 'audio',
      label: language,
      language,
      isAudio: true
    }));

    return mappedAudioTracks;
  }

  get paused(): boolean{
    return this.video.paused;
   }

  get currentTime(): number{
    return this.video.currentTime;
  }

  set currentTime(time: number){
    this.video.currentTime = time ;
  }

  setCurrentTime(seconds: number) {
    this.player.currentTime(seconds);
  }

  getAvailableSubtitles(): TextTrack[] {
    return Array.from(this.player.getTextTracks());
  }

  create(params: IDriverCreateParams): void {
    this.video = params.videoElement;
    shaka.polyfill.installAll();

    this.video.controls = false;  // Disable controls
    this.player = new shaka.Player(params.videoElement);
    //if want custom config use this.player.configure(SHAKA_OPTIONS);
    this.player.configure({
      preferredAudioLanguage: this.languageService.currentLang,
      preferredTextLanguage: this.languageService.currentLang
    });
    this.activeLanguage = this.languageService.currentLang;

    // Inicializar la UI de Shaka Player
    //const ui = new shaka.ui.Overlay(this.player, document.getElementById('video-container'), this.video);
    //const controls = ui.getControls();

    this.captureEvents();
  }

  updateSource(params: PlayerDetailType):  void {
    const protection = {
      drm: {
        servers: {
          'com.widevine.alpha': 'https://drm-widevine-licensing.axprod.net/AcquireLicense',
        },
      },
    };

    this.player.configure(protection);
    this.player.getNetworkingEngine().registerRequestFilter((type, request) => {
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        request.headers['X-AxDRM-Message'] = params.drm?.token;
      }
    });


    this.player.load(params.src).then(() => {
      params.subtitlesData.forEach(async (element: ITrack) => {
        const track = await this.player.addTextTrackAsync(
          element.url, // URI
          element.language, // Lang code
          'captions', // Kind of track (subtitles, captions, etc.)
          'text/vtt', // MIME type subtitles file
          null, // Codec (optional, could be null)
          element.label[0].value, // Label (opcional)
          false // Forzed (opcional)
        );
      });

    }).catch(error => {
      console.error('Código de error', error.code, 'objeto', error);
    });

    this.galgoPlayerService.setAspectRatio();
  }

  destroy(): void {
    this.logger.debug('ShakaDriverComponent => destroy');
    if (this.player) {
      this.player.destroy();
    }
  }

  captureEvents(): void {
    this.logger.trace('ShakaDriverComponent => captureEvents');
    this.video.addEventListener('playing', () => {
      this.logger.trace('ShakaDriverComponent => captureEvents: onPlaying');
      this.onPlaying();
      this.galgoPlayerService.updateStatus((status) => (status.playing = true));
    });
    this.video.addEventListener('pause', () => {
      this.logger.trace('ShakaDriverComponent => captureEvents: onPause');
      this.onPause();
      this.galgoPlayerService.updateStatus((status) => (status.playing = false));
    });
    this.video.addEventListener('ended', () => {
      this.onEnded();
      this.galgoPlayerService.updateStatus((status) => (status.ended = true));
    });

    this.video.addEventListener('error', (event) => {
      console.error('Error en la reproducción:', event);
    });
  }

  play(): void{
    this.video.play();
  }

  pause(){
    this.video.pause();
  }

  forward(seconds?: number): void {
    this.video.currentTime = this.video.currentTime + seconds;
  }

  rewind(seconds?: number): void {
    this.video.currentTime = this.video.currentTime - seconds;
  }

  getCurrentTime(): number {
    return this.video.currentTime;
  }

  getDuration(): number {

    return this.video.duration;
  }

  getCaptionsVisibility(): boolean {
    return this.player.isTextTrackVisible();
  }

  enableAudioTrack(id: number): void {
    const audioLanguages = this.player.getAudioLanguages();
    const selectedLanguage = audioLanguages[id];
    this.player.selectAudioLanguage(selectedLanguage);
    this.activeLanguage = selectedLanguage;
  }

  setSelectedSubtitle(id: number): void {
    const tracks = this.player.getTextTracks();
    this.player.selectTextTrack(tracks[id - 1]);
    this.video.textTracks[0].mode = 'showing';
  }

  unselectSubtitles(): void {
    const textTracks = this.video.textTracks;
    textTracks[0].mode = 'hidden';
  }

}
