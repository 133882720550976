import { SubscriptionPeriodApi } from '@api/models/subscription-period-api';


export class SubscriptionPeriod {
  description: string;
  period: string;
  title: string;
  private _position: number;
  private _isLast: boolean;

  get position(): number {
    return this._position;
  }

  get isLast(): boolean {
    return this._isLast;
  }

  constructor(init?: SubscriptionPeriodApi, position: number = 0, totalPositions?: number) {
    this.description = init?.description as unknown as string;
    this.period = init?.period;
    this.title = init?.title as unknown as string;
    this._position = position;
    this._isLast = position === totalPositions;
  }
}
