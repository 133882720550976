<div id="detail">
  <div class="detail-text">
    <div class="detail-topic-container" *ngIf="topics">
      <div class="detail-topic-wrap">
            <div class="detail-topic" *ngFor="let topic of topics; let last = last">
              {{ topic }} <span *ngIf="!last">,&nbsp;</span>
            </div>
        </div>
    </div>
    <h1 *ngIf="title">{{title}}</h1>
    <ty-metadata class="metadata-component"
                 [duration]="duration"
                 [date]="date"
                 [live]="live"
                 [pegi]="pegi"
                 [tags]="tags"
                 [subscription]="subscription"
                 [ppv]="ppv"
                 [productionEndDate]="productionEndDate"
                 [productionStartDate]="productionStartDate"
                 [media]="media"></ty-metadata>
    <div class="detail-shortdescription"
         *ngIf="showDescription">{{descriptionSelector}}</div>
  </div>
</div>