import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionResults } from '@app/auth/models/subscription-results.enum';
import { CheckSubscriptionService } from '@app/auth/services/subscription/check-subscription.service';
import { IconList } from '@app/core/models/icon-list.enum';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { ImageSizeApi } from '@app/galgo-api/models';
import { GENREIC_ERROR_MODAL_DATA, ModalActions, REGISTER_MODAL_DATA, SUBSCRIPTION_MODAL_DATA } from '@app/shared/components/modal/config';
import { ModalService } from '@app/shared/components/modal/services/modal.service';
import { Video } from '@app/shared/models/video/video.model';
import { MediaItem } from '@core/models/media-item.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ty-chapters-selector',
  templateUrl: './chapters-selector.component.html',
  styleUrls: ['./chapters-selector.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: ChaptersSelectorComponent },
  ],
})
export class ChaptersSelectorComponent extends NavigationComponent implements OnInit, OnDestroy {
  @ViewChild('chapterWrapper') chapterWrapper: ElementRef<HTMLDivElement>;
  @ViewChildren('chapterImage') chapterImages: QueryList<ElementRef>;


  @Input() seasonNumber: number;
  @Input() chapters: MediaItem<Video>[];

  currentUrl: string;
  scrollTop: number;

  get playIcon(): string {
    return IconList.playerIcon;
  }

  get defaultLandscape(): string {
    return this.customerDataService.defaultLandscape;
  }

  constructor(
    public el: ElementRef,
    private browsingHistoryService: BrowsingHistoryService,
    private checkSubscriptionService: CheckSubscriptionService,
    private customerDataService: CustomerDataService,
    private featureFlagsService: FeatureFlagsService,
    private modalService: ModalService,
    private router: Router,
    private translate: TranslateService,
  ) {
    super(el);
    this.currentUrl = '';
    this.seasonNumber = 0;
  }

  getThumbnail(chapter: MediaItem<Video>): string {
    const landscapes = chapter?.thumbnail?.landscapes;
    const landscape = chapter?.thumbnail?.landscape;
    const preferenceOrder = [ImageSizeApi.Medium, ImageSizeApi.High, ImageSizeApi.Original];

    if (landscapes?.length > 0) {
      const found = preferenceOrder.map(preference => landscapes.find(value => value.size === preference)).find(url => url);
      return found?.url ?? this.defaultLandscape;
    }
    return landscape ?? this.defaultLandscape;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    this.updateScroll();
    return true;
  }

  onUpKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
      return false;
    }
    this.selectPreviousChildNode();
    this.updateScroll();
    return true;
  }

  //TODO update chapters when chapters variable change instead of go to node 0
  onLeftKey(): boolean {
    this.selectChildNodeById(0);
    this.updateScroll();
    return false;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData?.chapter) {
      this.selectChildNodeById(focusData.chapter.firstSelectedChildNodeId);
      this.seasonNumber = focusData.chapter.firstSelectedChildNodeId;
    }

  }

  ngOnDestroy(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    // If there is focus data for the current URL, it saves the state of the component
    // to the focus data
    if (focusData) {
      focusData.chapter = {
        firstSelectedChildNodeId: this.getSelectedChildNodeId(),
      };
    }
  }

  onFirstChildNodes(): void {
    this.updateScroll();
  }

  cleanUrl(): void {
    const lastIndex = this.currentUrl.lastIndexOf('/');
    if (lastIndex >= 0) {
      this.currentUrl = this.currentUrl.substring(0, lastIndex);
    }
  }

  duration(c) {
    if (c?.duration < 60) {
      return '<1 min';
    } else {
      const minutes = Math.floor(c?.duration / 60);
      const hours = Math.floor(minutes / 60);
      const left_minutes = minutes - hours * 60;
      let duration = '';
      if (hours > 0) {
        duration += hours + this.translate.instant('details.hours') + ' ';
      }
      if (left_minutes > 0) {
        duration += left_minutes + ' ' + this.translate.instant('details.minutes');
      }
      return duration;
    }
  }

  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.chapterWrapper.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }


  playChapter(chapter: MediaItem<Video>) {
    this.checkSubscription(chapter);
  }

  setDefaultLandscape(index: number) {
    this.chapterImages.toArray()[index].nativeElement.src = this.customerDataService.defaultLandscape;
  }

  private checkSubscription(chapter: MediaItem<Video>) {
    const blockVideoResult = this.checkSubscriptionService.blockMedia(chapter);
    if (blockVideoResult.result) {
      switch(blockVideoResult.reason) {
        case SubscriptionResults.noUserAuthenticated:
          this.modalService.open(
            REGISTER_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction, chapter)
            );
          break;
        case SubscriptionResults.noUserSubscriptions:
        case SubscriptionResults.noUserSubscriptionsAndPpv:
        case SubscriptionResults.subscriptionError:
          this.modalService.open(
            SUBSCRIPTION_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction, chapter)
            );
          break;
        default:
          this.modalService.open(
            GENREIC_ERROR_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction, chapter)
            );
          break;
      }
    } else {
      this.router.navigate([AppRoutes.player, chapter?.id]);
    }
  }

  private manageModalAction(action: ModalActions, chapter: MediaItem<Video>): void {
    switch(action) {
      case ModalActions.identify:
        this.router.navigate([AppRoutes.login]);
        break;
      case ModalActions.retry:
        this.playChapter(chapter);
        break;
      case ModalActions.close:
      default:
        this.selectChildNodeById(1);
        break;
    }
  }
}
