import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { MediaThumbModule } from '../media-thumb/media-thumb.module';
import { VirtualScrollComponent } from './virtual-scroll.component';

const ANGULAR_MODULES = [ CommonModule ];
const SHARED_MODULES = [ MediaThumbModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...SHARED_MODULES,
    NavigationModule,
  ],
  declarations: [ VirtualScrollComponent ],
  exports: [ VirtualScrollComponent ]
})
export class VirtualScrollModule { }
