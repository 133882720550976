import { Component, Input} from '@angular/core';

@Component({
  selector: 'ty-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {
  @Input() subscriptionName: string;

}
