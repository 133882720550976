<div class="catalog-selector-wrapper">
  <div class="catalog-selector">
    <div class="catalog-selector-tenant-logo">
      <img class="catalog-selector-tenant-logo-image"
           [src]="logoUrl"
           alt="{{customerProductName}}">
    </div>
    <div class="catalog-title">
      <h2 class="catalog-title-text">{{'landing.catalog-selector.title' | translate}}</h2>
    </div>
    <div class="catalog-list">
      <div class="catalog-list-item"
           *ngFor="let catalog of catalogs; let i = index">
        <ty-catalog-item appNavigation
                         [catalog]="catalog"
                         (click)="selectCatalog(catalog?._id)"
                         [navigableId]="i">
        </ty-catalog-item>
      </div>
    </div>
  </div>
</div>