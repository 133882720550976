/* tslint:disable */
/* eslint-disable */

/**
 * Right Windows
 */
export enum RightWindowsTypeApi {
  Theatrical = 'THEATRICAL',
  Vod = 'VOD',
  Airlines = 'AIRLINES',
  Tv = 'TV'
}
