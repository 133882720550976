import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { PlayerName } from '@app/galgo-player/config';
import { PlayerDrivers } from '@app/galgo-player/models';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { languageMap } from './config/language-map';

@Component({
  selector: 'ty-list-selector',
  templateUrl: './list-selector.component.html',
  styleUrls: ['./list-selector.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ListSelectorComponent }],
})
export class ListSelectorComponent extends NavigationComponent implements OnInit {
  @Output() subtitledChanged = new EventEmitter<number>();
  @Input() itemTracks: any[];
  driver: PlayerDrivers;
  playerName: PlayerName;
  selectedItem: string | null = 'none';
  subtitleIndex: boolean;

  constructor(
    public el: ElementRef,
    private customerDataService: CustomerDataService,
    private galgoPlayerService: GalgoPlayerService
  ) {
    super(el);
    this.driver = this.galgoPlayerService.getDriver();
    this.playerName = this.customerDataService.playerName;
   }

  ngOnInit(): void {
    if (this.itemTracks?.length > 0) {
      //Only need check this.driver.getCaptionsVisibility() in shakaplayer
      const captionsVisibility = this.playerName === 'shaka' ? this.driver.getCaptionsVisibility() : true;

      if (captionsVisibility || this.isAudioTrack()) {
        //item.enabled for theo itemtracks
        //item.mode for videojs itemtracks
        //item.active for shaka itemtracks
        const itemIndex = this.itemTracks.findIndex(item => item.enabled || item.mode === 'showing' || item.active);

        if (itemIndex !== -1) {
          this.selectedItem = this.itemTracks[itemIndex].language;
          this.setDefaultActiveChildNodeId(itemIndex + 1);
        }
      }
    }
  }

  isAudioTrack(): boolean {
    return this.itemTracks.some(track => track.kind === 'audio');
  }

  parseLabel(label: string): any {
    return languageMap[label] || label;
  }

  changeSubtitle(subtitleId: number, subtitle?: string) {
    this.selectedItem = subtitle;
    this.subtitledChanged.emit(subtitleId);
  }

  onUpKey(): boolean {
    this.selectPreviousChildNode();
    return true;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onLeftKey(): boolean {
    return true;
  }

  onRightKey(): boolean {
    return true;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }
}
