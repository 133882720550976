
import { DrmApi, DrmFairplayApi } from '@app/galgo-api/models';
import { IDrmConfig } from './drm-config.interface';


/**
 * DRM configuration for DRM license acquisition usually
 * needed inside player configuration.
 */
export class DrmConfig implements IDrmConfig {
  authToken?: string;
  drmHeader?: string;
  fairplay?: DrmFairplayApi;
  playready?: string;
  token?: string;
  widevine?: {
    licenseAcquisitionURL: string;
    headers?: { [key: string]: string };
    useCredentials?: boolean;
  };

  /**
   * DrmConfig only can be instantiated from a DrmApi object.
   *
   * @param fromApi A DrmApi valid object
   */
  constructor(fromApi: DrmApi) {
    if (!fromApi) {
      return undefined;
    }
    this.token = fromApi.authToken;
    const headers = {};
    headers[fromApi.drmHeader ?? 'nv-authorizations'] = this.token;
    this.widevine = {
      headers,
      licenseAcquisitionURL: fromApi.widevine,
      useCredentials: false
    };
    this.drmHeader = fromApi.drmHeader;
    this.fairplay = fromApi.fairplay;
    this.playready = fromApi.playready;
  }
}
