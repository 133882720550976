<ng-container>
  <section class="open">
    <div class="modal-container">
      <div class="modal-container-inner">
        <div class="title condicions">
          {{ ppvPrice }}
        </div>
        <div class="title">{{ 'modal.no-ppv' | translate }}</div>
        <div class="qr-wrapper">
          <div class="qr">
            <ty-qr-code [qrUrl]="videoUrl"></ty-qr-code>
          </div>
          <div class="text-large qr-text">
            <div>{{ 'login.qr-description' | translate }}</div>
            <div>{{ 'modal.o' | translate }}</div>
            <div>{{ 'modal.web-access' | translate }}</div>
            <h2 class="code-url">{{domainUrl}}</h2>
          </div>
        </div>
        <div class="primary-button button-wrapper"
             (click)="hidePpvModal()"
             appNavigation
             [navigableId]="0">
          <button class="button-medium">{{'modal.subscription.close' | translate}}</button>
        </div>
      </div>
    </div>
  </section>
</ng-container>