import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';




@NgModule({

  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
})
export class AnalyticsModule { }
