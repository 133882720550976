import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache = new Map<string, any>();
  private CACHE_TTL = 300 * 1000;

  constructor(
    public logger: NGXLogger
  ) {}
  getFromCache<T>(cacheKey: string): Observable<T | null> {
    const cachedResult = this.cache.get(cacheKey);
    return cachedResult ? of(cachedResult) : of(null);
  }

  addToCache<T>(cacheKey: string, value: T): void {
     // If the cacheKey contains 'getDetailSheet', remove any existing entry with 'getDetailSheet'
     if (cacheKey.includes('getDetailSheet')) {
      this.clearCacheOfVideoDetails();
    }

    this.cache.set(cacheKey, value);
    this.setupCacheExpiration(cacheKey);
  }

  removeFromCache(cacheKey: string): void {
    this.cache.delete(cacheKey);
  }

  clearCache(): void {
    this.cache.clear();
  }

  generateCacheKey(baseKey: string): string {
    const url = window.location.href;
    const urlParts = url.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    return `${baseKey}:${lastPart}`;
  }

  private setupCacheExpiration(cacheKey: string): void {
    // If the cacheKey contains 'getDetailSheet', do not set up expiration
    if (cacheKey.includes('getDetailSheet')) {
      return;
    }

    const expirationSubject = new Subject<void>();

    of(null).pipe(delay(this.CACHE_TTL), takeUntil(expirationSubject))
      .subscribe({
        next:() => {
        this.cache.delete(cacheKey);
        expirationSubject.complete();
      },
      error: (error) => {
        this.logger.error(
          'CacheService -> setupCacheExpiration(cacheKey: string): Failed',
          error
        );
      }
    });
  }

  private clearCacheOfVideoDetails(): void {
    // Get all keys of the cache
    const keys = Array.from(this.cache.keys());

    // Filter keys that contain 'getDetailSheet'
    const detailSheetKeys = keys.filter(key => key.includes('getDetailSheet'));

    // Delete all entries with keys that contain 'getDetailSheet'
    detailSheetKeys.forEach(key => this.cache.delete(key));
  }

}
