import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { IPlayerDetailsEvents } from '@app/galgo-player/models';

export const videoConfig = {
  fadeOutTitleDuration: 3000,
};

export const videoPlayerEvents: IPlayerDetailsEvents = {
  startEvent: GalgoEvent.startVideo,
  halfEvent: GalgoEvent.halfVideo,
  finishEvent: GalgoEvent.finishVideo,
  startTrailerEvent: GalgoEvent.startTrailer,
  halfTrailerEvent: GalgoEvent.halfTrailer,
  finishTrailerEvent: GalgoEvent.finishTrailer,
  sendBeats: true,
};

