<div id="metadata">
  <ty-pegi class="pegi-component"
           *ngIf="pegi"
           [pegi]="pegi"></ty-pegi>
  <div *ngIf="productionDate; else showDate">{{productionDate}}</div>
  <ng-template #showDate>
    <div *ngIf="date">{{date | dateYear}}</div>
  </ng-template>
  <div class="detail-duration spacing"
       *ngIf="duration">{{duration | timeDuration}}</div>
  <div *ngIf="media?.hasAudio === false"><span class="no-audio">{{'details.no-audio' | translate}}
    </span></div>
  <div *ngIf="live">
    <ty-tag [content]="liveTag"></ty-tag>
  </div>
  <div *ngIf="subscription">
    <ty-tag [content]="suscriptionTag"></ty-tag>
  </div>
  <div *ngIf="ppv">
    <ty-tag [content]="ppvTag"></ty-tag>
  </div>
</div>