<div id="search">
  <div class="results-wrapper">
    <h4>{{title | translate}}</h4>
    <div class="search-wrapper">
      <ty-keyboard [navigableId]="0"></ty-keyboard>
    </div>
  </div>
  <div class="grid-wrapper">
    <h4>{{searchTitle | translate}}</h4>
    <div class="grid-overflow-hidden">
      <div class="black-gradient-overlay"></div>
      <ty-grid #search
               class="grid"
               *ngIf="mediaItems?.length"
               [items]="mediaItems"
               [navigable]="navigableGrid"
               [setInitFocus]="true"
               [galgoEvent]="galgoEvent"
               [navigableId]="1"
               [rowItems]="4"
               (updateScroll)="updateScroll()">
      </ty-grid>
      <div class="no-result"
           *ngIf="resultsNotFound">
        <span>{{'search.no-results' | translate}}</span>
      </div>
    </div>
  </div>
</div>