import { LanguageApi, TermApi } from '@api/models';

export class Language {
  private _id: string;
  private _description?: string;
  private _disabled?: boolean;
  private _icon?: string;
  private _terms?: TermApi[];

  get id(): string {
    return this._id;
  }

  get description(): string {
    return this._description;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get icon(): string {
    return this._icon;
  }

  get terms(): TermApi[] {
    return this._terms;
  }

  constructor(init?: LanguageApi, id?: string) {
    if (init || id) {
      this._id = init?._id || id;
      this._description = init?.description;
      this._disabled = init?.disabled;
      this._icon = init?.icon;
      this._terms = init?.terms;
    }
  }

  serialize(): string {
    const language: LanguageApi = {
      _id: this._id,
      description: this._description,
      disabled: this._disabled,
      icon: this._icon,
      terms: this._terms,
    };
    return JSON.stringify(language);
  }
}
