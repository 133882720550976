import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { LoadingSpinnerModule } from '@app/shared/components/loading-spinner/loading-spinner.module';
import { CustomRouterModule } from '@app/shared/components/router/custom-router.module';
import { LazyTranslationModule } from '@app/shared/translations/lazy-translation.module';
import { QrCodeModuleCustom } from '@shared/components/qr-code/qr-code-custom.module';
import { SvgIconItemModule } from '@shared/components/svg-icon-item/svg-icon-item.module';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { AuthRoutingModule } from './auth-routing.module';
import { CatalogItemComponent } from './components/catalog-item/catalog-item.component';
import { CatalogSelectorComponent } from './components/catalog-selector/catalog-selector/catalog-selector.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';

import { LoginFormPageComponent } from './components/login-form-page/login-form-page.component';
import { LoginFormComponent } from './components/login-form-page/login-form/login-form.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LoginReturnButtonComponent } from './components/login-return-button/login-return-button.component';
import { OnboardingLayoutComponent } from './components/onboarding-layout/onboarding-layout.component';
import { SignInPageComponent } from './components/sign-in-page/sign-in-page.component';
import { ErrorInterceptor, JwtInterceptor } from './helpers';
@NgModule({
  declarations: [
    OnboardingLayoutComponent,
    LandingPageComponent,
    LoginFormComponent,
    LoginFormPageComponent,
    LoginPageComponent,
    LoginReturnButtonComponent,
    SignInPageComponent,
    CatalogSelectorComponent,
    CatalogItemComponent
  ],
  imports: [
    AuthRoutingModule,
    CommonModule,
    FeatureToggleModule,
    HttpClientModule,
    LazyTranslationModule,
    QrCodeModuleCustom,
    ReactiveFormsModule,
    SvgIconItemModule,
    CustomRouterModule,
    NavigationModule,
    LoadingSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AuthModule { }
