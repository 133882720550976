import { Component, Input, OnInit } from '@angular/core';
import { IQRCode } from './models/qr-code.interface';
import { QrSize } from './models/qr-size.enum';
import { ScreenHeight } from './models/screen-height.enum';

import { QRCodeConfig } from './qr-code.config';

@Component({
  selector: 'ty-qr-code',
  templateUrl: './qr-code.component.html'
})
export class QrCodeComponent implements OnInit {

  @Input() qrUrl: string;
  get qrCodeConfig(): IQRCode {
    return QRCodeConfig;
  }

  ngOnInit(): void {
    this.setQRWidth();
  }

  private setQRWidth() {
    switch (screen.height) {
      case ScreenHeight.HD:
        this.qrCodeConfig.width = QrSize.HD;
        break;
      case ScreenHeight.fullHD:
        this.qrCodeConfig.width = QrSize.fullHD;
        break;
      case ScreenHeight.fourK:
        this.qrCodeConfig.width = QrSize.fourK;
        break;
    }
  }

}
