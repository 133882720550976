import { Injectable } from '@angular/core';
import { TvCodeApi } from '@api/models';
import { SmartTvCodeLoginApiService } from '@api/services';
import { CodeParams } from '@app/auth/models/code-params.model';
import { IAuthToken } from '@app/auth/models/token.model';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CodeService {

  private _token: IAuthToken;
  get token(): IAuthToken {
    return this._token || this.getTokenFromLocalStorage();
  }
  set token(value: IAuthToken) {
    this._token = value;
    this.setTokenInLocalStorage(this._token);
  }

  constructor(
    private smartTvCodeLoginService: SmartTvCodeLoginApiService) { }

  postSmartTVCode(): Observable<TvCodeApi> {
    return this.smartTvCodeLoginService.userCodeTvControllerCreateCode();
  }

  loginSmartTVCode(smartTVcode: string): Observable<IAuthToken> {
    const params: CodeParams = {
      code: smartTVcode
    };
    return this.smartTvCodeLoginService.userCodeTvControllerLogin(params).pipe(
      map((token: IAuthToken) => {
        this.token = token;
        return token;
      })
    );
  }

  private setTokenInLocalStorage(token: IAuthToken) {
    localStorage.setItem('accessToken', JSON.stringify(token));
  }

  private getTokenFromLocalStorage(): IAuthToken {
    const token = localStorage.getItem('accessToken');
    return token
      ? (JSON.parse(localStorage.getItem('accessToken')) as IAuthToken)
      : undefined;
  }
}
