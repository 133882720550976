import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { MoreInformationRoutingModule } from './more-information-routing.module';
import { MoreInformationComponent } from './more-information.component';
import { TitleInfoComponent } from './title-info/title-info.component';

@NgModule({
  declarations: [
    TitleInfoComponent,
    MoreInformationComponent
  ],
  imports: [
    CommonModule,
    MoreInformationRoutingModule,
    PipesModule,
    TranslateModule,
  ],
  exports: [MoreInformationComponent]
})
export class MoreInformationModule { }
