import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG } from '@app/app.config';
import { AppModule } from './app/app.module';
import { default as appConfig } from './assets/customer/tenant.conf.json';
import { environment } from './environments/environment';

//Error to lauch app in LG TV
/*fetch('assets/customer/tenant.conf.json')
  .then((response) => response.json())
  .then((config) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));

  });*/
  if (environment.production) {
    enableProdMode();
  }
  platformBrowserDynamic([{ provide: APP_CONFIG, useValue: appConfig }])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
