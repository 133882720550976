<div *ngIf="!isLive"
     class="time current-time">{{ timeString }}</div>
<ty-custom-seekbar class="seekbar"
                   [value]="seekTime"
                   [duration]="duration"
                   [thumbnails]="thumbnails"
                   [outerSeeking]="isSeeking"
                   [storyboard]="storyboard"
                   (seeking)="setSeeking($event)"
                   (onchange)="videoSeek($event)"
                   appNavigation
                   [navigableId]="0"></ty-custom-seekbar>
<div *ngIf="!isLive"
     class="time duration">{{ durationString }}</div>