/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PreorderApi } from '../models/preorder-api';
import { PreorderResponseApi } from '../models/preorder-response-api';

@Injectable({
  providedIn: 'root',
})
export class PreorderApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation preorderControllerFind
   */
  static readonly PreorderControllerFindPath = '/preorder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preorderControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  preorderControllerFind$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<Array<PreorderResponseApi>>> {

    const rb = new RequestBuilder(this.rootUrl, PreorderApiService.PreorderControllerFindPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PreorderResponseApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preorderControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  preorderControllerFind(params?: {
    language?: string;
  }): Observable<Array<PreorderResponseApi>> {

    return this.preorderControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PreorderResponseApi>>) => r.body as Array<PreorderResponseApi>)
    );
  }

  /**
   * Path part for operation preorderControllerCreate
   */
  static readonly PreorderControllerCreatePath = '/preorder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preorderControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  preorderControllerCreate$Response(params: {
    body: PreorderApi
  }): Observable<StrictHttpResponse<PreorderApi>> {

    const rb = new RequestBuilder(this.rootUrl, PreorderApiService.PreorderControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreorderApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preorderControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  preorderControllerCreate(params: {
    body: PreorderApi
  }): Observable<PreorderApi> {

    return this.preorderControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<PreorderApi>) => r.body as PreorderApi)
    );
  }

  /**
   * Path part for operation preorderControllerFindById
   */
  static readonly PreorderControllerFindByIdPath = '/preorder/{itemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preorderControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  preorderControllerFindById$Response(params: {

    /**
     * The video ID
     */
    itemId: string;
    language?: string;
  }): Observable<StrictHttpResponse<PreorderResponseApi>> {

    const rb = new RequestBuilder(this.rootUrl, PreorderApiService.PreorderControllerFindByIdPath, 'get');
    if (params) {
      rb.path('itemId', params.itemId, {});
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreorderResponseApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preorderControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  preorderControllerFindById(params: {

    /**
     * The video ID
     */
    itemId: string;
    language?: string;
  }): Observable<PreorderResponseApi> {

    return this.preorderControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<PreorderResponseApi>) => r.body as PreorderResponseApi)
    );
  }

  /**
   * Path part for operation preorderControllerUpdate
   */
  static readonly PreorderControllerUpdatePath = '/preorder/{itemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preorderControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  preorderControllerUpdate$Response(params: {

    /**
     * The video ID
     */
    itemId: string;
    language?: string;
    body: PreorderApi
  }): Observable<StrictHttpResponse<PreorderApi>> {

    const rb = new RequestBuilder(this.rootUrl, PreorderApiService.PreorderControllerUpdatePath, 'put');
    if (params) {
      rb.path('itemId', params.itemId, {});
      rb.header('language', params.language, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreorderApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preorderControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  preorderControllerUpdate(params: {

    /**
     * The video ID
     */
    itemId: string;
    language?: string;
    body: PreorderApi
  }): Observable<PreorderApi> {

    return this.preorderControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<PreorderApi>) => r.body as PreorderApi)
    );
  }

  /**
   * Path part for operation preorderControllerRemove
   */
  static readonly PreorderControllerRemovePath = '/preorder/{itemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preorderControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  preorderControllerRemove$Response(params: {

    /**
     * The video ID
     */
    itemId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PreorderApiService.PreorderControllerRemovePath, 'delete');
    if (params) {
      rb.path('itemId', params.itemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preorderControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  preorderControllerRemove(params: {

    /**
     * The video ID
     */
    itemId: string;
  }): Observable<void> {

    return this.preorderControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
