<div class="btn"
     appNavigation
     [navigableId]="0"
     (click)="onClickBtn()">
  <img class="btn-active"
       [src]=iconActive
       alt="Descripción">
  <img class="btn-default"
       [src]=iconDefault
       alt="Descripción">
</div>
<ty-list-selector *ngIf="showSubtitleList"
                  [navigableId]="1"
                  [itemTracks]="itemTracks"
                  (subtitledChanged)="changeItem($event)"></ty-list-selector>