import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { ListSelectorComponent } from '../list-selector/list-selector.component';
import { ListSelectorIconComponent } from './list-selector-icon.component';


@NgModule({
  imports: [ CommonModule, NavigationModule, TranslateModule ],
  declarations: [ListSelectorIconComponent, ListSelectorComponent],
  exports: [ListSelectorIconComponent]
})
export class ListSelectorIconModule { }
