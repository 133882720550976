import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconItemComponent } from './svg-icon-item/svg-icon-item.component';



@NgModule({
  declarations: [
    SvgIconItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SvgIconItemComponent
  ]
})
export class SvgIconItemModule { }
