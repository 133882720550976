import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { ImageResolutionApi, ImageSizeApi } from '@app/galgo-api/models';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { VideoFrontApi } from '../../../galgo-api/models/video-front-api';

@Component({
  selector: 'ty-next-chapter',
  templateUrl: './next-chapter.component.html',
  styleUrls: ['./next-chapter.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: NextChapterComponent },
  ],
})

export class NextChapterComponent extends NavigationComponent implements OnDestroy, AfterViewInit {
  @ViewChild('countdownButton', { static: false }) countdownButton: ElementRef;

  countdownInterval: any;
  countdown = 10;
  nextVideo: VideoFrontApi;

  get videoThumbnail(): string {
    const landscapes =  this.nextVideo?.thumbnail?.landscapes;
    if (landscapes && landscapes.length > 0) {
      return landscapes?.find((value: ImageResolutionApi) => value.size === ImageSizeApi.Original)?.url;
    }
    return '';
  }


  constructor(
    private galgoPlayerService: GalgoPlayerService,
    public el: ElementRef,
    private router: Router,
    private videoDetailService: VideoDetailService,
    ) {
    super(el);
    this.nextVideo = this.videoDetailService.getVideoNext();
   }

  ngOnDestroy(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.countdownInterval = setInterval(() => this.updateCountdown(), 1000);
  }

  updateCountdown() {
    this.countdown--;
    this.countdownButton.nativeElement.innerText = this.countdown.toString();

    if (this.countdown === 0) {
      clearInterval(this.countdownInterval);
      this.navigateToNextChapter();
    }
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  navigateToNextChapter() {
    if (this.nextVideo) {
      this.router.navigate([AppRoutes.player, this.nextVideo?._id]);
      this.galgoPlayerService.driver?.play();
      this.videoDetailService.deleteVideoNext();
    }
  }
}
