import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from './button.component';

const ANGULAR_MODULES = [ CommonModule ];
const EXTERNAL_MODULES = [ TranslateModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...EXTERNAL_MODULES,
    NavigationModule
 ],
  declarations: [ ButtonComponent ],
  exports: [ ButtonComponent ]
})
export class ButtonModule { }
