import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollectionsMenuComponent } from './collections-menu/collections-menu.component';

const routes: Routes = [
  { path: '', component: CollectionsMenuComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CollectionsMenuRoutingModule{ }
