import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { GridThumbModule } from '@shared/components/grid-thumb/grid-thumb.module';
import { LandscapeGridThumbModule } from '../../landscape-grid-thumb/landscape-grid-thumb.module';
import { GridRowComponent } from './grid-row.component';

const ANGULAR_MODULES = [ CommonModule ];
const SHARED_MODULES = [ GridThumbModule,LandscapeGridThumbModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...SHARED_MODULES,
    NavigationModule,
 ],
  declarations: [ GridRowComponent ],
  exports: [ GridRowComponent ]
})
export class GridRowModule { }
