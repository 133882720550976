<div id="grid"
     #gridElement>
  <div class="grid-wrapper">
    <ty-virtual-scroll class="virtual-scroll"
                       #virtualScroll
                       [items]="virtualScrollItems"
                       [numVisibleItems]="2"
                       [toleranceAfter]="1"
                       [toleranceBefore]="1"
                       [firstSelectedChildNodeId]="firstSelectedChildNodeId">
      <div class="grid-container">
        <ty-grid-row *ngFor="let item of virtualScroll.visibleItems; let index = index trackBy: trackByFn"
                     #gridElement
                     [navigableId]="item.id"
                     [items]="item.data"
                     [galgoEvent]="galgoEvent">
        </ty-grid-row>
      </div>
    </ty-virtual-scroll>
  </div>
</div>