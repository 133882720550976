<div class="labels"
     *ngIf="!isModalOpened">
  <div class="tab"
       *ngFor="let tab of tabs; let i = index;"
       appNavigation
       [navigableId]="i"
       [ngClass]="{'active': tab.active}"
       id="{{tab?.label}}"
       (click)="selectTab(tab.content_selector)"
       (focus)="selectTab(tab.content_selector)">
    <span class="label">{{tab.label | translate}}</span>
    <div class="underline-active-label"></div>
  </div>
</div>