/**
 * Checks wether a URL matches a pattern in the given list.
 *
 * @param targetUrl The URL to check.
 * @param urlPatterns The appropriate list of URL patterns.
 * @returns True if the URL matches a pattern in the list.
 */
export const checkExceptionsPattern = (
  targetUrl: string,
  urlPatterns: string[]
): boolean => urlPatterns.some((pattern: string) => targetUrl.includes(pattern));
