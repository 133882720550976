import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { VirtualScrollItem } from './virtual-scroll-item.interface';

@Component({
  selector: 'ty-virtual-scroll',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualScrollComponent implements OnInit, OnChanges {
  @Input() items: VirtualScrollItem[];
  @Input() numVisibleItems: number;
  @Input() toleranceBefore: number;
  @Input() toleranceAfter: number;
  @Input() firstSelectedChildNodeId: number;

  visibleItems: VirtualScrollItem[];
  emptySpaceWidth: number;
  emptySpaceHeight: number;
  firstLoad: boolean;
  private index: number;
  private intervalVisibleItems: {
    start: number;
    end: number;
  };

  constructor(private changeDetector: ChangeDetectorRef) {
    this.emptySpaceWidth = 0;
    this.emptySpaceHeight = 0;
    this.visibleItems = [];

    this.items = [];
    this.numVisibleItems = 4;
    this.toleranceBefore = 1;
    this.toleranceAfter = 1;
    this.index = 0;

    this.firstSelectedChildNodeId = 0;
    this.firstLoad = false;
  }

  ngOnInit(): void {
    if (!this.scrollToIndex(this.firstSelectedChildNodeId)) {
      this.scrollToIndex(this.index);
    }
    this.firstLoad = true;
  }

  ngOnChanges(): void {
    if (this.firstLoad) {
      this.scrollToIndex(this.index);
    }
  }

  scrollToIndex(index: number): boolean {
    if (index >= 0 && index < this.items.length) {
      this.index = index;
      this.changeVisibleItems();
      return true;
    }
    return false;
  }

  scrollToPreviousElement(): boolean {
    if (this.index > 0) {
      this.index--;
      this.changeVisibleItems();
      return true;
    }
    return false;
  }

  scrollToNextElement(): boolean {
    if (this.index < this.items.length - 1) {
      this.index++;
      this.changeVisibleItems();
      return true;
    }
    return false;
  }

  private changeVisibleItems(): void {
    this.intervalVisibleItems = this.getInterval(
      this.index,
      this.numVisibleItems,
      this.toleranceBefore,
      this.toleranceAfter
    );
    this.visibleItems = this.items.slice(
      this.intervalVisibleItems.start,
      this.intervalVisibleItems.end
    );
    this.updateEmptySpace();
    this.changeDetector.detectChanges();
  }

  private updateEmptySpace(): void {
    this.emptySpaceWidth = 0;
    this.emptySpaceHeight = 0;
    this.items.slice(0, this.intervalVisibleItems.start).forEach(item => {
      this.emptySpaceWidth += item.width;
      this.emptySpaceHeight += item.height;
    });
  }

  private getInterval(
    index: number,
    numVisibleItems: number,
    toleranceBefore: number,
    toleranceAfter: number
  ): { start: number; end: number } {
    let start = index - toleranceBefore;
    start = start < 0 ? 0 : start;
    const end = index + numVisibleItems + toleranceAfter;
    return { start, end };
  }
}
