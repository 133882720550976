import { PriceApi, ProductTypeApi, TermApi } from '@api/models';
import { SubscriptionPeriod } from './subscription-period.model';
import { SubscriptionPlanPrice } from './subscription-plan-price.model';


interface ISubscriptionPlan {
  _id: string;
  description?: string | TermApi[];
  endDate?: number;
  expirationDate?: number;
  featuredText?: string | string[];
  hasRibbon?: boolean;
  name?: string | TermApi[];
  prices: SubscriptionPlanPrice[] | PriceApi[] | string[];
  purchaseDate?: number;
  type?: ProductTypeApi;
  status?: string;
}

export class SubscriptionPlan {
  private _description: string;
  private _endDate?: number;
  private _expirationDate?: number;
  private _featuredText?: string | string[];
  private _hasRibbon?: boolean;
  private _id: string;
  private _name: string;
  private _prices: SubscriptionPlanPrice[];
  private _purchaseDate?: number;
  private _type: string;
  private _periods: SubscriptionPeriod[];
  private _status: string;

  get id(): string {
    return this._id;
  }
  get description(): string {
    return this._description;
  }
  get ribbonText(): string | string[] {
    return this._featuredText;
  }
  get name(): string {
    return this._name;
  }
  get prices(): SubscriptionPlanPrice[] {
    return this._prices;
  }
  get purchaseDate(): number {
    return this._purchaseDate;
  }
  get expirationDate(): number {
    return this._expirationDate || this._endDate;
  }
  get type(): string {
    return this._type;
  }
  get hasRibbon(): boolean {
    return this._hasRibbon;
  }

  get status(): string {
    return this._status;
  }

  constructor(init?: ISubscriptionPlan, periods?: SubscriptionPeriod[]) {
    if (init) {
      this._id = init._id;
      this._name = init.name as string;
      this._description = init.description as string;
      this._expirationDate = init.expirationDate;
      this._endDate = init.endDate;
      this._featuredText = init.featuredText;
      this._periods = periods;
      this._prices = this.formatPrices(init.prices as PriceApi[]);
      this._purchaseDate = init.purchaseDate;
      this._type = init.type;
      this._hasRibbon = this.checkRibbon(this._featuredText as string);
      this._status = init.status;
    }
  }

  private checkRibbon(ribbonText: string): boolean {
    return !!ribbonText?.length;
  }

  private formatPrices(prices: PriceApi[]): SubscriptionPlanPrice[] {
    return prices.map(
      (price: PriceApi) => new SubscriptionPlanPrice(price, this.getPricePeriod(price))
    );
  }

  private getPricePeriod(price: PriceApi) {
    return this._periods?.find(
      (period: SubscriptionPeriod) => period.period === price.period
    );
  }
}
