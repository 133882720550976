import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { LayoutModule } from '@app/layout/layout.module';
import { CustomRouterModule } from '@app/shared/components/router/custom-router.module';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { PublicPagesRoutingModule } from './public-pages-routing.module';


@NgModule({
  declarations: [
    PublicLayoutComponent
    ],
  imports: [
    CommonModule,
    LayoutModule,
    PublicPagesRoutingModule,
    CustomRouterModule,
    NavigationModule
  ]
})
export class PublicPagesModule { }
