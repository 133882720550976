import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogService } from '@app/auth/services/catalog/catalog.service';
import { IBaseApiParams } from '@app/core/models/base-api-params.interface';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { ContainerService } from '@app/core/services/container.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { LanguageService } from '@app/core/services/language.service';
import { AdvertisementApi } from '@app/galgo-api/models';
import { IVideoApi, Video } from '@app/shared/models/video/video.model';
import { Container, IContainer, IContainerByIdParams } from '@core/models/container.model';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Carousel } from '@shared/features/carousel/model/carousel.model';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, throwError } from 'rxjs';
import { first } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ty-home',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: HomePageComponent }],
})
export class HomePageComponent extends NavigationComponent implements OnInit {
  carousels: Carousel[];
  currentUrl: string;
  customCarousels: Carousel[];
  featuredMediaItems: MediaItem<MediaItemTypes>[];
  isHidden: boolean;
  isLoading: boolean;
  selectedItem: MediaItem<MediaItemTypes>;
  showThumbnailInformation: boolean;

  constructor(
    public logger: NGXLogger,
    private catalogService: CatalogService,
    private containerService: ContainerService,
    private browsingHistoryService: BrowsingHistoryService,
    private featureFlagsService: FeatureFlagsService,
    private languageService: LanguageService,
    private router: Router,

    public el: ElementRef
  ) {
    super(el);
    this.carousels = [];
    this.currentUrl = '';
    this.customCarousels = [];
    this.isHidden = false;
    this.showThumbnailInformation = false;
  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData?.heroBanner){
      this.selectChildNodeById(0);
    } else
    if (focusData?.viewAggregator) {
      this.selectChildNodeById(1);
    }
    this.getContainers();
    this.selectedItem = null;
    this.checkCatalogs();
  }

  setSelectedItem(event: MediaItem<MediaItemTypes>) {
    this.selectedItem = event;
    this.showThumbnailInformation = true;
    this.isHidden = true;
  }

  onUpKey(): boolean {
    this.selectPreviousChildNode();
    if(this.featuredMediaItems && this.featuredMediaItems.length > 0  && this.getSelectedChildNodeId() === 0) {
      this.showThumbnailInformation = false;
      this.isHidden = false;
    }
    return true;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onBackKey(): boolean {
    if(this.featureFlagsService?.currentFeaturesValue?.haveCatalogs){
      this.router.navigate([AppRoutes.catalogSelector]);
    }else{
      this.router.navigate([AppRoutes.exitApp]);
    }
    return true;
  }

  checkCatalogs(): void {
    if(this.featureFlagsService.currentFeaturesValue.haveCatalogs && this.catalogService.getImageCatalog() == null){
      this.router.navigate([AppRoutes.catalogSelector]);
    }
  }

  getContainers() {
    const homeParams: IContainerByIdParams = {
      id: 'home',
      language: this.languageService.currentLang,
      size: 100
    };

    const customParams: IBaseApiParams = { language: this.languageService.currentLang };

    forkJoin([
      this.containerService.getContainerById(homeParams),
      this.containerService.getCustomCarousels(customParams),
    ])
      .pipe(first())
      .subscribe({
        next: ([homeContainer, customCarousels]: [Container, Carousel[]]) => {
          const indexKeyToSort = 'position';
          this.processHomeContainers(homeContainer);
          this.customCarousels = customCarousels;
          this.carousels = this.formatPositionOrder(
            this.carousels,
            customCarousels,
            indexKeyToSort
          );
          this.logger.debug('HomeComponent -> GetContainers(): Success', [
            homeContainer,
            customCarousels,
          ]);
        },
        complete: () => {
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error('HomeComponent -> GetContainers(): Failed', error);
          throwError(() => new HttpErrorResponse(error));
        },
      });
  }

  processHomeContainers(homeContainer: Container) {

    if (homeContainer.heroBanner.length > 0) {
      this.getHeroBannerVideos(homeContainer);
    }

    let homeCarousels: Carousel[] = [];
    if (!!homeContainer?.subContainers?.length) {
      homeCarousels = homeContainer.subContainers.map(
        (carousel: IContainer) => new Carousel(carousel)
      );

      this.getCarousels(homeCarousels, this.carousels);
    }
  }

  private getHeroBannerVideos(homeContainer: Container) {
    this.featuredMediaItems = homeContainer.heroBanner
      .filter((element) => element.itemType !== 'Advertisement')
      .map((videoItem: IVideoApi) => new Video(videoItem));

      //filtro para remover directo
      if(this.featureFlagsService.currentFeaturesValue.filterLives){
      this.featuredMediaItems = this.featuredMediaItems.filter((element) => element.analytics.live !== true);
      }
  }

  private getCarousels(
    carousels: (AdvertisementApi | Carousel)[],
    arrayToSave: Carousel[]
  ) {
    carousels.forEach((carousel: Carousel) => {
      if (carousel.hasVideos || carousel.hasCollections) {
        arrayToSave.push(carousel);
      }
    });
  }

  /**
   * Sorts the source array to insert via am especified key as index. Then inserts the items in the container by position
   *
   * @param carousels {@link Carousel}
   * @param arrayToInsert {@link Carousel}
   * @param indexKeyToFormatBy {@link string}
   * @returns carousel {@link Carousel}
   */
  private formatPositionOrder(
    carousels: Carousel[],
    arrayToInsert: Carousel[],
    indexKeyToFormatBy: string
  ) {
    arrayToInsert = arrayToInsert.sort(
      (a, b) => a[indexKeyToFormatBy] - b[indexKeyToFormatBy]
    );

    arrayToInsert.forEach((carousel: Carousel) => {
      if (!carousels.includes(carousel)) {
        carousels.splice(carousel[indexKeyToFormatBy], 0, carousel);
      }
    });

    return carousels;
  }

}
