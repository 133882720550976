import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagModule } from '@shared/components/tag/tag.module';
import { MediaThumbComponent } from './media-thumb.component';

const ANGULAR_MODULES = [ CommonModule ];
const SHARED_MODULES = [ TagModule ];
@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...SHARED_MODULES
 ],
  declarations: [MediaThumbComponent],
  exports: [MediaThumbComponent]
})
export class MediaThumbModule { }
