/* tslint:disable */
/* eslint-disable */

/**
 * Order status
 */
export enum OrderStatusApi {
  Draft = 'DRAFT',
  PendingValidateFilmo = 'PENDING_VALIDATE_FILMO',
  NotCompletedInformation = 'NOT_COMPLETED_INFORMATION',
  ApprovedNotPaid = 'APPROVED_NOT_PAID',
  PaidNotUserSigned = 'PAID_NOT_USER_SIGNED',
  NotFilmoSigned = 'NOT_FILMO_SIGNED',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED',
  CanceledByUser = 'CANCELED_BY_USER'
}
