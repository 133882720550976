<div class="wrap"
     appNavigation
     [navigableId]="0"
     (click)="navigateToNextChapter()"
     [style.background-image]="'url(' + videoThumbnail + ')'">
  <p #countdownButton
     class="countdown">10</p>
  <p>{{'buttons.nextChapter' | translate}}</p>
</div>
<div class="scrim">
</div>
