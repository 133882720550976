import { LanguageIsoApi, LayoutFrontApi, LayoutPaymentFrontApi, TagResponseApi, TermApi } from '@api/models';
import { CategoryTypeEnum } from '@app/shared/models/category-type.enum';
import { environment } from '@environments/environment';
import { Capitalize } from '@shared/functions/capitalize';
import { ILayoutPayment } from './layout-payment.interface';
export class Layout {
  private _label?: TagResponseApi[];
  private _pegi?: TagResponseApi;
  private _topic?: TagResponseApi[];
  private _ppv?: LayoutPaymentFrontApi;
  private _subgenre?: TagResponseApi[];
  private _subscription?: LayoutPaymentFrontApi;
  private _other?: TagResponseApi[];

  get label(): TagResponseApi[] {
    return this._label;
  }

  get pegi(): string {
    if(this._pegi){
      return this._pegi.value;
    } else {
      return '';
    }
  }

  get topic(): TagResponseApi[] {
    if (this._topic) {
      return this._topic;
    } else {
      return  [];
    }
  }

  get ppv(): ILayoutPayment {
    return this.getCurrentLanguageLayoutPayment(this._ppv);
  }

  get subgenre(): TagResponseApi[] {
    return this._subgenre;
  }

  get subscription(): ILayoutPayment {
    return this.getCurrentLanguageLayoutPayment(this._subscription);
  }

  get other(): TagResponseApi[] {
    return this._other;
  }

  get rightOwnership(): TagResponseApi {
    return this._other.find((category) => category.categoryTypeId === CategoryTypeEnum.rightsOwnership);
  }

  constructor(init?: LayoutFrontApi) {
    if (init) {
      this._label = init.label;
      this._pegi = init.pegi;
      this._topic = init.topic;
      this._subgenre = init.subgenre;
      this._other = init.other;
      this._ppv = init.pay_per_view;
      this._subscription = init.subscription;
    }
  }

  getCurrentLanguageLayoutPayment(layoutPayment: LayoutPaymentFrontApi) {
    let name = {language: LanguageIsoApi.Es, value: ''};
    if (typeof layoutPayment?.name === 'string') {
      name.language = LanguageIsoApi[Capitalize(environment.defaultLanguage)];
      name.value = layoutPayment.name;
    } else {
      name = (layoutPayment?.name as TermApi[])?.find(
        (term: TermApi) => term?.language === environment?.defaultLanguage
      );
    }

    return {
      _id: layoutPayment?._id,
      name,
      price_id: layoutPayment?.price_id,
    };
  }
}
