import { UIPlayerConfiguration } from 'video.js';


export const VIDEOJS_DEFAULT_OPTIONS: UIPlayerConfiguration | any = {
  autoplay: true,
  controls: false,
  loadingSpinner : false,
  preload: 'auto',
  fluid: true,
  language : 'es',
  // Special configuration for correct playback on Samsung TV
  // More info: https://github.com/videojs/http-streaming?tab=readme-ov-file#overridenative
  html5: {
    vhs: {
      overrideNative: true
    },
    nativeAudioTracks: false,
    nativeVideoTracks: false
  }
  };
