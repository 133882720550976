import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DogComponent } from './dog.component';

const ANGULAR_MODULES = [ CommonModule ];
const EXTERNAL_MODULES = [ TranslateModule];

@NgModule({
  imports: [
    ANGULAR_MODULES,
    EXTERNAL_MODULES
  ],
  declarations: [ DogComponent ],
  exports: [ DogComponent, ]
})
export class DogModule { }
