import { Component, ElementRef } from '@angular/core';
import { NavigationType } from '@app/core/navigation/models/navigation.types';
import { NavigationComponent } from '@app/core/navigation/navigation.component';

@Component({
  selector: 'ty-router',
  templateUrl: './router.component.html',
  providers: [{ provide: NavigationComponent, useExisting: RouterComponent }],
})
export class RouterComponent extends NavigationComponent {

  counter: number;

  constructor(
    public el: ElementRef
  ) {
    super(el);
    this.counter = 0;
  }

  componentAdded(event: any): void {
    if (event.type === NavigationType.component) {
      setTimeout(() => {
        this.counter++;
        if(this.counter > 0){
          event.navigableId= 0;
          this.appendChildNode(event);
        }
      }, 0);
    }
  }

  componentRemoved(event: any): void {
    if (event.type === NavigationType.component) {
      this.counter--;
      this.popChildNode();
    }
  }

}
