import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TagComponent } from '@shared/components/tag/tag.component';
import { SvgIconItemModule } from '../svg-icon-item/svg-icon-item.module';


const INTERNAL_MODULES = [TranslateModule];
const ANGULAR_MODULES = [CommonModule];

@NgModule({
  imports: [...INTERNAL_MODULES, ...ANGULAR_MODULES, SvgIconItemModule],
  declarations: [TagComponent],
  exports: [TagComponent],
})
export class TagModule { }
