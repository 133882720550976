import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenRefreshResponseApi } from '@api/models/token-refresh-response-api';
import { IAuthToken } from '@app/auth/models/token.model';
import { AuthService } from '@app/auth/services/auth-custom/auth.service';
import { allowedUrlListPattern } from '@app/core/helpers/interceptors/allowed-url-list.constants';
import { checkExceptionsPattern } from '@app/core/helpers/interceptors/interceptor.utils';

import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly AUTH_HEADER = 'Authorization';
  private _tokenRefreshInProgress = false;
  private _refreshTokenSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private logger: NGXLogger, private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> | Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error?.status === HttpStatusCode.Unauthorized &&
          !checkExceptionsPattern(request.url, allowedUrlListPattern)
        ) {
          this.logger.debug(
            'Authentication Interceptor -> Error 401 Unauthorized: Attempting to refresh token'
          );
          if (this._tokenRefreshInProgress) {
            if (error?.status === HttpStatusCode.Unauthorized) {
              this.authService.logout();
            }
            return this._refreshTokenSubject$.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request))),
              untilDestroyed(this)
            );
          } else {
            this._tokenRefreshInProgress = true;
            this._refreshTokenSubject$.next(null);
            return this.authService.refreshToken().pipe(
              catchError((err) => {
                this.logger.error(
                  'ErrorInterceptor > refreshToken catchError - err',
                  err
                );
                this.authService.logout();
                return EMPTY;
              }),
              switchMap((success: TokenRefreshResponseApi) => {
                this.logger.debug(
                  'ErrorInterceptor > refreshToken switchMap - success',
                  success
                );
                this._refreshTokenSubject$.next(success);
                return next.handle(this.addAuthenticationToken(request));
              }),
              finalize(() => (this._tokenRefreshInProgress = false)),
              untilDestroyed(this)
            );
          }
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addAuthenticationToken(
    request: HttpRequest<IAuthToken>
  ): HttpRequest<IAuthToken> {
    if (!this.authService?.token?.accessToken) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(
        this.AUTH_HEADER,
        `${environment.tokenType} ${this.authService.token?.accessToken}`
      ),
    });
  }
}
