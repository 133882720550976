<div class="tag"
     id="tag"
     [ngClass]="getTagClass()"
     (mouseenter)="mouseEnter()"
     (mouseleave)="mouseLeave()"
     #tag>
   <span *ngIf="content?.text"
         id="textTag"
         class="text"
         #textElement
         [ngClass]="{
          'grid-text': gridTextTagsOnHover,
          'show-grid-text': showGridTextTags,
          'carousel-text': carouselTextTagsOnHover,
          'show-carousel-text': showCarouselTextTags
        }">{{ content?.text | translate }}</span>
   <i *ngIf="isLive"
      class="gi-xl gi-live"></i>
   <i *ngIf="isSubscription"
      class="gi-xl gi-subscription"></i>
   <i *ngIf="isPpv"
      class="gi-xl gi-ppv"></i>
</div>