import { FavoritesApi } from '@api/models/favorites-api';
import { LanguageIsoApi } from '@api/models/language-iso-api';
import { PaidItemsApi } from '@api/models/paid-items-api';
import { PaidSubscriptionApi } from '@api/models/paid-subscription-api';
import { PaidVideoApi } from '@api/models/paid-video-api';
import { UserFrontApi } from '@api/models/user-front-api';
import { UserStatusApi } from '@app/galgo-api/models/user-status-api';
import { IPaidItems } from './paid-items.interface';
import { SubscriptionPlan } from './subscription-plan.model';

export interface IUser extends UserFrontApi {
  banned?: boolean;
  createdAt?: number;
  defaultLanguage?: LanguageIsoApi;
  phone?: string;
  password?: string;
  privacy?: string;
  terms?: string;
  updatedAt?: number;
}

export class User {
  private _id: string;
  private _areLegalTermsAccepted: boolean;
  private _customerId?: string;
  private _email: string;
  private _favoriteLanguageCode?: string;
  private _favorites?: FavoritesApi;
  private _idRecommendedGroup?: number;
  private _legalConditionsId: number;
  private _name: string;
  private _paidItems?: IPaidItems;
  private _rawPaidItems?: PaidItemsApi;
  private _status: UserStatusApi;
  private _banned?: boolean;
  private _createdAt?: number;
  private _defaultLanguage?: LanguageIsoApi;
  private _phone?: string;
  private _privacy?: string;
  private _terms?: string;
  private _updatedAt?: number;
  private _password?: string;

  get id(): string {
    return this._id;
  }

  get hasAcceptedLegalTerms(): boolean {
    return this._areLegalTermsAccepted;
  }

  get customerId(): string {
    return this._customerId;
  }

  get email(): string {
    return this._email;
  }

  get favoriteLanguageCode(): string {
    return this._favoriteLanguageCode;
  }

  set favoriteLanguageCode(value: string) {
    this._favoriteLanguageCode = value;
  }

  get favorites(): FavoritesApi {
    return this._favorites;
  }

  get recommendedGroupId(): number {
    return this._idRecommendedGroup;
  }

  get legalConditionsId(): number {
    return this._legalConditionsId;
  }

  get name(): string {
    return this._name;
  }

  get paidItems(): IPaidItems {
    return this._paidItems;
  }

  get userConfirmationStatus(): UserStatusApi {
    return this._status;
  }

  get isConfirmed(): boolean {
    return this._status === UserStatusApi.Confirmed;
  }

  get isBanned(): boolean {
    return this._banned;
  }

  get password(): string {
    return this._password;
  }

  get createdAt(): number {
    return this._createdAt;
  }

  get defaultLanguage(): LanguageIsoApi {
    return this._defaultLanguage;
  }

  get updatedAt(): number {
    return this._updatedAt;
  }

  get phone(): string {
    return this._phone;
  }

  get privacy(): string {
    return this._privacy;
  }

  get terms(): string {
    return this._terms;
  }

  get currentPlan(): SubscriptionPlan {
    if (this._paidItems?.subscriptions?.length) {
      return this._paidItems?.subscriptions[0];
    }

    return undefined;
  }

  constructor(init?: IUser) {
    if (init) {
      this._id = init._id;
      this._areLegalTermsAccepted = init.areLegalTermsAccepted;
      this._banned = init.banned;
      this._createdAt = init.createdAt;
      this._customerId = init.customerId;
      this._defaultLanguage = init.defaultLanguage;
      this._email = init.email;
      this._favoriteLanguageCode = init.favoriteLanguageCode;
      this._favorites = init.favorites;
      this._idRecommendedGroup = init.idRecommendedGroup;
      this._legalConditionsId = init.legalConditionsId;
      this._name = init.name;
      this._rawPaidItems = init.paidItems;
      this._paidItems = this.formatPaidItems(this._rawPaidItems);
      this._phone = init.phone;
      this._privacy = init.privacy;
      this._status = init.status;
      this._terms = init.terms;
      this._updatedAt = init.updatedAt;
    }
  }

  serialize(): string {
    const user: IUser = {
      _id: this._id,
      areLegalTermsAccepted: this._areLegalTermsAccepted,
      banned: this._banned,
      createdAt: this._createdAt,
      customerId: this._customerId,
      defaultLanguage: this._defaultLanguage,
      email: this._email,
      favoriteLanguageCode: this._favoriteLanguageCode,
      favorites: this._favorites,
      idRecommendedGroup: this._idRecommendedGroup,
      legalConditionsId: this._legalConditionsId,
      name: this._name,
      paidItems: this._rawPaidItems,
      phone: this._phone,
      privacy: this._privacy,
      status: this._status,
      terms: this._terms,
      updatedAt: this._updatedAt,
    };
    return JSON.stringify(user);
  }

  private formatPaidItems(paidItems: PaidItemsApi): IPaidItems {
    const subscriptions: SubscriptionPlan[] = paidItems?.subscriptions?.map(
      (paidItem: PaidSubscriptionApi) => new SubscriptionPlan(paidItem)
    );

    const formattedPaidItems: IPaidItems = {
      subscriptions,
      paidVideos: paidItems?.purchases?.map((item: PaidVideoApi) => item?.productId),
    };

    return formattedPaidItems;
  }
}
