import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RestrictedAccessGuard } from './auth/guards/restricted-access.guard';
import { Page404Component } from './pages/public/page404/page404.component';


const routes: Routes = [
  {
    path: '404',
    pathMatch: 'full',
    component: Page404Component,
  },
  {
    path: 'pages',
    pathMatch: 'prefix',
    canActivate: [RestrictedAccessGuard],// TODO check RestrictedAccessGuard
    loadChildren: () =>
      import('./pages/protected/protected-pages.module').then(
        (m) => m.ProtectedPagesModule
      ),
  },
  {
    path: 'landing',
    pathMatch: 'prefix',
    canActivate: [RestrictedAccessGuard],// TODO check RestrictedAccessGuard
    loadChildren: () =>
      import('./auth/auth.module').then(
        (m) => m.AuthModule
      ),
  },
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'prefix',
  },
   {
     path: '',
     loadChildren: () =>
       import('./pages/public/public-pages.module').then(
         (m) => m.PublicPagesModule
       ),
   },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  declarations: [Page404Component],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
