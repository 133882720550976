export enum QRCodeErrorCorrectionLevel {
  L = 'L',
  M = 'M',
  Q = 'Q',
  H = 'H',
  low = 'low',
  medium = 'medium',
  quartile = 'quartile',
  high = 'high'
}
