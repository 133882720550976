import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { LoadingSpinnerModule } from '@app/shared/components/loading-spinner/loading-spinner.module';
import { LegalDisplayModule } from '@shared/components/legal-display/legal-display.module';
import { LegalConditionsRoutingModule } from './legal-conditions-routing.module';
import { LegalConditionsComponent } from './legal-conditions.component';



@NgModule({
  declarations: [
    LegalConditionsComponent,
  ],
  imports: [
    CommonModule,
    LegalDisplayModule,
    LegalConditionsRoutingModule,
    NavigationModule,
    LoadingSpinnerModule
  ]
})
export class LegalConditionsModule { }
