import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { GridModule } from '@app/shared/components/grid/grid.module';
import { LoadingSpinnerModule } from '@app/shared/components/loading-spinner/loading-spinner.module';
import { ReturnButtonModule } from '@app/shared/components/return-button/return-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { SeeMoreRoutingModule } from './see-more-routing.module';
import { SeeMoreComponent } from './see-more/see-more.component';

const ANGULAR_MODULES = [ CommonModule ];
const COMPONENTS_MODULES = [
  GridModule,
  LoadingSpinnerModule,
  ReturnButtonModule,
];
const EXTERNAL_MODULES = [ TranslateModule ];
@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...COMPONENTS_MODULES,
    ...EXTERNAL_MODULES,
    SeeMoreRoutingModule,
    NavigationModule
  ],
  declarations: [
    SeeMoreComponent
  ]
})
export class SeeMoreModule { }
