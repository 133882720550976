import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SubscriptionComponent } from './subscription.component';
const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [ ...ANGULAR_MODULES ],
  declarations: [ SubscriptionComponent ],
  exports: [ SubscriptionComponent ]
})
export class SubscriptionModule { }
