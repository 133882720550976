import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { BrowserModule } from '@angular/platform-browser';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { environment } from '@environments/environment';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MarkdownModule } from 'ngx-markdown';
import { AnalyticsModule } from './analytics/analytics.module';
import { apiConfigurationFactory } from './api-configuration.factory';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CatalogService } from './auth/services/catalog/catalog.service';
import { CoreModule } from './core/core.module';
import { SpinnerInterceptor } from './core/interceptors/spinner.interceptor';
import { ApiConfiguration } from './galgo-api/api-configuration';
import { ApiModule } from './galgo-api/api.module';
import { CustomRouterModule } from './shared/components/router/custom-router.module';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { SpinnerService } from './shared/services/spinner.service';
import { RootTranslationModule } from './shared/translations/root-translation.module';

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
    ],
    providers: [
        SpinnerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    imports: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAnalytics(() => getAnalytics()),
        ApiModule.forRoot({
            config: {
                provide: ApiConfiguration,
                useFactory: apiConfigurationFactory,
                deps: [CatalogService]
            }
        }),
        AppRoutingModule,
        AuthModule,
        AnalyticsModule,
        BrowserModule,
        CustomRouterModule,
        NavigationModule,
        CoreModule.forRoot(),
        FeatureToggleModule,
        HttpClientModule,
        LoggerModule.forRoot({
            level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF,
            serverLogLevel: NgxLoggerLevel.ERROR,
            enableSourceMaps: !environment.production ? true : false,
        }),
        MarkdownModule.forRoot(),
        RootTranslationModule
    ]
})
export class AppModule { }
