/* tslint:disable */
/* eslint-disable */

/**
 * Item type value COMPANY
 */
export enum ItemTypeApi {
  Video = 'Video',
  Audio = 'Audio',
  Advertisement = 'Advertisement',
  Link = 'Link',
  Container = 'Container',
  Company = 'Company'
}
