import CaLang from '../../../../assets/i18n/theo.ca.json';
import EsLang from '../../../../assets/i18n/theo.es.json';
import { UIPlayerConfiguration } from '../../../../assets/libraries/theo/THEOplayer';


export const THEO_DEFAULT_OPTIONS: UIPlayerConfiguration | any = {
  allowNativeFullscreen: true,
  libraryLocation: '/assets/libraries/theo',
  mutedAutoplay: 'content',
  ui: {
    fluid: true,
    language: 'es',
    languages: {
      ca: CaLang,
      es: EsLang
    },
  }
};
