import { Component, ElementRef } from '@angular/core';
import { ExternalAppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { CustomerDataService } from '@core/services/customer-data.service';

@Component({
  selector: 'ty-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: SignInPageComponent }]
})
export class SignInPageComponent extends NavigationComponent {

  showBackButton: boolean;


  get registerUrl(): string {
    return `${this.customerDataService.webBaseUrl}/${ExternalAppRoutes.register}`;
  }
  constructor(
    private customerDataService: CustomerDataService,
    private browsingHistoryService: BrowsingHistoryService,
    private featureFlagsService: FeatureFlagsService,
    public el: ElementRef
  ) {
    super(el);
    this.showBackButton = this.featureFlagsService?.currentFeaturesValue?.showBackButton;
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }

}
