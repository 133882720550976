import { IKeyCodes } from '../models/keycodes.interface';

export enum KeyCodesNames {
  left ='left',
  up = 'up',
  right = 'right',
  down = 'down',
  enter = 'enter',
  back = 'back',
  space = 'space',
  playPause = 'playPause',
  play = 'play',
  pause = 'pause',
  stop = 'stop',
  rewind = 'rewind',
  fastForward = 'fastForward',
  mediaTrackPrevious = 'mediaTrackPrevious',
  mediaTrackNext = 'mediaTrackNext',
  intro = 'intro',
  zero = 'zero',
  one = 'one',
  two = 'two',
  three = 'three',
  four = 'four',
  five = 'five',
  six = 'six',
  seven = 'seven',
  eight = 'eight',
  nine = 'nine',
};

export const keyCodesSamsung: IKeyCodes = {
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  enter: 13,
  back: 10009,
  space: 13,
  playPause: 10252,
  play: 415,
  pause: 19,
  stop: 413,
  rewind: 412,
  fastForward: 417,
  mediaTrackPrevious: 10232,
  mediaTrackNext: 10233,
  intro: 65376,
  zero: 48,
  one: 49,
  two: 50,
  three: 51,
  four: 52,
  five: 53,
  six: 54,
  seven: 55,
  eight: 56,
  nine: 57,
};

export const keyCodesLG: IKeyCodes = {
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  enter: 13,
  back: 461,
  playPause: -1, // Not exists
  play: 415,
  pause: 19,
  stop: 413,
  rewind: 412,
  fastForward: 417,
};

export const keyCodesBrowser: IKeyCodes = {
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  enter: 13,
  back: 27, // ESC
  playPause: 81, // Q
  play: 87, // W
  pause: 69, // E
  stop: 82, // R
  rewind: 84, // T
  fastForward: 89, // Y
};
