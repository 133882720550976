<div id="genres-menu">
  <h4>{{ title | translate }}</h4>
  <div class="black-gradient-overlay"></div>
  <div class="grid-overflow-hidden"
       #genres>
    <ty-grid class="grid"
             *ngIf="mediaItems?.length && this.isDataReceived"
             [items]="mediaItems"
             [navigableId]="0"
             (updateScroll)="updateScroll()">
    </ty-grid>
  </div>
</div>