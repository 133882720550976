/* tslint:disable */
/* eslint-disable */

/**
 * Requested action
 */
export enum RequestedActionApi {
  AccessIndustryContentViewer = 'ACCESS_INDUSTRY_CONTENT_VIEWER',
  AccessIndustryContentOwner = 'ACCESS_INDUSTRY_CONTENT_OWNER',
  AccessIndustryVideoView = 'ACCESS_INDUSTRY_VIDEO_VIEW'
}
