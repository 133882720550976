import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContainerTypes } from '@app/core/models/container-types.enum';
import { Container, IContainerParams } from '@app/core/models/container.model';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { ContainerService } from '@app/core/services/container.service';
import { LanguageService } from '@app/core/services/language.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { Collection } from '@app/shared/models/collection/collection.model';
import { ItemTypes } from '@app/shared/models/item-types.enum';
import { Video } from '@app/shared/models/video/video.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { GenresConfiguration } from './genres.config';

@UntilDestroy()
@Component({
  selector: 'ty-genres',
  templateUrl: './genres.component.html',
  styleUrls: ['./genres.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: GenresComponent }]

})
export class GenresComponent extends NavigationComponent implements OnInit, OnDestroy{
  @ViewChild('genres') genres!: ElementRef;
  mediaItems: MediaItem<MediaItemTypes>[];
  isDataReceived: boolean;
  currentUrl: string;
  title: string;
  scrollTop: number;

  constructor(
    private browsingHistoryService: BrowsingHistoryService,
    private containerService: ContainerService,
    private languageService: LanguageService,
    private settingsService: SettingsService,
    public logger: NGXLogger,
    public el: ElementRef

  ) {
    super(el);
    this.isDataReceived = false;
    this.scrollTop = 0;
    this.title = GenresConfiguration.title;
  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    this.settingsService.languageSet$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
        if (focusData?.genres) {
          this.mediaItems = focusData.genres.items;
          this.isDataReceived = true;
        } else {
          this.getGenres();
        }
      },
    });
  }

  ngOnDestroy(): void {
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData) {
      focusData.genres = {
        items: this.mediaItems,
      };
    }
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }


  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.genres.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

  private getGenres() {
    const params: IContainerParams = {
      language: this.languageService.currentLang,
      containerType: ContainerTypes.categoryType,
      categoryType: 2,
      page: 1,
      size: 15,
    };
    this.containerService.getContainerByCategoryType(params).pipe(untilDestroyed(this)).subscribe({
      next: (items: Container[]) => {
        this.isDataReceived = true;
        const collections: MediaItem<Collection>[] = this.filterCollections(items[0]);
        const filteredVideos = this.filterVideos(items[0]);
        this.mediaItems = [...collections, ...filteredVideos];
        this.logger.debug(
          'CollectionsMenuComponent -> GetCollections(): Success',
          items
        );
      },
      error: (error: HttpErrorResponse) => {
        this.logger.error(
          'CollectionsMenuComponent -> GetCollections(): Failed',
          error
        );
      }
    });
  }

  private filterCollections(carousel: Container) {
    return carousel.subContainers
      ?.map((collection) => new Collection(collection))
      ?.filter((collection: Collection) => collection.numOfChapters > 0);
  }

  private filterVideos(container: Container): MediaItem<Video>[] {
    return container?.videos?.filter(
      (video: MediaItem<Video>) => !video.type?.includes(ItemTypes.Advertisement)
    );
  }

}
