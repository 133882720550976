import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export interface IStore<T> {
  getStatus(): T;
  select(selector: (status: T) => any): Observable<T>;
  updateStatus(callback: (status: T) => void): T;
}

export class Store<T> implements IStore<T> {

  private status: T;
  private statusSubject: BehaviorSubject<T>;

  constructor(status: T) {
    this.status = status;
    this.statusSubject = new BehaviorSubject<T>(this.status);
  }

  getStatus(): T {
    return this.status;
  }

  select(selector: (state: T) => any, distinct: boolean = true): Observable<T> {
    return this.statusSubject.asObservable().pipe(
      map((status) => selector(status)),
      distinctUntilChanged((prev, curr) => prev === curr && distinct),
      map(() => this.status)
    );
  }

  updateStatus(callback: (status: T) => void): T {
    callback(this.status);
    this.statusSubject.next(this.status);
    return this.status;
  }

}
