import { Injectable } from '@angular/core';
import { LanguageService } from '@app/core/services/language.service';
import { SearchApiService } from '@app/galgo-api/services';
import { CacheService } from '@app/shared/services/cache.service';
import { Container, IContainer, ISimpleContainer } from '@core/models/container.model';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { ContainerService } from '@core/services/container.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private searchApiService: SearchApiService,
    private containerCustomService: ContainerService,
    private languageService: LanguageService,
    private cacheService: CacheService
  ) {}

  getPopularVideos(): Observable<MediaItem<MediaItemTypes>[]> {
    const cacheKey = this.cacheService.generateCacheKey('getPopularVideos');

    return this.cacheService.getFromCache<MediaItem<MediaItemTypes>[]>(cacheKey).pipe(
      switchMap(cachedResult => {
        if (cachedResult) {
          return of(cachedResult);
        }

        return this.searchApiService.searchControllerFindByRanking({
          language: this.languageService.currentLang,
          version: 2,
        }).pipe(
          map((response: unknown) => {
            const simpleContainer: ISimpleContainer = response as ISimpleContainer;
            const mediaItems = this.containerCustomService.getMediaItemsFromContainer(
              new Container(simpleContainer as IContainer)
            );
            this.cacheService.addToCache(cacheKey, mediaItems);
            return mediaItems;
          })
        );
      })
    );
  }

  searchMediaItem(text: string): Observable<MediaItem<MediaItemTypes>[]> {
    return this.searchApiService
      .searchControllerFind({
        text,
        language: this.languageService.currentLang,
        version: 2,
      })
      .pipe(
        map((response: unknown) => JSON.parse(response as string)),
        map((response: unknown) => {
          const simpleContainer: ISimpleContainer = response as ISimpleContainer;
          return this.containerCustomService.getMediaItemsFromContainer(
            new Container(simpleContainer as IContainer)
          );
        })
      );
  }
}
