import { Injectable } from '@angular/core';
import { NetworkStatus } from '@app/core/models/network-status.enum';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { keyCodesBrowser, keyCodesLG, keyCodesSamsung } from '../config/keycodes.config';
import { IKeyCodes } from '../models/keycodes.interface';
import { platforms } from '../models/platforms.enum';


declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class PlatformService {

  keyCodes: Observable<IKeyCodes>;
  private networkEvents = new ReplaySubject<NetworkStatus>(1);


  private _platform: string;
  get platform(): string {
    return this._platform;
  }
  set platform(value: string) {
    this._platform = value;
  }
  private _subjectKeyCodes$: Subject<IKeyCodes>;

  constructor(
    private logger: NGXLogger
  ) {
    this._platform = platforms.browser;
    this._subjectKeyCodes$ = new Subject();
    this.keyCodes = this._subjectKeyCodes$.asObservable();
  }

  checkPlatform(): Observable<string> {

    let keyCodePlatform: IKeyCodes;

    if (this.isTizen()) {
      this._platform = platforms.tizen;
      keyCodePlatform = keyCodesSamsung;
      window.tizen.tvinputdevice.registerKeyBatch([
        'MediaPlay',
        'MediaPause',
        'MediaPlayPause',
        'MediaStop',
        'MediaRewind',
        'MediaFastForward',
      ]);
    } else if (this.isWebOs()) {
      this._platform = platforms.lg;
      keyCodePlatform = keyCodesLG;
    } else {
      this._platform = platforms.browser;
      keyCodePlatform = keyCodesBrowser;
    }
    this._subjectKeyCodes$.next(keyCodePlatform);
    return of(this._platform);
  }

  exit(): void {
    if (this.isTizen()) {
      window.tizen.application.getCurrentApplication().exit();
    } else if (this.isWebOs()) {
      window.close();
    }
  }

  isWebOs(): boolean {
    this.logger.debug('Detecting webOS platform', window?.webOS?.platform?.tv);
    return window?.webOS?.platform?.tv;
  }

  isTizen(): boolean {
    this.logger.debug('Detecting Tizen platform', window?.tizen);
    return window?.tizen;
  }

  getNetworkEvents(): Observable<NetworkStatus> {
    return this.networkEvents.asObservable();
  }

  configureNetworkStateChanges(): void {
    if (window?.webapis?.network) {
      window.webapis.network.addNetworkStateChangeListener((value: number) => {
        const gatewayStatus = window.webapis.network.isConnectedToGateway();
        if (!gatewayStatus && value === window.webapis.network.NetworkState.GATEWAY_DISCONNECTED) {
          this.networkEvents.next(NetworkStatus.disconnected);
        } else if (value === window.webapis.network.NetworkState.GATEWAY_CONNECTED) {
          this.networkEvents.next(NetworkStatus.connected);
        }
      });
    }
  }



}
