/* tslint:disable */
/* eslint-disable */

/**
 * Status of requested action to industry
 */
export enum StatusRequestedApi {
  Undefined = 'UNDEFINED',
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Revoked = 'REVOKED'
}
