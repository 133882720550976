export enum ContainerTypes {
  default = 'Default',
  season = 'Season',
  serie = 'Serie',
  collection = 'Collection',
  microsite = 'Microsite',
  channel = 'Channel',
  participant = 'Participant',
  categoryType = 'CategoryType',
  container = 'Container',
  artist = 'Artist'
}
