import { AfterViewInit, Component, Input } from '@angular/core';
import { IMenuOption } from '@app/layout/models/menu-option.interface';

@Component({
  selector: 'ty-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements AfterViewInit{

  @Input() currentRouteActive: string;
  @Input() set item(item: IMenuOption) {
    this._item = item;
  }

  get item(): IMenuOption {
    return this._item;
  }
  private _item: IMenuOption;

  get isRouteActive(): boolean {
    return this.currentRouteActive === this.item?.routerLink;
  }


  ngAfterViewInit(): void {
    const iconElement = document.getElementById(this.item?.icon);
    iconElement?.classList.add(this.item.icon);
  }
}
