import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CatalogService } from '@app/auth/services/catalog/catalog.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { HttpRequestMethod } from '../models/http-request-method.enum';
import { LanguageService } from '../services/language.service';

@Injectable()
export class ContentLanguageInterceptor implements HttpInterceptor {
  language: string;

  constructor(
    private languageService: LanguageService,
    private catalogService: CatalogService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.language = this.languageService.currentLang;
    const catalogselected = this.catalogService.getCatalogSelected();
    let isApiUrl = request.url.startsWith(environment.apiUrl);

    if( catalogselected) {
      isApiUrl = request.url.startsWith(catalogselected);
    }

    if (isApiUrl && this.language && !request.headers.has('language')) {
      request = request.clone({
        headers: request.headers.append('language', this.language),
      });
      //request = this.setLanguageQueryParam(request);
    }

    return next.handle(request);
  }

  setLanguageQueryParam(request: HttpRequest<any>) {
    if (request.method === HttpRequestMethod.GET) {
      const params: HttpParams = request.params ? request.params : new HttpParams();

      return request = request.clone({
        params: params.set('language', this.language),
      });
    }

    return request;
  }
}
