<div class="catalog-item">
  <div class="catalog-item-content">
    <div class="catalog-item-image-wrapper">
      <img class="catalog-item-image"
           [src]="getCatalogImage()" alt="{{catalog?.title}}">
    </div>
    <div class="catalog-item-title">
      <p class="catalog-item-title-text">{{catalog?.title}}</p>
    </div>
    <div class="catalog-item-description">
      <p class="catalog-item-description-text">{{catalog?.subtitle}}</p>
    </div>
  </div>
</div>
