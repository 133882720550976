import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { GalgoPlayerModule } from '@app/galgo-player/galgo-player.module';
import { ChaptersModule } from '@app/shared/components/chapters/chapters.module';
import { DetailsTabsModule } from '@app/shared/components/details-tabs/details-tabs.module';
import { LoadingSpinnerModule } from '@app/shared/components/loading-spinner/loading-spinner.module';
import { MoreInformationModule } from '@app/shared/components/more-information/more-information.module';
import { CustomRouterModule } from '@app/shared/components/router/custom-router.module';
import { SimilarModule } from '@app/shared/components/similar/similar.module';
import { BackgroundThumbnailModule } from '@shared/components/background-thumbnail/background-thumbnail.module';
import { ButtonListModule } from '@shared/components/button-list/button-list.module';
import { DetailModule } from '@shared/components/detail/detail.module';
import { ModalModule } from '@shared/components/modal/modal.module';
import { MySpaceButtonModule } from '@shared/components/my-space-button/my-space-button.module';
import { ReturnButtonModule } from '@shared/components/return-button/return-button.module';
import { TabsModule } from '@shared/components/tabs/tabs.module';
import { PpvModalModule } from '../../../shared/components/ppv-modal/ppv-modal.module';
import { MediaDetailRoutingModule } from './media-detail-routing.module';
import { MediaDetailComponent } from './media-detail/media-detail.component';


const ANGULAR_MODULES = [ CommonModule ];
const COMPONENTS_MODULES = [
  BackgroundThumbnailModule,
  ButtonListModule,
  DetailModule,
  GalgoPlayerModule,
  ModalModule,
  MySpaceButtonModule,
  ReturnButtonModule,
  TabsModule,
  CustomRouterModule,
  NavigationModule,
  LoadingSpinnerModule,
  MoreInformationModule,
  SimilarModule,
  DetailsTabsModule,
  ChaptersModule
];
@NgModule({
    declarations: [MediaDetailComponent],
    imports: [
        ...ANGULAR_MODULES,
        ...COMPONENTS_MODULES,
        MediaDetailRoutingModule,
        PpvModalModule
    ]
})
export class MediaDetailModule { }
