import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { LoadingSpinnerModule } from '@app/shared/components/loading-spinner/loading-spinner.module';
import { TagModule } from '@app/shared/components/tag/tag.module';
import { TranslateModule } from '@ngx-translate/core';
import { BackgroundThumbnailModule } from '@shared/components/background-thumbnail/background-thumbnail.module';
import { ButtonListModule } from '@shared/components/button-list/button-list.module';
import { DetailModule } from '@shared/components/detail/detail.module';
import { RibbonModule } from '@shared/components/ribbon/ribbon.module';
import { SubscriptionModule } from '@shared/components/subscription/subscription.module';
import { ViewAggregatorModule } from '@shared/components/view-aggregator/view-aggregator.module';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { HomeBannerComponent } from './components/home-banner/home-banner.component';
import { HomeDetailComponent } from './components/home-detail/home-detail.component';
import { HomeRoutingModule } from './home-page-routing.module';
import { HomePageComponent } from './home/home-page.component';

const ANGULAR_MODULES = [ CommonModule ];
const COMPONENTS_MODULES = [
  BackgroundThumbnailModule,
  ButtonListModule,
  DetailModule,
  ViewAggregatorModule,
  RibbonModule,
  SubscriptionModule,
  LoadingSpinnerModule,
];
const EXTERNAL_MODULES = [ FeatureToggleModule];
const SHARED_MODULES = [ TranslateModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...COMPONENTS_MODULES,
    ...EXTERNAL_MODULES,
    ...SHARED_MODULES,
    HomeRoutingModule,
    NavigationModule,
    TagModule
  ],
  declarations: [
    HomeBannerComponent,
    HomeDetailComponent,
    HomePageComponent
  ]
})
export class HomeModule { }
