import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackgroundThumbnailComponent } from './background-thumbnail.component';

const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [ ...ANGULAR_MODULES ],
  declarations: [ BackgroundThumbnailComponent],
  exports: [ BackgroundThumbnailComponent]
})
export class BackgroundThumbnailModule { }
