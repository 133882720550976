import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IMAGE_FORMAT_ENDPOINTS } from './image-format-endpoints.conf';

export class ImageFormatInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //IF is not an ApiUrl, then we don't need to do anything
    if (!request.url.startsWith(environment.apiUrl)) {
      return next.handle(request);
    }

    if (this.urlInList(request.url)) {
      request = request.clone({
        params: request.params.set('image-format', 'webp')
      });
    }

    return next.handle(request);
  }

  /**
   * It checks if the url of the request it matches any element of the list, strings and RegExps can be used
   *
   * @param url request url
   * @returns whether if url matches at least an element in the list or not
   */

  urlInList(url: string): boolean {
    const urlWithoutBase = url.split(environment.apiUrl).pop();
    return IMAGE_FORMAT_ENDPOINTS.some(ignoredUrl => {
      if (ignoredUrl instanceof RegExp) {
        return ignoredUrl.test(urlWithoutBase);
      }
      return urlWithoutBase === ignoredUrl;
    });
  }
}
