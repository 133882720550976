/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

import { PaidItemsApi } from '../models/paid-items-api';
import { ProductApi } from '../models/product-api';
import { PurchaseOrderApi } from '../models/purchase-order-api';
import { PurchasePaymentSessionApi } from '../models/purchase-payment-session-api';
import { SubscriptionConditionsApi } from '../models/subscription-conditions-api';
import { SubscriptionInfoApi } from '../models/subscription-info-api';

@Injectable({
  providedIn: 'root',
})
export class PurchaseApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation purchasesControllerPurchaseVideo
   */
  static readonly PurchasesControllerPurchaseVideoPath = '/purchase/video/{id}/purchase/{priceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `purchasesControllerPurchaseVideo()` instead.
   *
   * This method doesn't expect any request body.
   */
  purchasesControllerPurchaseVideo$Response(params: {

    /**
     * Video id to be purchased
     */
    id: string;

    /**
     * Price id for the buying
     */
    priceId: string;
  }): Observable<StrictHttpResponse<PurchasePaymentSessionApi>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.PurchasesControllerPurchaseVideoPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('priceId', params.priceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchasePaymentSessionApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `purchasesControllerPurchaseVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  purchasesControllerPurchaseVideo(params: {

    /**
     * Video id to be purchased
     */
    id: string;

    /**
     * Price id for the buying
     */
    priceId: string;
  }): Observable<PurchasePaymentSessionApi> {

    return this.purchasesControllerPurchaseVideo$Response(params).pipe(
      map((r: StrictHttpResponse<PurchasePaymentSessionApi>) => r.body as PurchasePaymentSessionApi)
    );
  }

  /**
   * Path part for operation purchasesControllerCancelVideopurchase
   */
  static readonly PurchasesControllerCancelVideopurchasePath = '/purchase/video/{id}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `purchasesControllerCancelVideopurchase()` instead.
   *
   * This method doesn't expect any request body.
   */
  purchasesControllerCancelVideopurchase$Response(params: {

    /**
     * Video id to be purchased
     */
    id: string;
    Authorization?: string;
  }): Observable<StrictHttpResponse<PaidItemsApi>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.PurchasesControllerCancelVideopurchasePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Authorization', params.Authorization, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaidItemsApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `purchasesControllerCancelVideopurchase$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  purchasesControllerCancelVideopurchase(params: {

    /**
     * Video id to be purchased
     */
    id: string;
    Authorization?: string;
  }): Observable<PaidItemsApi> {

    return this.purchasesControllerCancelVideopurchase$Response(params).pipe(
      map((r: StrictHttpResponse<PaidItemsApi>) => r.body as PaidItemsApi)
    );
  }

  /**
   * Path part for operation subscriptionControllerGetSubscriptions
   */
  static readonly SubscriptionControllerGetSubscriptionsPath = '/subscription';

  /**
   * use /subscription/info instead
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerGetSubscriptions()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  subscriptionControllerGetSubscriptions$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<Array<ProductApi>>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.SubscriptionControllerGetSubscriptionsPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProductApi>>;
      })
    );
  }

  /**
   * use /subscription/info instead
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerGetSubscriptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  subscriptionControllerGetSubscriptions(params?: {
    language?: string;
  }): Observable<Array<ProductApi>> {

    return this.subscriptionControllerGetSubscriptions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProductApi>>) => r.body as Array<ProductApi>)
    );
  }

  /**
   * Path part for operation subscriptionControllerGetSubscriptionsConditions
   */
  static readonly SubscriptionControllerGetSubscriptionsConditionsPath = '/subscription/conditions';

  /**
   * Use /subscription/info instead
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerGetSubscriptionsConditions()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  subscriptionControllerGetSubscriptionsConditions$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<SubscriptionConditionsApi>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.SubscriptionControllerGetSubscriptionsConditionsPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionConditionsApi>;
      })
    );
  }

  /**
   * Use /subscription/info instead
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerGetSubscriptionsConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  subscriptionControllerGetSubscriptionsConditions(params?: {
    language?: string;
  }): Observable<SubscriptionConditionsApi> {

    return this.subscriptionControllerGetSubscriptionsConditions$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionConditionsApi>) => r.body as SubscriptionConditionsApi)
    );
  }

  /**
   * Path part for operation subscriptionControllerGetSubscriptionsInfo
   */
  static readonly SubscriptionControllerGetSubscriptionsInfoPath = '/subscription/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerGetSubscriptionsInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerGetSubscriptionsInfo$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<SubscriptionInfoApi>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.SubscriptionControllerGetSubscriptionsInfoPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionInfoApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerGetSubscriptionsInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerGetSubscriptionsInfo(params?: {
    language?: string;
  }): Observable<SubscriptionInfoApi> {

    return this.subscriptionControllerGetSubscriptionsInfo$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionInfoApi>) => r.body as SubscriptionInfoApi)
    );
  }

  /**
   * Path part for operation subscriptionControllerPurchaseSubscriptionFull
   */
  static readonly SubscriptionControllerPurchaseSubscriptionFullPath = '/purchase/subscription';

  /**
   * Logged user wants to purchase a subscription
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerPurchaseSubscriptionFull()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerPurchaseSubscriptionFull$Response(params: {
    body: PurchaseOrderApi
  }): Observable<StrictHttpResponse<PurchasePaymentSessionApi>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.SubscriptionControllerPurchaseSubscriptionFullPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchasePaymentSessionApi>;
      })
    );
  }

  /**
   * Logged user wants to purchase a subscription
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerPurchaseSubscriptionFull$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerPurchaseSubscriptionFull(params: {
    body: PurchaseOrderApi
  }): Observable<PurchasePaymentSessionApi> {

    return this.subscriptionControllerPurchaseSubscriptionFull$Response(params).pipe(
      map((r: StrictHttpResponse<PurchasePaymentSessionApi>) => r.body as PurchasePaymentSessionApi)
    );
  }

  /**
   * Path part for operation subscriptionControllerPurchaseSubscription
   */
  static readonly SubscriptionControllerPurchaseSubscriptionPath = '/purchase/subscription/{id}';

  /**
   * Logged user wants to purchase a subscription
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerPurchaseSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerPurchaseSubscription$Response(params: {

    /**
     * Subscription Price ID to be purchased
     */
    id: string;
  }): Observable<StrictHttpResponse<PurchasePaymentSessionApi>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.SubscriptionControllerPurchaseSubscriptionPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchasePaymentSessionApi>;
      })
    );
  }

  /**
   * Logged user wants to purchase a subscription
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerPurchaseSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerPurchaseSubscription(params: {

    /**
     * Subscription Price ID to be purchased
     */
    id: string;
  }): Observable<PurchasePaymentSessionApi> {

    return this.subscriptionControllerPurchaseSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<PurchasePaymentSessionApi>) => r.body as PurchasePaymentSessionApi)
    );
  }

  /**
   * Path part for operation subscriptionControllerCancelSubscriptionpurchase
   */
  static readonly SubscriptionControllerCancelSubscriptionpurchasePath = '/purchase/subscription/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerCancelSubscriptionpurchase()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerCancelSubscriptionpurchase$Response(params: {

    /**
     * Subscription id to be removed
     */
    id: string;

    /**
     * Cancel the subscription inmediately. Default &#x3D; false
     */
    inmediate?: boolean;
  }): Observable<StrictHttpResponse<PaidItemsApi>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.SubscriptionControllerCancelSubscriptionpurchasePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('inmediate', params.inmediate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaidItemsApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerCancelSubscriptionpurchase$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerCancelSubscriptionpurchase(params: {

    /**
     * Subscription id to be removed
     */
    id: string;

    /**
     * Cancel the subscription inmediately. Default &#x3D; false
     */
    inmediate?: boolean;
  }): Observable<PaidItemsApi> {

    return this.subscriptionControllerCancelSubscriptionpurchase$Response(params).pipe(
      map((r: StrictHttpResponse<PaidItemsApi>) => r.body as PaidItemsApi)
    );
  }

  /**
   * Path part for operation subscriptionControllerUpgradeSubscription
   */
  static readonly SubscriptionControllerUpgradeSubscriptionPath = '/purchase/subscription/{id}';

  /**
   * Change Subscription to other price
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerUpgradeSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerUpgradeSubscription$Response(params: {

    /**
     * Subscription id to be upgraded
     */
    id: string;

    /**
     * upgrade Price ID
     */
    body: {
'priceId': string;
'coupon'?: string;
}
  }): Observable<StrictHttpResponse<PaidItemsApi>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.SubscriptionControllerUpgradeSubscriptionPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaidItemsApi>;
      })
    );
  }

  /**
   * Change Subscription to other price
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerUpgradeSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerUpgradeSubscription(params: {

    /**
     * Subscription id to be upgraded
     */
    id: string;

    /**
     * upgrade Price ID
     */
    body: {
'priceId': string;
'coupon'?: string;
}
  }): Observable<PaidItemsApi> {

    return this.subscriptionControllerUpgradeSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<PaidItemsApi>) => r.body as PaidItemsApi)
    );
  }

}
