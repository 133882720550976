import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@app/app.config';
import { UserService } from '@app/auth/services/user/user.service';
import { AppConfig } from '@app/models/app.model';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { LocaleData as LangData, getName, registerLocale as registerLanguage } from '@cospired/i18n-iso-languages';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, ReplaySubject, forkJoin, from, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Language } from '../models/language.model';
import { ISettings } from '../models/settings.interface';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  currentSettings: ISettings;
  langsRegistered$: ReplaySubject<boolean>;
  languageSet$: ReplaySubject<void>;

  get userLang(): Language {
    return  this.getUserLangFromLocalStorage();
  }

  set userLang(lang: Language) {
    this.setUserLangInLocalStorage(lang);
  }

  get defaultLang(): string {
    return this.config.defaultLanguage;
  }

  get currentLang(): string {
    return this.userService.currentUserLanguage || this.getLanguageFromLocalStorage() || this.defaultLang;
  }

  set currentLang(value: string) {
    this.translate.currentLang = value;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) private config: AppConfig,
    public logger: NGXLogger,
    private http: HttpClient,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private userService: UserService
    ) {
      this.langsRegistered$ = new ReplaySubject(1);
      this.languageSet$ = new ReplaySubject<void>();
      this.subscribeToLangValues();
    }

  instant(value: string, interpolateParams?: Record<string, unknown>): string {
    return this.translate.instant(value, interpolateParams);
  }

  use(id: string): void {
    const langId = id || this.defaultLang;
    this.translate.use(langId);
  }

  get(value: string[]): Observable<string[]>{
    return this.translate.get(value);
  }

  setLanguage(id: string): void {
    this.setLanguageInLocalStorage(id);
    this.use(id);
    this.currentLang = id;
    this.document.documentElement.lang = id;
    this.languageSet$.next();
  }

  setLanguageInLocalStorage(id: string) {
    localStorage.setItem('language', id);
  }

  getLanguageFromLocalStorage(): string {
    return localStorage.getItem('language');
  }

  removeLanguageFromLocalStorage() {
    localStorage.removeItem('language');
  }

  /**
   * getLanguageName$ returns an Observable that emits the translated name of a language.
   *
   * @param langCodeToTranslate The language code to be translated.
   * @param lang The target language code for the translation.
   * @returns An Observable that emits the translated name of the language.
   *
   * The function waits for all languages to be registered before attempting the translation.
   * If the translation is unavailable, it falls back to `currentLang`.
   *
   * Example:
   * this.myService.getLanguageName$('en', 'es').subscribe(name => console.log(name));
   * Learn more at: {@link https://www.npmjs.com/package/@cospired/i18n-iso-languages}
   * or in documentation: docs\8-others\i18n-iso-languages.md
   */
  getLanguageName$(langCodeToTranslate: string, lang: string): Observable<string> {
    return this.langsRegistered$.pipe(map(() =>getName(langCodeToTranslate, lang)
    ? getName(langCodeToTranslate, lang) : getName(langCodeToTranslate, this.currentLang) ));
  }

  private getUserLangFromLocalStorage(): Language {
    const lang = JSON.parse(localStorage.getItem('userLang'));
    if (lang) {
      return new Language(lang);
    }

    return undefined;
  }

  private setUserLangInLocalStorage(userLang: Language) {
    localStorage.setItem('userLang', userLang.serialize());
  }


  private subscribeToLangValues(): void {
    this.settingsService.currentSettings$
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: ISettings) => {
        this.registerLanguages(res.availableLanguages);
      },
      error => {
        this.logger.error('LanguageService -> subscribeToLangValues: Failed', error);
      });
  }

  private registerLanguages(langs: Language[]): void {
    const obs = langs.map(lang => this.registerLanguage(lang.id));
    forkJoin(obs).pipe(catchError(error => {
        this.logger.error('LanguageService -> registerLanguages: Failed', error);
        return of(null);
    })).subscribe({
        next: () => this.langsRegistered$.next(true),
        error: (err) => this.logger.error('LanguageService -> registerLanguages: Error', err),
    });
}

  private registerLanguage(language: string): Observable<LangData> {
    return from(import('@cospired/i18n-iso-languages/langs/' + language + '.json')).pipe(
      tap((lang: any) => {
        registerLanguage(lang);
      })
    );
  }

}
