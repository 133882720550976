export const PLAYER_CONTROLS = [
  //'airplay', // Airplay (currently Safari only)
  //'captions', // Toggle captions
  'current-time', // The current time of playback
  'duration', // The full duration of the media
  //'fast-forward', // Fast forward by the seek time (default 10 seconds)
  //'fullscreen', // Toggle fullscreen
  //'mute', // Toggle mute
  //'pip', // Picture-in-picture (currently Safari only)
  //'play-large', // The large play button in the center
  //'play', // Play/pause playback
  'progress', // The progress bar and scrubber for playback and buffering
  //'restart', // Restart playback
  //'rewind', // Rewind by the seek time (default 10 seconds)
  //'settings', // Settings menu
  //'volume', // Volume control
];
