import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconItemModule } from '../svg-icon-item/svg-icon-item.module';
import { ChaptersRoutingModule } from './chapters-routing.module';
import { ChaptersComponent } from './chapters.component';
import { ChaptersSelectorComponent } from './components/chapters-selector/chapters-selector.component';
import { SeasonsSelectorComponent } from './components/seasons-selector/seasons-selector.component';

const ANGULAR_MODULES = [CommonModule];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    NavigationModule,
    ChaptersRoutingModule,
    SvgIconItemModule,
    TranslateModule,
    NavigationModule
  ],
  declarations: [
    ChaptersComponent,
    ChaptersSelectorComponent,
    SeasonsSelectorComponent
  ],
  exports: [ChaptersComponent]
})
export class ChaptersModule { }
