import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { QrCodeModuleCustom } from '@shared/components/qr-code/qr-code-custom.module';
import { ReturnButtonModule } from '@shared/components/return-button/return-button.module';
import { SvgIconItemModule } from '@shared/components/svg-icon-item/svg-icon-item.module';
import { ModalReturnButtonComponent } from './components/modal-return-button/modal-return-button.component';
import { ModalComponent } from './modal.component';

@NgModule({
  imports: [
    CommonModule,
    QrCodeModuleCustom,
    ReturnButtonModule,
    SvgIconItemModule,
    TranslateModule,
    NavigationModule,
  ],
  declarations: [
    ModalComponent,
    ModalReturnButtonComponent
  ],
  exports: [
    ModalComponent
  ]
})
export class ModalModule { }
