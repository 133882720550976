import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { MediaThumbModule } from '@shared/components/media-thumb/media-thumb.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { CarouselComponent } from './carousel.component';

const ANGULAR_MODULES = [ CommonModule ];
const SHARED_MODULES = [ MediaThumbModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...SHARED_MODULES,
    NavigationModule,
    VirtualScrollModule,
  ],
  declarations: [ CarouselComponent ],
  exports: [ CarouselComponent ]
})
export class CarouselModule { }
