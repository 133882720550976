<div>
  <img src="./assets/customer/icons/tv-register-icon.png" alt="register-icon">
  <div>
    <p class="register">{{'login.register' | translate}}</p>
    <p class="register-text">{{'login.redirect' | translate}}</p>

    <div class="primary-button box-space" (click)="goTo(appRoutes.login)" appNavigation [navigableId]="0">
      <button class="button-medium">{{'landing.button' | translate}}</button>
    </div>
  </div>
</div>
