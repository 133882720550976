import { Injectable } from '@angular/core';
import { IFeatureToggle } from '@core/models/feature-toggle.interface';
import { environment } from '@environments/environment';
import { set as setFeatureToggle } from 'feature-toggle-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerDataService } from './customer-data.service';

/**
 * Merge environment features and customer features and provide
 * an observable to update all dependencies.
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  /**
   * You can subscribe this observable to respond to changes on feature flags.
   */
  public features: Observable<IFeatureToggle>;
  private featuresSubject: BehaviorSubject<IFeatureToggle>;

  constructor(private customerDataService: CustomerDataService) {
    const features = this.fetchFeatures();
    this.featuresSubject = new BehaviorSubject<IFeatureToggle>(features);
    this.features = this.featuresSubject.asObservable();
  }

  /**
   * Init features for feature-toggle-service library
   */
  initFeatures() {
    setFeatureToggle(this.fetchFeatures());
  }

  /**
   * return the current feature collection flags.
   */
  public get currentFeaturesValue() {
    return this.featuresSubject.value;
  }

  /**
   * Force refreshing of feature flags and send
   * a notification to any subscriber
   */
  public refresh() {
    const features = this.fetchFeatures();
    this.featuresSubject.next(features);
  }

  private fetchFeatures(): IFeatureToggle {
    return {
      ...environment.features,
      ...this.customerDataService.features,
    };
  }
}
