/* tslint:disable */
/* eslint-disable */

/**
 * Pricing Period
 */
export enum PricingPeriodApi {
  Monthly = 'monthly',
  Yearly = 'yearly',
  Halfyearly = 'halfyearly'
}
