import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { CollectionTypes } from '@app/shared/models/collection/collection-types.enum';
import { Video } from '@app/shared/models/video/video.model';
import { ItemTypes } from '@shared/models/item-types.enum';


@Component({
  selector: 'ty-grid-row',
  templateUrl: './grid-row.component.html',
  styleUrls: ['./grid-row.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: GridRowComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridRowComponent extends NavigationComponent {
  @Input() galgoEvent: GalgoEvent;
  @Input() items: MediaItem<MediaItemTypes>[];
  currentUrl: string;
  isLivesGrid: boolean;


  constructor(
    public el: ElementRef,
    private browsingHistoryService: BrowsingHistoryService,
    private featureFlagsService: FeatureFlagsService,
    private settings: SettingsService,
    private analyticsService: AnalyticsService,
    private router: Router
    ) {
    super(el);
    this.items = [];
    this.isLivesGrid = this.browsingHistoryService.currentURL === AppRoutes.lives;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeIndex() < this.getChildNodes().length - 1) {
      this.selectNextChildNode();
      return true;
    }
    return false;
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeIndex() > 0) {
      this.selectPreviousChildNode();
      return true;
    }
    return false;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  navigateToVideoDetails(item: MediaItem<MediaItemTypes>) {
    switch (this.galgoEvent) {
      case GalgoEvent.videoFromDetail:
        this.settings.lastView$ = 'similar-tab';
        break;
      case GalgoEvent.videoFromPopularSearch:
        this.settings.lastView$ = 'search';
        break;
      case GalgoEvent.videoFromSeeMore:
        this.settings.lastView$ = 'see-more';
        break;
      default:
        this.settings.lastView$ = 'my-space';
        break;
    }


    switch (item?.itemType) {
      case ItemTypes.Collection:
        switch (item?.collectionType) {
          case CollectionTypes.microsite:
            this.router.navigate([AppRoutes.microsites, item?.id]);
            break;
          case CollectionTypes.collection:
            this.router.navigate([AppRoutes.collections, item?.id]);
            break;
          case CollectionTypes.serie:
            this.router.navigate([AppRoutes.series, item?.id]);
            break;
          default:
            this.router.navigate([AppRoutes.collections, item?.id]);
            break;
        }
        break;
      case ItemTypes.Video:
        const video = item as unknown as Video;
        this.analyticsService.logEvent(GalgoEvent.videoFromHome, {
          video: item,
        });
        this.router.navigate([item.live ? AppRoutes.player : AppRoutes.video, video?.id]);
        break;
    }

  }
}
