
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenRefreshApi, TokenRefreshResponseApi, UserLoginApi } from '@api/models';
import { AuthApiService } from '@api/services';
import { IAuthToken } from '@app/auth/models/token.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Intermediate service for auth purposes.
 *
 * Firebase analytics events are managed directly here to avoid
 * circular dependencies.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  logout$: Subject<void>;
  private jwtHelper: JwtHelperService;

  private _token: IAuthToken;
  get token(): IAuthToken {
    return this._token || this.getTokenFromLocalStorage();
  }
  set token(value: IAuthToken) {
    this._token = value;
    this.setTokenInLocalStorage(this._token);
  }

  constructor(
    private authApiService: AuthApiService,
    private logger: NGXLogger,
    private router: Router
  ) {
    this.jwtHelper = new JwtHelperService();
    this.logout$ = new Subject<void>();
  }

  login(loginData: UserLoginApi): Observable<IAuthToken> {
    const userLoginRequestBody: UserLoginApi = loginData;
    const params: { body: UserLoginApi } = { body: userLoginRequestBody };

    return this.authApiService.authControllerSignIn(params).pipe(
      map((token: IAuthToken) => {
        this.token = token;
        return token;
      })
    );
  }

  refreshToken(): Observable<TokenRefreshResponseApi> {
    if (!this.token?.refreshToken) {
      return throwError(
        () => new Error('AuthService -> RefreshToken: Refresh token is not defined')
      );
    }
    const refreshToken: TokenRefreshApi = { refreshToken: this.token?.refreshToken };

    return this.authApiService.authControllerRefresh({ body: refreshToken }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.logger.error('AuthService -> refreshToken: Failed', error);
        /*if (error.status === HttpStatusCode.Unauthorized) {
          this.logout();
        }*/
        return throwError(() => new Error(`Auth error: ${error.message}`));
      }),
      map((refreshedToken: TokenRefreshResponseApi) => {
        this.logger.debug('AuthService -> RefreshToken: Token:', refreshedToken);
        const currentToken = this.token;
        currentToken.accessToken = refreshedToken?.accessToken;
        currentToken.accesTokenExpirationDate = refreshedToken?.accessTokenExpirationDate;
        this.token = currentToken;
        return refreshedToken;
      })
    );
  }

  logout() {
    this.logout$.next();
    this.clear();
  }

  private setTokenInLocalStorage(token: IAuthToken) {
    localStorage.setItem('accessToken', JSON.stringify(token));
  }

  private getTokenFromLocalStorage(): IAuthToken {
    const token = localStorage.getItem('accessToken');
    return token
      ? (JSON.parse(localStorage.getItem('accessToken')) as IAuthToken)
      : undefined;
  }

  private removeTokenFromLocalStorage(): void {
    localStorage.removeItem('accessToken');
  }

  private clear() {
    this._token = undefined;
    this.removeTokenFromLocalStorage();
  }
}
