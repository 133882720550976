import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { CarouselModule } from '@shared/components/carousel/carousel.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { ViewAggregatorComponent } from './view-aggregator.component';

const ANGULAR_MODULES = [ CommonModule ];
const SHARED_MODULES = [ CarouselModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...SHARED_MODULES,
    NavigationModule,
    VirtualScrollModule,
  ],
  declarations: [ ViewAggregatorComponent ],
  exports: [ ViewAggregatorComponent ]
})
export class ViewAggregatorModule { }
