/* tslint:disable */
/* eslint-disable */

/**
 * DOGLogo position
 */
export enum DogLogoPositionApi {
  TopLeft = 'TOP_LEFT',
  TopRight = 'TOP_RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT'
}
