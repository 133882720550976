import { IKeyboardEvent } from '@shared/models/keyboard-event.interface';
import { KeyboardAction } from './keyboard-actions.enum';

export const KeyboardKeys: IKeyboardEvent[] = [
    { content: 'a', action: KeyboardAction.Add },
    { content: 'b', action: KeyboardAction.Add },
    { content: 'c', action: KeyboardAction.Add },
    { content: 'd', action: KeyboardAction.Add },
    { content: 'e', action: KeyboardAction.Add },
    { content: 'f', action: KeyboardAction.Add },
    { content: 'g', action: KeyboardAction.Add },
    { content: 'h', action: KeyboardAction.Add },
    { content: 'i', action: KeyboardAction.Add },
    { content: 'j', action: KeyboardAction.Add },
    { content: 'k', action: KeyboardAction.Add },
    { content: 'l', action: KeyboardAction.Add },
    { content: 'm', action: KeyboardAction.Add },
    { content: 'n', action: KeyboardAction.Add },
    { content: 'o', action: KeyboardAction.Add },
    { content: 'p', action: KeyboardAction.Add },
    { content: 'q', action: KeyboardAction.Add },
    { content: 'r', action: KeyboardAction.Add },
    { content: 's', action: KeyboardAction.Add },
    { content: 't', action: KeyboardAction.Add },
    { content: 'u', action: KeyboardAction.Add },
    { content: 'v', action: KeyboardAction.Add },
    { content: 'w', action: KeyboardAction.Add },
    { content: 'x', action: KeyboardAction.Add },
    { content: 'y', action: KeyboardAction.Add },
    { content: 'z', action: KeyboardAction.Add },
    { content: '1', action: KeyboardAction.Add },
    { content: '2', action: KeyboardAction.Add },
    { content: '3', action: KeyboardAction.Add },
    { content: '4', action: KeyboardAction.Add },
    { content: '5', action: KeyboardAction.Add },
    { content: '6', action: KeyboardAction.Add },
    { content: '7', action: KeyboardAction.Add },
    { content: '8', action: KeyboardAction.Add },
    { content: '9', action: KeyboardAction.Add },
    { content: '0', action: KeyboardAction.Add },
];

export const BackSpaceKey: IKeyboardEvent = {
  content: 'delete', action: KeyboardAction.Delete
};

export const SpaceKey: IKeyboardEvent = {
  content: ' ', action: KeyboardAction.Add
};

export const SearchKey: IKeyboardEvent = {
  content: 'search', action: KeyboardAction.Search
};
