/* tslint:disable */
/* eslint-disable */

/**
 * status of the transaltion
 */
export enum TranslationStatusApi {
  Pending = 'pending',
  Translated = 'translated',
  Error = 'error',
  Noop = 'noop'
}
