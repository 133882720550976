import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { NavigationComponent } from './navigation.component';
import { NavigationDirective } from './navigation.directive';


@NgModule({
  declarations: [
    NavigationComponent,
    NavigationDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NavigationComponent,
    NavigationDirective
  ]
})
export class NavigationModule { }
