import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { CustomSeekbarModule } from '../custom-seekbar/custom-seekbar.module';
import { SeekbarComponent } from './seekbar.component';

const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
    declarations: [SeekbarComponent],
    exports: [SeekbarComponent],
    imports: [...ANGULAR_MODULES, CustomSeekbarModule, NavigationModule]
})
export class SeekbarModule { }
