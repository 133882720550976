import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { TabContentSelector } from '@shared/components/tabs/models/tab-label.enum';
import { ITab } from '@shared/components/tabs/models/tabs.interface';
import { TabsService } from '@shared/components/tabs/services/tabs.service';


@Component({
  selector: 'ty-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: TabsComponent }]
})
export class TabsComponent extends NavigationComponent {

  @Input() videoId: string;
  @Output() closeTabs?: EventEmitter<string>;
  @Output() selectedTab?: EventEmitter<ITab>;
  isModalOpened: boolean;
  private _tabs: ITab[];

  get tabs(): ITab[] {
    return this._tabs;
  }
  constructor(
    private tabsService: TabsService,
    private settings: SettingsService,
    public el: ElementRef,
    private featureFlagsService: FeatureFlagsService,

  ) {
    super(el);
    this.closeTabs = new EventEmitter<string>();
    this.selectedTab = new EventEmitter<ITab>();
    this._tabs = this.tabsService.tabs;
    this.isModalOpened = false;
    const detailsTab = this.featureFlagsService.currentFeaturesValue.detailsTab;
    if(detailsTab){
      this._tabs = this._tabs.filter(tab => tab.label !== 'details.more-information');
    }else{
      this._tabs = this._tabs.filter(tab => tab.label !== 'details.details');
    }
  }

   onRightKey(): boolean {
    if (this.getSelectedChildNodeId() < this.tabs.length-1) {
      this.selectNextChildNode();
    }
     return true;
   }

   onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() > 0) {
      this.selectPreviousChildNode();
      return true;
    }
    return false;
   }

   onDownKey(): boolean {
    const tab: ITab = this.tabsService.getSelectedTab();
    if (tab.label === 'details.more-information') {
      return true;
    }
    return false;
   }

   onUpKey(): boolean {
    this.closeTabs.emit();
    this.settings.lastView$='';
    return true;
   }

  selectTab(selectedTabSelector: TabContentSelector) {
    const selectedTab = this.tabsService.selectTab(selectedTabSelector);
    this.selectedTab.emit(selectedTab);

  }
}
