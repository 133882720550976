import { ActionList } from '@core/models/action-list.enum';
import { IButtonConfig } from '@shared/components/button-list/button-config.interface';
import { IDetailConfig } from '@shared/components/detail/detail-config';

export const homeBannerBtnListConfig: IButtonConfig[] = [
    {
        text: 'home.info',
        action: ActionList.navigateToDetails,
        active: true,
        disable: true,
        initialPosition: true
    },
];

export const homeBannerDetailConfig: IDetailConfig =
{
    showDescription: false
};
