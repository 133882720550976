<div id="home">
  <div class="home-banner-section">
    <ty-home-banner [hidden]="showThumbnailInformation"
                    [isHidden]="isHidden"
                    [videos]="featuredMediaItems"
                    [navigableId]="0"
                    [navigable]="featuredMediaItems && featuredMediaItems.length > 0"></ty-home-banner>
    <ty-home-detail [hidden]="!showThumbnailInformation"
                    [selectedContent]="selectedItem"></ty-home-detail>
  </div>
  <div class="carousels-wrapper">
    <div>
      <ty-view-aggregator *ngIf="carousels.length"
                          [data]="carousels"
                          (selectedItem)="setSelectedItem($event)"
                          [navigableId]="1"></ty-view-aggregator>
    </div>
  </div>
</div>