import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { MarkdownModule } from 'ngx-markdown';
import { DetailsDescriptionsComponent } from './components/details-descriptions/details-descriptions.component';
import { DetailsInfoComponent } from './components/details-info/details-info.component';
import { DetailsTabsRoutingModule } from './details-tabs-routing.module';
import { DetailsTabsComponent } from './details-tabs.component';

@NgModule({
  declarations: [
    DetailsTabsComponent,
    DetailsInfoComponent,
    DetailsDescriptionsComponent
  ],
  imports: [
    NavigationModule,
    CommonModule,
    PipesModule,
    TranslateModule,
    DetailsTabsRoutingModule,
    MarkdownModule,
  ],
  exports: [DetailsTabsComponent]
})
export class DetailsTabsModule { }
