import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';

@Injectable({
  providedIn: 'root'
})
export class SkipLandingGuard implements CanActivate {

  constructor(
    private router: Router,
    private featureFlagsService: FeatureFlagsService,
  ) { }
  canActivate(): boolean {

      if(this.featureFlagsService.currentFeaturesValue.skipLanding){
        this.router.navigate(['pages/home']);
        return false;
      }

    return true;
  }

}
