import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { LogoModule } from '@shared/components/logo/logo.module';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';


const ANGULAR_MODULES = [ CommonModule, RouterModule ];
const SHARED_MODULES = [ LogoModule ];

@NgModule({
  declarations: [
    SideMenuComponent,
    MenuItemComponent
  ],
  imports: [
    ... ANGULAR_MODULES,
    ... SHARED_MODULES,
    TranslateModule,
    NavigationModule
  ],
  exports: [
    ... ANGULAR_MODULES,
    SideMenuComponent
  ]
})
export class LayoutModule { }
