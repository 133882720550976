export enum GalgoEvent {
  // Video
  startVideo = 'start_video',
  halfVideo = 'half_video',
  finishVideo = 'finish_video',
  videoDetail = 'video_detail',
  videoFromDetail = 'video_from_detail',
  videoFromHighlights = 'video_from_highlights',
  videoFromSearch = 'video_from_search',
  videoFromPopularSearch = 'video_from_popular_search',
  videoFromFavorites = 'video_from_favorites',
  videoFromRecommendations = 'video_from_recommendations',
  videoFromSeeMore = 'video_from_see_more',
  videoFromContainer = 'video_from_container_detail', // TO DO: when access from collection detail page
  videoFromHome = 'video_from_home',
  containerDetail = 'container_detail', // TO DO: when access to collection detail page
  beat = 'beat',

  // User
  newSession = 'new_session',
  userLogin = 'user_login',

  // Purchases
  purchaseRequired = 'purchase_required',

  // Trailers
  halfTrailer = 'half_trailer',
  finishTrailer = 'finish_trailer',
  startTrailer = 'start_trailer',

  // Various
  addToFavorites = 'add_to_favorites',
  search = 'search',
  collectionToFavorites = 'collection_to_favorites',
}

export enum GalgoEventType {
  error = 'error',
  other = 'other',
  purchase = 'purchase',
  trailer = 'trailer',
  user = 'user',
  video = 'video',
}

export interface IGalgoEvent {
  type: GalgoEventType;
  events: GalgoEvent[];
}


export const videoEvents: GalgoEvent[] = [
  GalgoEvent.startVideo,
  GalgoEvent.halfVideo,
  GalgoEvent.finishVideo,
  GalgoEvent.videoDetail,
  GalgoEvent.videoFromDetail,
  GalgoEvent.videoFromHighlights,
  GalgoEvent.videoFromSearch,
  GalgoEvent.videoFromPopularSearch,
  GalgoEvent.videoFromFavorites,
  GalgoEvent.videoFromRecommendations,
  GalgoEvent.videoFromContainer,
  GalgoEvent.videoFromHome,
  GalgoEvent.containerDetail,
  GalgoEvent.beat
];

export const userEvents: GalgoEvent[] = [
  GalgoEvent.newSession,
  GalgoEvent.userLogin
];
export const purchaseEvents: GalgoEvent[] = [
  GalgoEvent.purchaseRequired
];

export const otherEvents: GalgoEvent[] = [
  GalgoEvent.addToFavorites,
  GalgoEvent.search,
  GalgoEvent.collectionToFavorites,
];
