import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { liveTag, ppvTag, suscriptionTag } from '@app/shared/components/media-thumb/media-thumb.config';
import { ITag } from '@app/shared/models/tag.interface';
import { IFeatureToggle } from '@core/models/feature-toggle.interface';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { IButtonConfig } from '@shared/components/button-list/button-config.interface';
import { IDetailConfig } from '@shared/components/detail/detail-config';
import { Video } from '@shared/models/video/video.model';
import { Observable } from 'rxjs';
import { homeBannerBtnListConfig, homeBannerDetailConfig } from './home-banner.config';

@Component({
  selector: 'ty-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: HomeBannerComponent }],
})
export class HomeBannerComponent extends NavigationComponent implements OnInit, OnDestroy  {
  @ViewChild('swiper') swiper!: ElementRef;
  @Input() isHidden: boolean;
  buttonList: IButtonConfig[];
  buttonListSuscription: IButtonConfig[];
  currentUrl: string;
  featureToggleData: Observable<IFeatureToggle>;
  homeBannerDetailConfig: IDetailConfig;
  scrollLeft: number;
  next: number;

  private _videos: MediaItem<MediaItemTypes>[];


  get videos(): MediaItem<MediaItemTypes>[]{
    return this._videos;
  }

  @Input() set videos(videos: MediaItem<MediaItemTypes>[]) {
    this._videos = videos;
    this.setConfig();
  }

  get tagTypeSubscription(): ITag {
    return suscriptionTag;
  }

  get tagTypePpv(): ITag {
    return ppvTag;
  }

  get tagTypeLive(): ITag {
    return liveTag;
  }

  get defaultBanner(): string {
    return this.customerDataService.defaultBanner;
  }

  constructor(
    public el: ElementRef,
    private analyticsService: AnalyticsService,
    private browsingHistoryService: BrowsingHistoryService,
    private customerDataService: CustomerDataService,
    private featureFlagsService: FeatureFlagsService,
    private router: Router,
    private settings: SettingsService
    ) {
      super(el);
    }

  ngOnInit(): void {
    this.featureToggleData = this.featureFlagsService.features;
    this.currentUrl = this.router.url;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    this.setConfig();
    if (focusData?.heroBanner?.firstSelectedChildNodeId !== undefined && focusData?.heroBanner?.firstSelectedChildNodeId !== 0){
       const selectedChildNodeId = focusData?.heroBanner?.firstSelectedChildNodeId;
       this.selectChildNodeById(selectedChildNodeId - 1);
       this.browsingHistoryService.removeAllFocusData();
    }

    this.nextVideo(1);

  }

  ngOnDestroy(): void {
    const focusData = this.browsingHistoryService.getFocusData(AppRoutes.home);
    if (focusData && !this.isHidden) {
      focusData.heroBanner = {
        firstSelectedChildNodeId: this.getSelectedChildNodeId()
      };
    }
  }

  updateScroll(): void {
    clearInterval(this.next);
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollLeft = focused.getLeafOffsetLeft();
      if (scrollLeft !== this.scrollLeft) {
        this.scrollLeft = scrollLeft;
        this.swiper.nativeElement.style.transform = `translateX(-${this.scrollLeft}px)`;
      }
    }
    this.nextVideo(1000);
  }

  nextVideo(milisec: number): void {
    const heroBannerSeconds = this.videos ? this.videos[this.getSelectedChildNodeId()]?.heroBannerSeconds || 5 : 5;

    this.next = setInterval(() => {
      const isLastChild = this.getSelectedChildNodeId() === this.getChildNodes().length - 1;

      this.selectChildNodeById(isLastChild ? 0 : this.getSelectedChildNodeId() + 1);
      this.updateScroll();
    }, heroBannerSeconds * milisec);
  }

  onDownKey(): boolean {
    return false;
  }

  onLeftKey(): boolean {
    if(this.getSelectedChildNodeId() === 0) {
      return false;
    }
    this.selectPreviousChildNode();
    this.updateScroll();
    return true;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeId() === this.getChildNodes().length - 1) {
      this.selectChildNodeById(0);
    }else{
      this.selectNextChildNode();
    }
    this.updateScroll();
    return true;
  }

  onEnterKey(): boolean {
    this.analyticsService.logEvent(GalgoEvent.videoFromHighlights, { video: this._videos[this.getSelectedChildNodeId()] });
    this.settings.lastView$ = 'home';
    this.router.navigate([AppRoutes.video, this._videos[this.getSelectedChildNodeId()]?.id]);
    return true;
  }

  getButtonInfo(): void {
    this.analyticsService.logEvent(GalgoEvent.videoFromHighlights, { video: this._videos[this.getSelectedChildNodeId()] });
    this.settings.lastView$ = 'home';
    this.router.navigate([AppRoutes.video, this._videos[this.getSelectedChildNodeId()]?.id]);
  }

  getSubscriptionName(video: MediaItem<Video>): string {
    return video?.layout?.subscription?.name?.value;
  }

  setConfig(): void {
    this.homeBannerDetailConfig = homeBannerDetailConfig;
    this.buttonList = homeBannerBtnListConfig;
  }

  isPpv(video: MediaItem<Video>): boolean {
    return !!video?.layout?.ppv?.name?.value;
  }

  isLive(video: MediaItem<Video>): boolean {
    return !!video?.live;
  }
}
