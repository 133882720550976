import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PegiComponent } from './pegi.component';

const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [ ...ANGULAR_MODULES ],
  declarations: [ PegiComponent ],
  exports: [ PegiComponent ]
})
export class PegiModule { }
