/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

import { VideoFrontApi } from '../models';
import { ContainerSimpleApi } from '../models/container-simple-api';
import { FavoritesFrontApi } from '../models/favorites-front-api';
import { PaidItemsResponseApi } from '../models/paid-items-response-api';
import { StripeCustomerInfoApi } from '../models/stripe-customer-info-api';
import { UserCompanyInfoApi } from '../models/user-company-info-api';
import { UserFrontApi } from '../models/user-front-api';

@Injectable({
  providedIn: 'root',
})
export class MeApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation meControllerGetMe
   */
  static readonly MeControllerGetMePath = '/me';

  /**
   * Get authenticated user data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetMe$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<UserFrontApi>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerGetMePath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserFrontApi>;
      })
    );
  }

  /**
   * Get authenticated user data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetMe(params?: {
    language?: string;
  }): Observable<UserFrontApi> {

    return this.meControllerGetMe$Response(params).pipe(
      map((r: StrictHttpResponse<UserFrontApi>) => r.body as UserFrontApi)
    );
  }

  /**
   * Path part for operation meControllerRemoveUser
   */
  static readonly MeControllerRemoveUserPath = '/me';

  /**
   * Removes logged user from DB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerRemoveUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerRemoveUser$Response(params?: {
  }): Observable<StrictHttpResponse<UserFrontApi>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerRemoveUserPath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserFrontApi>;
      })
    );
  }

  /**
   * Removes logged user from DB
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerRemoveUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerRemoveUser(params?: {
  }): Observable<UserFrontApi> {

    return this.meControllerRemoveUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserFrontApi>) => r.body as UserFrontApi)
    );
  }

  /**
   * Path part for operation meControllerSetData
   */
  static readonly MeControllerSetDataPath = '/me';

  /**
   * Update authenticated user data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerSetData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  meControllerSetData$Response(params: {
    language?: string;
    body: UserFrontApi
  }): Observable<StrictHttpResponse<UserFrontApi>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerSetDataPath, 'patch');
    if (params) {
      rb.header('language', params.language, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserFrontApi>;
      })
    );
  }

  /**
   * Update authenticated user data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerSetData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  meControllerSetData(params: {
    language?: string;
    body: UserFrontApi
  }): Observable<UserFrontApi> {

    return this.meControllerSetData$Response(params).pipe(
      map((r: StrictHttpResponse<UserFrontApi>) => r.body as UserFrontApi)
    );
  }

  /**
   * Path part for operation meControllerGetStripeCustomerInfo
   */
  static readonly MeControllerGetStripeCustomerInfoPath = '/me/stripe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetStripeCustomerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetStripeCustomerInfo$Response(params?: {
  }): Observable<StrictHttpResponse<StripeCustomerInfoApi>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerGetStripeCustomerInfoPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StripeCustomerInfoApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetStripeCustomerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetStripeCustomerInfo(params?: {
  }): Observable<StripeCustomerInfoApi> {

    return this.meControllerGetStripeCustomerInfo$Response(params).pipe(
      map((r: StrictHttpResponse<StripeCustomerInfoApi>) => r.body as StripeCustomerInfoApi)
    );
  }

  /**
   * Path part for operation meControllerAddPlaying
   */
  static readonly MeControllerAddPlayingPath = '/me/playing/{videoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerAddPlaying()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerAddPlaying$Response(params: {

    /**
     * Video id to be added to my playing videos
     */
    videoId: string;

    /**
     * Watched video time in seconds
     */
    time: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerAddPlayingPath, 'post');
    if (params) {
      rb.path('videoId', params.videoId, {});
      rb.query('time', params.time, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerAddPlaying$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerAddPlaying(params: {

    /**
     * Video id to be added to my playing videos
     */
    videoId: string;

    /**
     * Watched video time in seconds
     */
    time: number;
  }): Observable<void> {

    return this.meControllerAddPlaying$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation meControllerRemovePlaying
   */
  static readonly MeControllerRemovePlayingPath = '/me/playing/{videoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerRemovePlaying()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerRemovePlaying$Response(params: {

    /**
     * Video id to be removed (finished to true) from my playing videos
     */
    videoId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerRemovePlayingPath, 'delete');
    if (params) {
      rb.path('videoId', params.videoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerRemovePlaying$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerRemovePlaying(params: {

    /**
     * Video id to be removed (finished to true) from my playing videos
     */
    videoId: string;
  }): Observable<void> {

    return this.meControllerRemovePlaying$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation meControllerGetPlayings
   */
  static readonly MeControllerGetPlayingsPath = '/me/playing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetPlayings()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetPlayings$Response(params: {

    /**
     * If true, returns the complete list, including finished videos
     */
    complete?: boolean;
    language: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<StrictHttpResponse<ContainerSimpleApi>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerGetPlayingsPath, 'get');
    if (params) {
      rb.query('complete', params.complete, {});
      rb.header('language', params.language, {});
      rb.query('size', params.size, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContainerSimpleApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetPlayings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetPlayings(params: {

    /**
     * If true, returns the complete list, including finished videos
     */
    complete?: boolean;
    language: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<ContainerSimpleApi> {

    return this.meControllerGetPlayings$Response(params).pipe(
      map((r: StrictHttpResponse<ContainerSimpleApi>) => r.body as ContainerSimpleApi)
    );
  }

  /**
   * Path part for operation meControllerGetPlayingVideos
   */
  static readonly MeControllerGetPlayingVideosPath = '/me/playing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetPlayingVideos()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  meControllerGetPlayingVideos$Response(params: {
    language: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;

    /**
     * Video Ids array
     */
    body: Array<string>
  }): Observable<StrictHttpResponse<Array<VideoFrontApi>>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerGetPlayingVideosPath, 'post');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('size', params.size, {});
      rb.query('page', params.page, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VideoFrontApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetPlayingVideos$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  meControllerGetPlayingVideos(params: {
    language: string;

    /**
     * Page size. Minimum value: 1, Default: 15
     */
    size?: number;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;

    /**
     * Video Ids array
     */
    body: Array<string>
  }): Observable<Array<VideoFrontApi>> {

    return this.meControllerGetPlayingVideos$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VideoFrontApi>>) => r.body as Array<VideoFrontApi>)
    );
  }

  /**
   * Path part for operation meControllerAddFavorite
   */
  static readonly MeControllerAddFavoritePath = '/me/favorite/{videoId}';

  /**
   * Use POST /favorites instead
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerAddFavorite()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  meControllerAddFavorite$Response(params: {

    /**
     * Video id to be added to my favorites
     */
    videoId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerAddFavoritePath, 'post');
    if (params) {
      rb.path('videoId', params.videoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Use POST /favorites instead
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerAddFavorite$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  meControllerAddFavorite(params: {

    /**
     * Video id to be added to my favorites
     */
    videoId: string;
  }): Observable<void> {

    return this.meControllerAddFavorite$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation meControllerRemoveFavorite
   */
  static readonly MeControllerRemoveFavoritePath = '/me/favorite/{videoId}';

  /**
   * Use DELETE /favorites instead
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerRemoveFavorite()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  meControllerRemoveFavorite$Response(params: {

    /**
     * Video id to be removed from my favorites
     */
    videoId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerRemoveFavoritePath, 'delete');
    if (params) {
      rb.path('videoId', params.videoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Use DELETE /favorites instead
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerRemoveFavorite$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  meControllerRemoveFavorite(params: {

    /**
     * Video id to be removed from my favorites
     */
    videoId: string;
  }): Observable<void> {

    return this.meControllerRemoveFavorite$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation meControllerGetFavorites
   */
  static readonly MeControllerGetFavoritesPath = '/me/favorites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetFavorites()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetFavorites$Response(params: {
    language: string;
  }): Observable<StrictHttpResponse<FavoritesFrontApi>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerGetFavoritesPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FavoritesFrontApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetFavorites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetFavorites(params: {
    language: string;
  }): Observable<FavoritesFrontApi> {

    return this.meControllerGetFavorites$Response(params).pipe(
      map((r: StrictHttpResponse<FavoritesFrontApi>) => r.body as FavoritesFrontApi)
    );
  }

  /**
   * Path part for operation meControllerAddFavoriteGeneric
   */
  static readonly MeControllerAddFavoriteGenericPath = '/me/favorites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerAddFavoriteGeneric()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerAddFavoriteGeneric$Response(params: {

    /**
     * Video id to be added to my favorites
     */
    elementId: string;

    /**
     * Favorite type
     */
    favoriteType: 'video' | 'container';
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerAddFavoriteGenericPath, 'post');
    if (params) {
      rb.query('elementId', params.elementId, {});
      rb.query('favoriteType', params.favoriteType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerAddFavoriteGeneric$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerAddFavoriteGeneric(params: {

    /**
     * Video id to be added to my favorites
     */
    elementId: string;

    /**
     * Favorite type
     */
    favoriteType: 'video' | 'container';
  }): Observable<void> {

    return this.meControllerAddFavoriteGeneric$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation meControllerRemoveFavoriteGeneric
   */
  static readonly MeControllerRemoveFavoriteGenericPath = '/me/favorites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerRemoveFavoriteGeneric()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerRemoveFavoriteGeneric$Response(params: {

    /**
     * Element id to be removed from my favorites
     */
    elementId: string;

    /**
     * Favorite type
     */
    favoriteType: 'video' | 'container';
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerRemoveFavoriteGenericPath, 'delete');
    if (params) {
      rb.query('elementId', params.elementId, {});
      rb.query('favoriteType', params.favoriteType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerRemoveFavoriteGeneric$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerRemoveFavoriteGeneric(params: {

    /**
     * Element id to be removed from my favorites
     */
    elementId: string;

    /**
     * Favorite type
     */
    favoriteType: 'video' | 'container';
  }): Observable<void> {

    return this.meControllerRemoveFavoriteGeneric$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation meControllerGetProducts
   */
  static readonly MeControllerGetProductsPath = '/me/paid-items';

  /**
   * Get current user bought or rented videos
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetProducts()` instead.
   *
   * This method doesn't expect any request body.
   */

  //changed PaidItemsResponseApi to PaidItemsResponseApi[] , manual override by error in swagger
  meControllerGetProducts$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<PaidItemsResponseApi[]>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.MeControllerGetProductsPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaidItemsResponseApi[]>;
      })
    );
  }

  /**
   * Get current user bought or rented videos
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetProducts(params?: {
    language?: string;
  }): Observable<PaidItemsResponseApi[]> {

    return this.meControllerGetProducts$Response(params).pipe(
      map((r: StrictHttpResponse<PaidItemsResponseApi[]>) => r.body as PaidItemsResponseApi[])
    );
  }

  /**
   * Path part for operation icaaMeControllerFindByUserId
   */
  static readonly IcaaMeControllerFindByUserIdPath = '/me/company-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `icaaMeControllerFindByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  icaaMeControllerFindByUserId$Response(params?: {
  }): Observable<StrictHttpResponse<UserCompanyInfoApi>> {

    const rb = new RequestBuilder(this.rootUrl, MeApiService.IcaaMeControllerFindByUserIdPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserCompanyInfoApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `icaaMeControllerFindByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  icaaMeControllerFindByUserId(params?: {
  }): Observable<UserCompanyInfoApi> {

    return this.icaaMeControllerFindByUserId$Response(params).pipe(
      map((r: StrictHttpResponse<UserCompanyInfoApi>) => r.body as UserCompanyInfoApi)
    );
  }

}
