import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/services/auth-custom/auth.service';
import { CatalogService } from '@app/auth/services/catalog/catalog.service';
import { LanguageService } from '@app/core/services/language.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private languageService: LanguageService,
    private catalogService: CatalogService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with JWT
    const currentToken = this.authService?.token?.accessToken;
    const hasToken = !!currentToken;
    const catalogselected = this.catalogService.getCatalogSelected();
    let isApiUrl = request.url.startsWith(environment.apiUrl);

    if( catalogselected) {
      isApiUrl = request.url.startsWith(catalogselected);
    }
    if (isApiUrl) {
      request = request.clone({
      setParams:
        {
          language: this.languageService.currentLang,
        }
      });
    }

    if (isApiUrl && hasToken) {
      request = request.clone({
      setHeaders: {
        Authorization: `${environment.tokenType} ${currentToken}`,
        }
      });
    }

    return next.handle(request);
  }
}
