import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomRouterModule } from '../router/custom-router.module';
import { TabsRoutingModule } from './tabs-routing.module';
import { TabsComponent } from './tabs/tabs.component';

@NgModule({
  declarations: [
    TabsComponent
  ],
  imports: [
    CommonModule,
    TabsRoutingModule,
    NavigationModule,
    TranslateModule,
    CustomRouterModule,
    NavigationModule
  ],
  exports: [
    TabsComponent
  ]
})
export class TabsModule { }
