import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ErrorResponseApi } from '@api/models';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { SetFocusInSimilarGrid } from '@app/pages/protected/media-detail/configuration/tabs.config';
import { CollectionDetailService } from '@app/pages/protected/media-detail/services/collection-detail.service';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { Container } from '@core/models/container.model';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ItemTypes } from '@shared/models/item-types.enum';
import { NGXLogger } from 'ngx-logger';
import { GridComponent } from '../grid/grid.component';
import { TabContentSelector } from '../tabs/models/tab-label.enum';

@Component({
  selector: 'ty-similar',
  templateUrl: './similar.component.html',
  styleUrls: ['./similar.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: SimilarComponent }],
})
@UntilDestroy()
export class SimilarComponent extends NavigationComponent implements OnInit, OnDestroy {
  @ViewChild('similar') similar!: GridComponent;

  currentUrl: string;
  contentId: string;
  galgoEvent: GalgoEvent = GalgoEvent.videoFromRecommendations;
  isLoading: boolean;
  mediaItems: MediaItem<MediaItemTypes>[];
  relatedContainer: Container;
  scrollTop: number;
  similarTab: boolean;

  get setFocusInGrid(): boolean {
    return SetFocusInSimilarGrid;
  }

  constructor(
    public el: ElementRef,
    public logger: NGXLogger,
    private browsingHistoryService: BrowsingHistoryService,
    private collectionDetailService: CollectionDetailService,
    private featureFlagsService: FeatureFlagsService,
    private route: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
    private videoDetailService: VideoDetailService
  ) {
    super(el);
    this.scrollTop = 0;
    this.currentUrl = '';
    this.similarTab = false;
  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData?.similar) {
      this.mediaItems = focusData.similar.items;
    } else {
      this.getContentId();
    }

    this.settings.lastView$ = TabContentSelector.similar;
  }

  ngOnDestroy(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData) {
      focusData.similar = {
        items: this.mediaItems,
      };
      focusData.visitedTab = {
        chaptersTab: false,
        similarTab: true
      };
    }
  }

  cleanUrl(): void {
    const lastIndex = this.currentUrl.lastIndexOf('/');
    if (lastIndex >= 0) {
      this.currentUrl = this.currentUrl.substring(0, lastIndex);
    }
  }

  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.similar.el.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

  private getContentId() {
    this.route.params.subscribe(
      (params: Params) => {
        this.contentId = params.id;
        // TODO: refactor. Same function in MoreInformationComponent
        switch (this.router.url.split('/')[2]) {
          case 'series':
            this.getCollectionSimilarContent();
            break;
          case 'collections':
            this.getCollectionContent();
            break;
          case 'video':
            this.getVideoSimilarContent();
            break;
          case 'microsites':
            this.getCollectionContent();
            break;
        }

        this.logger.debug(
          'SimilarComponent -> GetContentId(): Success',
          params
        );
      },
      (error: ErrorResponseApi) => {
        this.logger.error('SimilarComponent -> GetContentId(): Failed', error);
        this.router.navigate([AppRoutes.forbidden]);
      }
    );
  }

  private getCollectionSimilarContent() {
    this.collectionDetailService
      .getSimilarContent(this.contentId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: MediaItem<MediaItemTypes>[]) => {
          this.logger.debug('SimilarComponent -> GetSimilarContent: Success');
          this.mediaItems = response.filter(
            (mediaItem: MediaItem<MediaItemTypes>) =>
              mediaItem.type !== ItemTypes.Collection
          );
        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            'SimilarComponent -> GetSimilarContent: Failed',
            this.contentId,
            error
          );
        }
      );
  }

  private getVideoSimilarContent() {
    this.videoDetailService
      .getSimilarContent(this.contentId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: MediaItem<MediaItemTypes>[]) => {
          this.logger.debug('SimilarComponent -> GetSimilarContent: Success');
          this.mediaItems = response.filter(
            (mediaItem: MediaItem<MediaItemTypes>) =>
              mediaItem.type !== ItemTypes.Collection
          );
        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            'SimilarComponent -> GetSimilarContent: Failed',
            this.contentId,
            error
          );
        }
      );
  }

  private getCollectionContent() {
    this.collectionDetailService
      .getSimilarContent(this.contentId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: MediaItem<MediaItemTypes>[]) => {
          this.logger.debug('SimilarComponent -> GetSimilarContent: Success');
          this.mediaItems = response.filter(
            (mediaItem: MediaItem<MediaItemTypes>) =>
              mediaItem.type !== ItemTypes.Collection
          );
        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            'SimilarComponent -> GetSimilarContent: Failed',
            this.contentId,
            error
          );
        }
      );
  }
}
