<div id="my-space">
  <h4>{{ title | translate }}</h4>
  <div class="black-gradient-overlay"></div>
  <div class="grid-overflow-hidden"
       #mySpace>
    <ty-grid class="grid"
             *ngIf="favouriteItems?.length && this.isDataReceived"
             [items]="favouriteItems"
             [navigable]="navigableGrid"
             [setInitFocus]="this.setInitialFocus"
             [navigableId]="0"
             [galgoEvent]="galgoEvent"
             (updateScroll)="updateScroll()">
    </ty-grid>
  </div>
  <div class="no-favourites-wrapper"
       *ngIf="!favouriteItems?.length && this.isDataReceived">
    <span>{{ "my-space.no-favourites" | translate }}</span>
  </div>
</div>