/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PlatformApi } from '../models/platform-api';
import { RequestAccessProApi } from '../models/request-access-pro-api';
import { SignUpFormDtoApi } from '../models/sign-up-form-dto-api';
import { TokenApi } from '../models/token-api';
import { TokenRefreshApi } from '../models/token-refresh-api';
import { TokenRefreshResponseApi } from '../models/token-refresh-response-api';
import { UserFrontApi } from '../models/user-front-api';
import { UserFrontLoginApi } from '../models/user-front-login-api';
import { UserLoginApi } from '../models/user-login-api';
import { ValidateRecaptchaDtoApi } from '../models/validate-recaptcha-dto-api';
import { ValidateRecaptchaResponseApi } from '../models/validate-recaptcha-response-api';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation authControllerSignUpController
   */
  static readonly AuthControllerSignUpControllerPath = '/auth/signup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerSignUpController()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignUpController$Response(params: {
    body: SignUpFormDtoApi
  }): Observable<StrictHttpResponse<TokenApi>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerSignUpControllerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerSignUpController$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignUpController(params: {
    body: SignUpFormDtoApi
  }): Observable<TokenApi> {

    return this.authControllerSignUpController$Response(params).pipe(
      map((r: StrictHttpResponse<TokenApi>) => r.body as TokenApi)
    );
  }

  /**
   * Path part for operation authControllerSignUpIndustryController
   */
  static readonly AuthControllerSignUpIndustryControllerPath = '/auth/signup/industry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerSignUpIndustryController()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignUpIndustryController$Response(params: {
    Authorization?: string;
    body: RequestAccessProApi
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerSignUpIndustryControllerPath, 'post');
    if (params) {
      rb.header('Authorization', params.Authorization, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerSignUpIndustryController$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignUpIndustryController(params: {
    Authorization?: string;
    body: RequestAccessProApi
  }): Observable<void> {

    return this.authControllerSignUpIndustryController$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerSignupStepOneController
   */
  static readonly AuthControllerSignupStepOneControllerPath = '/auth/signup/step1';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerSignupStepOneController()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignupStepOneController$Response(params: {
    body: UserFrontLoginApi
  }): Observable<StrictHttpResponse<UserFrontApi>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerSignupStepOneControllerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserFrontApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerSignupStepOneController$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignupStepOneController(params: {
    body: UserFrontLoginApi
  }): Observable<UserFrontApi> {

    return this.authControllerSignupStepOneController$Response(params).pipe(
      map((r: StrictHttpResponse<UserFrontApi>) => r.body as UserFrontApi)
    );
  }

  /**
   * Path part for operation authControllerSignupStepTwoController
   */
  static readonly AuthControllerSignupStepTwoControllerPath = '/auth/signup/step2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerSignupStepTwoController()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignupStepTwoController$Response(params: {
    body: UserFrontLoginApi
  }): Observable<StrictHttpResponse<TokenApi>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerSignupStepTwoControllerPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerSignupStepTwoController$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignupStepTwoController(params: {
    body: UserFrontLoginApi
  }): Observable<TokenApi> {

    return this.authControllerSignupStepTwoController$Response(params).pipe(
      map((r: StrictHttpResponse<TokenApi>) => r.body as TokenApi)
    );
  }

  /**
   * Path part for operation authControllerSignIn
   */
  static readonly AuthControllerSignInPath = '/auth/signin';

  /**
   * Login user in platform
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerSignIn()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignIn$Response(params: {
    body: UserLoginApi
  }): Observable<StrictHttpResponse<TokenApi>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerSignInPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenApi>;
      })
    );
  }

  /**
   * Login user in platform
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerSignIn$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerSignIn(params: {
    body: UserLoginApi
  }): Observable<TokenApi> {

    return this.authControllerSignIn$Response(params).pipe(
      map((r: StrictHttpResponse<TokenApi>) => r.body as TokenApi)
    );
  }

  /**
   * Path part for operation authControllerRefresh
   */
  static readonly AuthControllerRefreshPath = '/auth/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRefresh()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRefresh$Response(params: {
    body: TokenRefreshApi
  }): Observable<StrictHttpResponse<TokenRefreshResponseApi>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerRefreshPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenRefreshResponseApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerRefresh$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRefresh(params: {
    body: TokenRefreshApi
  }): Observable<TokenRefreshResponseApi> {

    return this.authControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<TokenRefreshResponseApi>) => r.body as TokenRefreshResponseApi)
    );
  }

  /**
   * Path part for operation authControllerVerifyEmailByPost
   */
  static readonly AuthControllerVerifyEmailByPostPath = '/auth/verifyEmail/{id}';

  /**
   * This method is for confirm the user email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerVerifyEmailByPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerVerifyEmailByPost$Response(params: {
    Authorization?: string;

    /**
     * token for validate email
     */
    id: string;
  }): Observable<StrictHttpResponse<{
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerVerifyEmailByPostPath, 'post');
    if (params) {
      rb.header('Authorization', params.Authorization, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * This method is for confirm the user email.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerVerifyEmailByPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerVerifyEmailByPost(params: {
    Authorization?: string;

    /**
     * token for validate email
     */
    id: string;
  }): Observable<{
'message'?: string;
}> {

    return this.authControllerVerifyEmailByPost$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>) => r.body as {
'message'?: string;
})
    );
  }

  /**
   * Path part for operation authControllerSendEmail
   */
  static readonly AuthControllerSendEmailPath = '/auth/verifyEmail';

  /**
   * This method is for send the email confirmation again to the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerSendEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerSendEmail$Response(params?: {
  }): Observable<StrictHttpResponse<{
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerSendEmailPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * This method is for send the email confirmation again to the user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerSendEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerSendEmail(params?: {
  }): Observable<{
'message'?: string;
}> {

    return this.authControllerSendEmail$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>) => r.body as {
'message'?: string;
})
    );
  }

  /**
   * Path part for operation authControllerForgetPassword
   */
  static readonly AuthControllerForgetPasswordPath = '/auth/recoverPassword';

  /**
   * This method is for send an email with a new password to the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerForgetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerForgetPassword$Response(params: {

    /**
     * Use if you do not remember the password
     */
    body: {
'email'?: string;
}
  }): Observable<StrictHttpResponse<{
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * This method is for send an email with a new password to the user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerForgetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerForgetPassword(params: {

    /**
     * Use if you do not remember the password
     */
    body: {
'email'?: string;
}
  }): Observable<{
'message'?: string;
}> {

    return this.authControllerForgetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>) => r.body as {
'message'?: string;
})
    );
  }

  /**
   * Path part for operation authControllerChangePassword
   */
  static readonly AuthControllerChangePasswordPath = '/auth/changePassword';

  /**
   * This method is for change password using a temporary URL.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerChangePassword$Response(params: {

    /**
     * Use to change the password if not remember
     */
    body: {
'id'?: string;
'newPassword'?: string;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerChangePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method is for change password using a temporary URL.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerChangePassword(params: {

    /**
     * Use to change the password if not remember
     */
    body: {
'id'?: string;
'newPassword'?: string;
}
  }): Observable<void> {

    return this.authControllerChangePassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerValidateRecaptcha
   */
  static readonly AuthControllerValidateRecaptchaPath = '/auth/validate-recaptcha';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerValidateRecaptcha()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerValidateRecaptcha$Response(params: {
    body: ValidateRecaptchaDtoApi
  }): Observable<StrictHttpResponse<ValidateRecaptchaResponseApi>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthControllerValidateRecaptchaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidateRecaptchaResponseApi>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerValidateRecaptcha$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerValidateRecaptcha(params: {
    body: ValidateRecaptchaDtoApi
  }): Observable<ValidateRecaptchaResponseApi> {

    return this.authControllerValidateRecaptcha$Response(params).pipe(
      map((r: StrictHttpResponse<ValidateRecaptchaResponseApi>) => r.body as ValidateRecaptchaResponseApi)
    );
  }

  /**
   * Path part for operation socialLoginControllerSocialLogin
   */
  static readonly SocialLoginControllerSocialLoginPath = '/auth/socialLogin/{socialMediaType}';

  /**
   * social login for web
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialLoginControllerSocialLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  socialLoginControllerSocialLogin$Response(params: {

    /**
     * Social media type
     */
    socialMediaType: 'google' | 'facebook' | 'instagram' | 'apple';
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.SocialLoginControllerSocialLoginPath, 'get');
    if (params) {
      rb.path('socialMediaType', params.socialMediaType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * social login for web
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `socialLoginControllerSocialLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  socialLoginControllerSocialLogin(params: {

    /**
     * Social media type
     */
    socialMediaType: 'google' | 'facebook' | 'instagram' | 'apple';
  }): Observable<void> {

    return this.socialLoginControllerSocialLogin$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation socialLoginControllerSocialLoginApps
   */
  static readonly SocialLoginControllerSocialLoginAppsPath = '/auth/socialLogin/{socialMediaType}/{platform}';

  /**
   * social login for apps
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialLoginControllerSocialLoginApps()` instead.
   *
   * This method doesn't expect any request body.
   */
  socialLoginControllerSocialLoginApps$Response(params: {

    /**
     * Social media type
     */
    socialMediaType: 'google' | 'facebook' | 'instagram' | 'apple';

    /**
     * Platform using login
     */
    platform: PlatformApi;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.SocialLoginControllerSocialLoginAppsPath, 'get');
    if (params) {
      rb.path('socialMediaType', params.socialMediaType, {});
      rb.path('platform', params.platform, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * social login for apps
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `socialLoginControllerSocialLoginApps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  socialLoginControllerSocialLoginApps(params: {

    /**
     * Social media type
     */
    socialMediaType: 'google' | 'facebook' | 'instagram' | 'apple';

    /**
     * Platform using login
     */
    platform: PlatformApi;
  }): Observable<void> {

    return this.socialLoginControllerSocialLoginApps$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation socialLoginControllerSocialLoginById
   */
  static readonly SocialLoginControllerSocialLoginByIdPath = '/auth/loginsocial';

  /**
   * social login using CODE
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialLoginControllerSocialLoginById()` instead.
   *
   * This method doesn't expect any request body.
   */
  socialLoginControllerSocialLoginById$Response(params?: {

    /**
     * code for login
     */
    code?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthApiService.SocialLoginControllerSocialLoginByIdPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * social login using CODE
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `socialLoginControllerSocialLoginById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  socialLoginControllerSocialLoginById(params?: {

    /**
     * code for login
     */
    code?: string;
  }): Observable<void> {

    return this.socialLoginControllerSocialLoginById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
