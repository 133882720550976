import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RestrictedAccessGuard } from '@app/auth/guards/restricted-access.guard';
import { PublicLayoutComponent } from './public-layout/public-layout.component';


const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'privacy-policy',
        pathMatch: 'full',
        canActivate: [RestrictedAccessGuard],
        loadChildren: () =>
          import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
      },
      {
        path: 'legal-conditions',
        pathMatch: 'full',
        canActivate: [RestrictedAccessGuard],
        loadChildren: () =>
          import('./legal-conditions/legal-conditions.module').then((m) => m.LegalConditionsModule),
      },
      {
        path: 'restricted-access',
        pathMatch: 'full',
        loadChildren: () =>
          import('./restricted-access/restricted-access.module').then((m) => m.RestrictedAccessModule),
      },
      {
        path: 'exit-app',
        pathMatch: 'full',
        loadChildren: () =>
          import('./exit-app/exit-app.module').then((m) => m.ExitAppModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicPagesRoutingModule { }
