import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoginRedirectRoutingModule } from './login-redirect-routing.module';
import { LoginRedirectComponent } from './login-redirect.component';

@NgModule({
  declarations: [
    LoginRedirectComponent
  ],
  imports: [
    LoginRedirectRoutingModule,
    NavigationModule,
    TranslateModule
  ]
})
export class LoginRedirectModule { }
