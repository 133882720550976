/* tslint:disable */
/* eslint-disable */

/**
 * Support
 */
export enum SupportMediaEntitiesApi {
  Icaa = 'ICAA',
  Ibermedia = 'IBERMEDIA',
  Eurimages = 'EURIMAGES',
  CreativeEurope = 'CREATIVE EUROPE',
  Other = 'OTHER'
}
