import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { CatalogService } from '@app/auth/services/catalog/catalog.service';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalService } from '../modal/services/modal.service';

@UntilDestroy()
@Component({
  selector: 'ty-ppv-modal',
  templateUrl: './ppv-modal.component.html',
  styleUrls: ['./ppv-modal.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: PpvModalComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PpvModalComponent extends NavigationComponent {
  @Input() price: string;
  @Input() videoId: string;

  qrURL: string;
  constructor(
    public el: ElementRef,
    private customerDataService: CustomerDataService,
    private modalService: ModalService,
    private changeDetector: ChangeDetectorRef,
    private catalogService: CatalogService,
    private featureFlagsService: FeatureFlagsService

    ) {
    super(el);
  }

  get urlCatalog(): string {
    return this.catalogService.getFrontUrlCatalog();
  }

  get domainUrl(): string {
    let webBaseUrl = this.featureFlagsService.currentFeaturesValue.haveCatalogs ? this.urlCatalog : `${this.webBaseUrl}`;
    webBaseUrl = webBaseUrl.replace(/\/$/, '');
    return webBaseUrl;
  }

  get videoUrl(): string {
    return `${this.domainUrl}/pages/home?panel=${this.videoId}?k=${this.customerDataService.accessKey}&?o=tv`;
  }

  get webBaseUrl(): string {
    return this.customerDataService.webBaseUrl;
  }

  get ppvPrice(): string {
    return this.price + '€';
  }

  onUpKey(): boolean {
    return true;
  }
  onRightKey(): boolean {
    return true;
  }
  onDownKey(): boolean {
    return true;
  }
  onLeftKey(): boolean {
    return true;
  }

  onEnterKey(): boolean {
    (
      this.getChildNodeByPosition(this.getSelectedChildNodeId()).el
        .nativeElement as HTMLElement
    ).click();
    return true;
  }

  onBackKey(): boolean {
    this.modalService.emitCloseModalEvent();

    return true;
  }

  hidePpvModal(): void {
    this.modalService.emitCloseModalEvent();
  }

}
