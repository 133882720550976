/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchFilterApi } from '../models/search-filter-api';
import { VideoFrontApi } from '../models/video-front-api';

@Injectable({
  providedIn: 'root',
})
export class SearchApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation searchControllerFind
   */
  static readonly SearchControllerFindPath = '/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerFind$Response(params?: {
    language?: string;

    /**
     * Text to search
     */
    text?: string;

    /**
     * Copyright category ID
     */
    copyright?: Array<string>;

    /**
     * Collection category ID
     */
    collection?: Array<string>;

    /**
     * Director person ID
     */
    director?: Array<string>;

    /**
     * Date range in ISO string format, separated by a comma
     */
    year?: Array<string>;

    /**
     * Original Format category ID
     */
    originalFormat?: Array<string>;

    /**
     * Image procedure category ID
     */
    imageProcedure?: Array<string>;

    /**
     * duration in seconds
     */
    duration?: Array<string>;
    hasAudio?: boolean;

    /**
     * Type of the file
     */
    itemType?: Array<string>;

    /**
     * Genre category ID
     */
    genre?: Array<string>;

    /**
     * Subgenre category ID
     */
    subgenre?: Array<string>;

    /**
     * Whether rights are hold by filmoteca or public domain
     */
    clippable?: boolean;

    /**
     * set version 2 to get result in a container structure
     */
    version?: number;

    /**
     * number of results
     */
    size?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SearchApiService.SearchControllerFindPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('text', params.text, {});
      rb.query('copyright', params.copyright, {});
      rb.query('collection', params.collection, {});
      rb.query('director', params.director, {});
      rb.query('year', params.year, {});
      rb.query('originalFormat', params.originalFormat, {});
      rb.query('imageProcedure', params.imageProcedure, {});
      rb.query('duration', params.duration, {});
      rb.query('hasAudio', params.hasAudio, {});
      rb.query('itemType', params.itemType, {});
      rb.query('genre', params.genre, {});
      rb.query('subgenre', params.subgenre, {});
      rb.query('clippable', params.clippable, {});
      rb.query('version', params.version, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerFind(params?: {
    language?: string;

    /**
     * Text to search
     */
    text?: string;

    /**
     * Copyright category ID
     */
    copyright?: Array<string>;

    /**
     * Collection category ID
     */
    collection?: Array<string>;

    /**
     * Director person ID
     */
    director?: Array<string>;

    /**
     * Date range in ISO string format, separated by a comma
     */
    year?: Array<string>;

    /**
     * Original Format category ID
     */
    originalFormat?: Array<string>;

    /**
     * Image procedure category ID
     */
    imageProcedure?: Array<string>;

    /**
     * duration in seconds
     */
    duration?: Array<string>;
    hasAudio?: boolean;

    /**
     * Type of the file
     */
    itemType?: Array<string>;

    /**
     * Genre category ID
     */
    genre?: Array<string>;

    /**
     * Subgenre category ID
     */
    subgenre?: Array<string>;

    /**
     * Whether rights are hold by filmoteca or public domain
     */
    clippable?: boolean;

    /**
     * set version 2 to get result in a container structure
     */
    version?: number;

    /**
     * number of results
     */
    size?: number;
  }): Observable<void> {

    return this.searchControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation searchControllerFindByRanking
   */
  static readonly SearchControllerFindByRankingPath = '/search/popular';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchControllerFindByRanking()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerFindByRanking$Response(params: {
    language: string;

    /**
     * set version 2 to get result in a container structure
     */
    version?: number;

    /**
     * Select the type of popular videos to return
     */
    type?: 'video' | 'container' | 'mixed';

    /**
     * Page size. Minimum value: 1, Default: 10
     */
    size?: number;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<StrictHttpResponse<Array<VideoFrontApi>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchApiService.SearchControllerFindByRankingPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('version', params.version, {});
      rb.query('type', params.type, {});
      rb.query('size', params.size, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VideoFrontApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchControllerFindByRanking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerFindByRanking(params: {
    language: string;

    /**
     * set version 2 to get result in a container structure
     */
    version?: number;

    /**
     * Select the type of popular videos to return
     */
    type?: 'video' | 'container' | 'mixed';

    /**
     * Page size. Minimum value: 1, Default: 10
     */
    size?: number;

    /**
     * Page number. Minimum value: 1, Default: 1
     */
    page?: number;
  }): Observable<Array<VideoFrontApi>> {

    return this.searchControllerFindByRanking$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VideoFrontApi>>) => r.body as Array<VideoFrontApi>)
    );
  }

  /**
   * Path part for operation searchControllerAutocomplete
   */
  static readonly SearchControllerAutocompletePath = '/search/autocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchControllerAutocomplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerAutocomplete$Response(params: {
    language?: string;
    text: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SearchApiService.SearchControllerAutocompletePath, 'get');
    if (params) {
      rb.header('language', params.language, {});
      rb.query('text', params.text, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchControllerAutocomplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerAutocomplete(params: {
    language?: string;
    text: string;
  }): Observable<void> {

    return this.searchControllerAutocomplete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation searchControllerFilters
   */
  static readonly SearchControllerFiltersPath = '/search/filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchControllerFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerFilters$Response(params?: {
    language?: string;
  }): Observable<StrictHttpResponse<Array<SearchFilterApi>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchApiService.SearchControllerFiltersPath, 'get');
    if (params) {
      rb.header('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchFilterApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchControllerFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerFilters(params?: {
    language?: string;
  }): Observable<Array<SearchFilterApi>> {

    return this.searchControllerFilters$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchFilterApi>>) => r.body as Array<SearchFilterApi>)
    );
  }

}
