/* tslint:disable */
/* eslint-disable */

/**
 * Player type, because some players have special config
 */
export enum PlayerTypeApi {
  Standard = 'Standard',
  Audio = 'Audio',
  Youtube = 'Youtube',
  Vimeo = 'Vimeo',
  Twitch = 'Twitch'
}
