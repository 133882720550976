import { Injectable } from '@angular/core';
import { IURLMatch } from '../models/url-match.interface';
import { BrowsingHistoryService } from './browsing-history.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class InitializerNavigationModuleService {

  constructor(
    private browserHistoryService: BrowsingHistoryService,
    private platformService: PlatformService
  ) { }

  /**
   * Function to initialize all navigation services
   */
  initializeServices(urlsToIgnore?: IURLMatch[], customMainURL?: IURLMatch[]) {
    this.platformService.checkPlatform();
    this.browserHistoryService.start(customMainURL, urlsToIgnore);
  }

}
