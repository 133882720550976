import { QRCodeElementType } from './models/qr-code-element-type.enum';
import { QRCodeErrorCorrectionLevel } from './models/qr-code-error-correction-level.enum';
import { IQRCode } from './models/qr-code.interface';

export const QRCodeConfig: IQRCode = {
  allowEmptyString: true,
  elementType: QRCodeElementType.canvas,
  errorCorrectionLevel: QRCodeErrorCorrectionLevel.M,
  imageSrc:  './assets/customer/icon.png' ,
  imageHeight: 30,
  imageWidth: 30,
  margin: 4,
  scale: 1,
  width: 300
};
