import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LogoModule } from '../logo/logo.module';
import { ReturnButtonModule } from '../return-button/return-button.module';
import { HeaderPlayerComponent } from './header-player.component';



const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [ ...ANGULAR_MODULES, LogoModule, ReturnButtonModule,  TranslateModule ],
  declarations: [ HeaderPlayerComponent ],
  exports: [ HeaderPlayerComponent ]
})
export class HeaderPlayerModule { }
