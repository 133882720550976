/* tslint:disable */
/* eslint-disable */

/**
 * Type of content
 */
export enum VideoContentTypeApi {
  Film = 'FILM',
  Short = 'SHORT',
  Series = 'SERIES',
  Video = 'VIDEO'
}
