<div id="see-more">
  <div class="return-button">
    <ty-return-button *ngIf="showBackButton" [navigableId]="0"
                      (click)="onBackKey()"></ty-return-button>
    <span class="separator">|</span>
    <h4 class="title">{{ title | translate }}</h4>
  </div>
  <div class="black-gradient-overlay"></div>
  <div class="grid-overflow-hidden">
    <div class="spinner"
         *ngIf="!this.isDataReceived">
    </div>
    <div #seeMore>
      <ty-grid class="grid"
               *ngIf="this.isDataReceived && carouselItems?.length"
               [galgoEvent]="galgoEvent"
               [items]="carouselItems"
               [navigable]="navigableGrid"
               [setInitFocus]="this.setInitialFocus"
               [navigableId]="1"
               (updateScroll)="updateScroll()"></ty-grid>
    </div>
  </div>
</div>
