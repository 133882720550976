<div id="login-return-button"
     (click)="return()"
     appNavigation
     [navigableId]="0">
  <button class="return-icon-svg">
    <span class="return-icon-svg">
      <i class="gi-lg gi-return-icon"></i>
    </span>
    <span class="text">{{'buttons.return' | translate}} </span>
  </button>
</div>