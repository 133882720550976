import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { ButtonPlayerModule } from '../button-player/button-player.module';
import { HeaderPlayerModule } from '../header-player/header-player.module';
import { LogoModule } from '../logo/logo.module';
import { ControlsPlayerComponent } from './controls-player.component';
import { CustomSeekbarModule } from './custom-seekbar/custom-seekbar.module';
import { ListSelectorIconModule } from './list-selector-icon/list-selector-icon.module';
import { SeekbarModule } from './seekbar/seekbar.module';

const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    LogoModule,
    HeaderPlayerModule,
    ButtonPlayerModule,
    CustomSeekbarModule,
    SeekbarModule,
    ListSelectorIconModule,
    NavigationModule
  ],
  declarations: [ ControlsPlayerComponent],
  exports: [ ControlsPlayerComponent ]
})
export class ControlsPlayerModule { }
