import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { IconList } from '@core/models/icon-list.enum';
import { NGXLogger } from 'ngx-logger';
import { BrowsingHistoryService } from '../../../core/navigation/services/browsing-history.service';

@Component({
  selector: 'ty-return-button',
  templateUrl: './return-button.component.html',
  styleUrls: ['./return-button.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ReturnButtonComponent }]
})
export class ReturnButtonComponent extends NavigationComponent {

  @Output() backEvent = new EventEmitter();
  iconName: string;
  isKeyboardVisible: boolean;

  constructor(
    public logger: NGXLogger,
    public el: ElementRef,
    private browsingHistoryService: BrowsingHistoryService,
  ) {
    super(el);
    this.isKeyboardVisible = false;
    this.iconName = IconList.returnIcon;
  }

  get returnIconUrl(): string {
    return `./assets/icons/${this.iconName}#icon`;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  navigateBack() {
    this.browsingHistoryService.goBack();
  }

  isVirtualKeyboardVisible(event: boolean) {
    this.isKeyboardVisible = event;
  }

}
