export enum AppRoutes {
  catalogSelector = '/landing/catalog-selector',
  genres = '/pages/genres',
  series = '/pages/series',
  seriesMedia = '/pages/series-media',
  collections = '/pages/collections',
  collectionsMenu = '/pages/collections-menu',
  collectionsMedia = '/pages/collections-media',
  microsites = '/pages/microsites',
  micrositesMedia = '/pages/microsites-media',
  exitApp = '/exit-app',
  forbidden = '/404',
  home = '/pages/home',
  lives = '/pages/lives',
  login = '/landing/login',
  loginForm = '/landing/form',
  loginRedirect = '/pages/login-redirect',
  mySpace = '/pages/my-space',
  onboarding = '/landing/onboarding',
  player = '/pages/player',
  privacy = '/privacy-policy',
  purchases = '/pages/purchases',
  restrictedAccess = '/restricted-access',
  search = '/pages/search',
  seeMore = '/pages/see-more',
  settings = '/pages/settings',
  signIn = '/landing/sign-in-page',
  terms = '/legal-conditions',
  video = '/pages/video',
  videoMedia = '/pages/video-media',
}

export enum ExternalAppRoutes {
  help = 'help',
  register = 'register'
}
