import { Injectable } from '@angular/core';
import { LegalTextApi } from '@api/models';
import { LegalConditionsApiService } from '@api/services';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LegalConditionsCustomService {
  constructor(private apiLegalConditions: LegalConditionsApiService) {}

  /**
   * Returns a {@link LegalTextApi} object with all legal texts with markdown format
   *
   * @return Observable<LegalTextApi>
   * @memberof LegalConditionsCustomService
   */
  getLegalConditions(): Observable<LegalTextApi> {
    return this.apiLegalConditions.legalTextControllerFindLast();
  }

  /**
   * Returns a {@link LegalTextApi} object with all legal texts with markdown format via an id
   *
   * @param id string
   * @return Observable<LegalTextApi>
   * @memberof LegalConditionsCustomService
   */
  getLegalConditionsById(id: number): Observable<LegalTextApi> {
    return this.apiLegalConditions.legalTextControllerFindById({ id });
  }
}
