/* tslint:disable */
/* eslint-disable */

/**
 * field type for db
 */
export enum SearchFieldTypeApi {
  CategoryType = 'categoryType',
  PersonalRole = 'personalRole',
  Video = 'video'
}
