import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { SharedTranslationModule } from '@app/shared/translations/shared-translation.module';
import { ExitAppRoutingModule } from './exit-app-routing.module';
import { ExitAppComponent } from './exit-app.component';



@NgModule({
  declarations: [
    ExitAppComponent,
  ],
  imports: [
    CommonModule,
    SharedTranslationModule,
    NavigationModule,
    ExitAppRoutingModule
  ]
})
export class ExitAppModule { }
