import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { throwError } from 'rxjs';
import { AppRoutes } from '../config/app-routes.enum';
import { PageUrl } from './../models/page-url.interface';
import { IURLMatch } from './../models/url-match.interface';

@Injectable({
  providedIn: 'root',
})
export class BrowsingHistoryService {

  private _history: PageUrl[] = [];
  get history(): PageUrl[] {
    return this._history;
  }
  set history(value: PageUrl[]) {
    this._history = value;
  }

  private _currentURL: string;
  get currentURL(): string {
    return this._currentURL;
  }
  set currentURL(value: string) {
    this._currentURL = value;
  }

  private _mainUrl: IURLMatch[];
  get mainUrl(): IURLMatch[] {
    return this._mainUrl;
  }
  set mainUrl(value: IURLMatch[]) {
    this._mainUrl = value;
  }

  private _ignoreUrls: IURLMatch[];
  get ignoreUrls(): IURLMatch[] {
    return this._ignoreUrls;
  }
  set ignoreUrls(value: IURLMatch[]) {
    this._ignoreUrls = value;
  }

  /**
   * Class to save url history in TV Browsers.
   *
   * @param router Angular Router object
   * @param logger NGX Logger object
   */
  constructor(
    private router: Router,
    private logger: NGXLogger
  ) {
    this.logger.trace('[HISTORY] - Initialize browser history service...');
    this.currentURL = '';
    this.ignoreUrls = [];

  }

  /**
   * Start listener of router events to set urls into history.
   */
  start(mainAppUrl?: IURLMatch[], ignoreUrls?: IURLMatch[]): void {
    this.setIgnoreURLsConfiguration(ignoreUrls);
    this.setCustomMainURLConfig(mainAppUrl);

    this.router.events.subscribe(
      {
        next: (event) => {
          if (event instanceof NavigationEnd) {
            this.handleNavigation(event.url);
          }
        },
        error: () => {
          throwError(() => new Error('Error while trying to subscribe to router events'));
        }
      });
  }

    /**
     * Used to navigate to the last URL
     */
  goBack(): void {
    if (!this.matchSome(this.ignoreUrls, this.currentURL)) {
      this.removeLastHistoryPage();
    }
    const targetPage = this.getLastHistoryPage();
    this.router.navigateByUrl(targetPage?.url || AppRoutes.home);
    this.logger.trace('[HISTORY] - History data after go back...', this.history);
  }

  getFocusData(url: string): any {
    const item = this.findPageByUrl(url);
    return item?.focusData;
  }

  removeFocusData(url: string): void {
    const item = this.findPageByUrl(url);
    if (item) {
      item.focusData = {};
    }
  }

  removeAllFocusData(): void {
    this.history.forEach(item => {
      item.focusData = {};
    });
  }

  private handleNavigation(url: string): void {
    this.currentURL = url;
    // If current URL is not an ignored URL
    if (!this.matchSome(this.ignoreUrls, this.currentURL)) {
      // If current URL is a main URL, clear other URLs
      if (this.matchSome(this.mainUrl, this.currentURL)) {
          this.removeOtherUrls(this.currentURL);
        }
      this.pushToHistory(this.currentURL);
    }
  }

  private removeOtherUrls(url: string): void {
    this.history = this.history.filter(page => page.url === url);
  }

  private findPageByUrl(url: string): PageUrl | undefined {
    url = url.trim().toLowerCase();
    return this.history.find(i => i.url.trim().toLowerCase() === url);
  }

  /**
   * Set app ignore urls configuration
   *
   * @param ignoreUrls array of URLs to ignore
   */
  private setIgnoreURLsConfiguration(ignoreUrls?: IURLMatch[]): void {
    this.ignoreUrls = ignoreUrls;
    this.logger.trace('[HISTORY] - Setting ignore urls...', this.ignoreUrls);
  }

  private getLastHistoryPage(): PageUrl {
    return this.history[this.history.length - 1];
  }

  private removeLastHistoryPage(): void {
    this.history.pop();
  }

  /**
   * Set app main urls configuration
   *
   * @param mainUrls array of main URLs of application
   */
  private setCustomMainURLConfig(mainAppUrl?: IURLMatch[]): void {
    this.mainUrl= mainAppUrl;
    this.logger.trace('[HISTORY] - Setting main urls...', this.mainUrl);
  }

  private pushToHistory(url: string): void {
    // Push only if URL is not an ignored URL
    if (this.getLastHistoryPage()?.url !== url && !this.matchSome(this.ignoreUrls, url)) {
      this.history.push({ url, focusData: {} });
      this.logger.trace('[HISTORY] - History data after push data...', this.history);
    }
  }

  private matchSome(ignoreUrls: IURLMatch[], currentUrl: string): boolean {
    return ignoreUrls.some(ignoreUrl =>
      ignoreUrl.matchExact ? currentUrl === ignoreUrl.url : currentUrl.includes(ignoreUrl.url)
    );
  }

}
