import { Injectable } from '@angular/core';
import { IKeyboardEvent } from '@shared/models/keyboard-event.interface';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyboardSearchService {
  private keyBoardObservable$: Subject<IKeyboardEvent>;

  get keyBoardObservable(): Observable<IKeyboardEvent> {
    return this.keyBoardObservable$;
  }
  constructor() {
    this.keyBoardObservable$ = new Subject<IKeyboardEvent>();
  }

  keyboardEvent(event: IKeyboardEvent): void {
    this.keyBoardObservable$.next(event);
  }
}
