<div id="landscape-grid-thumb"
     (mouseenter)="mouseEnter()"
     (mouseleave)="mouseLeave()">
  <div class="focused-thumb">
    <div class="thumb"
         #thumbElement
         [style.background-image]="'url('+ thumbnail || defaultLandscape +')'"></div>
  </div>
  <div class="info">
    <div class="metadata">
      <ty-tag *ngIf="isLive"
              [content]="liveTag"
              [forceOpen]="focused">
      </ty-tag>
      <ty-tag *ngIf="subscription"
              [content]="suscriptionTag"
              [forceOpen]="focused">
      </ty-tag>
      <ty-tag *ngIf="ppv"
              [content]="ppvTag"
              [forceOpen]="focused"></ty-tag>
    </div>
    <h3>{{title}}</h3>
  </div>
</div>